import { useGetFlags } from '@/hooks/useFeatureFlag';
import { Details } from '@features/invoices/components/Details';
import { DraggableDrawer } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo, useEffect, useMemo, useState } from 'react';
import styles from './Drawer.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  selectedId: string;
  open: boolean;
  onClose: () => void;
};

export const Drawer: FC<Props> = memo(({ selectedId, open, onClose }) => {
  const flag = useGetFlags('RECEIVED_INVOICE_COPY_BUTTON');
  const minWidth = useMemo(() => (flag ? 968 : 986), [flag]);
  const [width, setWidth] = useState(minWidth);
  useEffect(() => {
    setWidth(minWidth);
  }, [minWidth, open]);
  return (
    <DraggableDrawer
      width={width}
      setWidth={setWidth}
      visible={open}
      onClose={onClose}
      withOutDefaultTitle
      contentContainerClassName={cx(styles['contentContainer'])}
      minWidth={minWidth}
    >
      {selectedId && (
        <Details
          key={selectedId}
          invoiceId={selectedId}
          onClose={onClose}
          parentWidth={width}
        />
      )}
    </DraggableDrawer>
  );
});
Drawer.displayName = 'Drawer';
