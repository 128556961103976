import { useRefetchQueries } from '@/context/services/refetch';
import {
  ApReport,
  useCreateInvoiceReport as useBaseCreateInvoiceReport,
} from 'ap-openapi';
import { useCallback } from 'react';

type TCreateInvoiceReportMutateAsync = ReturnType<
  typeof useBaseCreateInvoiceReport
>['mutateAsync'];
type CreateInvoiceReportMutateArgs =
  Parameters<TCreateInvoiceReportMutateAsync>['0'];
type CreateInvoiceReportMutateOption =
  Parameters<TCreateInvoiceReportMutateAsync>['1'] & TFetchQueries;

export const useCreateInvoiceReportCallbackNavigation = () => {
  return useCallback((data: ApReport) => {
    if (data.link) {
      location.href = data.link;
    } else {
      throw new Error('No ApReport link has been issued.');
    }
  }, []);
};

export const useCreateInvoiceReports = (
  callback?: (data: ApReport) => void
) => {
  const { mutateAsync: _mutateAsync } = useBaseCreateInvoiceReport();
  const refetchQueries = useRefetchQueries();
  const mutateAsync = useCallback(
    async (
      args: CreateInvoiceReportMutateArgs,
      options?: CreateInvoiceReportMutateOption
    ) => {
      const resp = await _mutateAsync(args, options);
      if (resp.status === 200) {
        await refetchQueries(options?.refetchQueries);
        callback?.(resp.data);
      } else {
        throw resp.data;
      }
    },
    [_mutateAsync, callback, refetchQueries]
  );
  return { mutateAsync };
};
