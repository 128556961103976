import {
  useDraggingDrawer,
  useSplitViewDragging,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';
import styles from './Viewer.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  src?: string;
  className?: string;
};

export const PdfViewer: FC<Props> = memo(({ src, className }) => {
  const isDrawerDragging = useDraggingDrawer();
  const isSplitViewDragging = useSplitViewDragging();
  return (
    <div className={cx(styles['container'], className)}>
      <pdf-viewer
        // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          display: 'block',
        }}
        src={src}
        baseUrl={`${process.env.PDF_VIEWER_URL || '/'}pdf/web/`}
      />
      <div
        // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
        style={{
          display: isDrawerDragging || isSplitViewDragging ? 'block' : 'none',
        }}
        className={cx(styles['overlay'])}
      />
    </div>
  );
});
PdfViewer.displayName = 'PdfViewer';
