export class RestError extends Error {
  code: string;
  messages: string;

  constructor(code: string, messages: string) {
    super();
    this.code = code;
    this.messages = messages;
  }
}
