import { IgnoreErrorTracking } from '@/utils/errors';
import { ErrorBoundary, ErrorBoundaryProps, Provider } from '@rollbar/react';
import { FC, useMemo } from 'react';
import { rollbarConfig } from './config';

export const RollbarProvider: FC<ErrorBoundaryProps> = ({
  children,
  ...rest
}) => {
  const config = useMemo(() => {
    const cfg = rollbarConfig(
      import.meta.env.VITE_ROLLBAR_TOKEN,
      import.meta.env.VITE_ROLLBAR_ENV,
      import.meta.env.VITE_CODE_VERSION
    );
    cfg.checkIgnore = (isUncaught, args) => {
      const _args = Array.isArray(args) ? args : [args];
      return _args.some((item) => item instanceof IgnoreErrorTracking);
    };
    return cfg;
  }, []);
  return (
    <Provider config={config}>
      <ErrorBoundary {...rest}>{children}</ErrorBoundary>
    </Provider>
  );
};
