import { ApReportStatus } from '@/context/services/invoice/type';
import { useTranslation } from '@/i18n';
import { useMemo } from 'react';

type TApReportStatusLabel = { [key in ApReportStatus]: string };
export const useLabel = () => {
  const { t } = useTranslation();
  const obj: TApReportStatusLabel = useMemo(
    () => ({
      unsubmitted: t('ap_report_status_unsubmitted'),
      waiting_step_one: t('ap_report_status_waiting_step_one'),
      waiting_step_two: t('ap_report_status_waiting_step_two'),
      waiting_step_three: t('ap_report_status_waiting_step_three'),
      waiting_step_four: t('ap_report_status_waiting_step_four'),
      waiting_step_five: t('ap_report_status_waiting_step_five'),
      waiting_step_six: t('ap_report_status_waiting_step_six'),
      waiting_step_seven: t('ap_report_status_waiting_step_seven'),
      waiting_step_eight: t('ap_report_status_waiting_step_eight'),
      waiting_step_nine: t('ap_report_status_waiting_step_nine'),
      waiting_step_ten: t('ap_report_status_waiting_step_ten'),
      approved: t('ap_report_status_approved'),
      canceled: t('ap_report_status_canceled'),
      rejected: t('ap_report_status_rejected'),
    }),
    [t]
  );
  return (value: ApReportStatus): string => {
    return obj[value];
  };
};
