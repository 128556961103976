import { useGlobalContainerRef } from '@/wc/helper/ref';
import { FC, ReactNode, useMemo } from 'react';
import {
  BaseApiArgs,
  ConfirmationModalProvider,
  useConfirmationModal,
} from '../Base';
import { ArchiveConfirmationModalBody } from './Body';
import { ArchiveConfirmationModalFooter } from './Footer';

type ApiArgs = {
  fileName: string;
  pics: string[];
  bodyTitle?: string;
  onCancelClick: VoidFunction;
  onArchiveClick: VoidFunction;
} & Omit<BaseApiArgs, 'body' | 'children'>;

export type ArchiveModalAPI = {
  open: (args: ApiArgs) => void;
  close: () => void;
};

type Props = {
  children: ReactNode;
  getContainer?: () => HTMLElement | ShadowRoot;
};

export const ArchiveConfirmationModalProvider: FC<Props> = ({
  children,
  getContainer: _getContainer,
}) => {
  const containerRef = useGlobalContainerRef();
  const getContainer = useMemo(
    () => _getContainer ?? containerRef,
    [_getContainer, containerRef]
  );
  return (
    <ConfirmationModalProvider getContainer={getContainer}>
      {children}
    </ConfirmationModalProvider>
  );
};

export const useArchiveConfirmationModal = (): ArchiveModalAPI => {
  const modal = useConfirmationModal();

  return {
    open(args) {
      const key = Date.now().toString();
      modal.open({
        ...args,
        title: args.title,
        body: (
          <ArchiveConfirmationModalBody
            key={key}
            fileName={args.fileName}
            pics={args.pics}
            bodyTitle={args.bodyTitle}
          />
        ),
        footer: (
          <ArchiveConfirmationModalFooter
            key={key}
            onCancelClick={args.onCancelClick}
            onArchiveClick={args.onArchiveClick}
          />
        ),
      });
    },
    close() {
      modal.close();
    },
  };
};
