import { FlagSet, IFeatureFlag } from '../types';

export class Environment implements IFeatureFlag {
  private flags: FlagSet;
  off: boolean;

  constructor(flags: FlagSet, off = false) {
    this.flags = flags;
    this.off = off;
  }

  public check(name: string, skip = false): Promise<boolean> {
    const key = name.toUpperCase();
    // offが設定されている場合は全ての機能をOnにする
    if (this.off) return Promise.resolve(true);
    // flagとして設定されている分は全てチェックする
    // @ts-ignore noUncheckedIndexedAccess
    if (key in this.flags) return Promise.resolve(this.flags[key]);
    // それ以外はskipの値とする(意図せずに機能がOnにならないようにfalseとする)
    return Promise.resolve(skip);
  }
}
