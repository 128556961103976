import { FC, PropsWithChildren, createContext, useContext } from 'react';

const Context = createContext(() => {});

type Props = {
  value: () => void;
};

export const DrawerCloseProvider: FC<PropsWithChildren<Props>> = ({
  children,
  value,
}) => {
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useDrawerClose = () => useContext(Context);
