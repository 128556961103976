import { FileNameSeparatorWithExtension } from '@/components/FileNameSeparatorWithExtension';
import { PdfViewer } from '@/components/pdfViewer';
import { BusinessDocumentDetail } from '@/context/services/business_document/type';
import { Header } from '@/features/invoices/components/Details/Components/Header';
import styles from '@/features/invoices/components/Details/Components/Left/Left.module.scss';
import { useDownloadClick } from '@/features/invoices/components/Details/Components/Left/hooks';
import {
  IconButton,
  IconClose,
  IconDownload,
  Loading,
} from '@moneyforward/ap-frontend-components';
import { useGetMfFileAndObjectURL } from '@service/mf_file/MfFile.service';
import classnames from 'classnames/bind';
import { FC, Suspense, memo } from 'react';

const cx = classnames.bind(styles);

export type Props = {
  data: BusinessDocumentDetail;
  onClose: () => void;
};

const InnerLeft: FC<Props> = memo(({ data, onClose }) => {
  const { originalBlob, url } = useGetMfFileAndObjectURL(data.mf_file?.id);
  return (
    <div className={cx(styles['container'])}>
      <PreviewHeader
        onClose={onClose}
        fileName={data.mf_file?.name}
        originalBlob={originalBlob}
      />
      <PdfViewer src={url} />
    </div>
  );
});
InnerLeft.displayName = 'InnerLeft';

const SuspenseLoading: FC = memo(() => {
  return (
    <div>
      <Loading className={cx(styles['spinner'])} size='large' />
    </div>
  );
});
SuspenseLoading.displayName = 'Loading';

export const BusinessDocumentDetailLeftView: FC<Props> = memo((props) => {
  return (
    <Suspense fallback={<SuspenseLoading />}>
      <InnerLeft {...props} />
    </Suspense>
  );
});
BusinessDocumentDetailLeftView.displayName = 'BusinessDocumentDetailLeftView';

type PreviewHeaderProps = {
  onClose: VoidFunction;
  fileName: string | undefined;
  originalBlob: Blob;
};

const PreviewHeader: FC<PreviewHeaderProps> = memo(
  ({ onClose, originalBlob, fileName }) => {
    const onDownloadClick = useDownloadClick(
      originalBlob,
      fileName || 'unknown.pdf'
    );

    return (
      <Header className={cx(styles['headerContainer'])}>
        <div className={cx(styles['closeButton'])} onClick={onClose}>
          <IconClose size={20} />
        </div>
        <FileNameSeparatorWithExtension
          containerClassName={cx(styles['title'])}
        >
          {fileName ?? ''}
        </FileNameSeparatorWithExtension>
        <IconButton color='primary' onClick={onDownloadClick}>
          <IconDownload size={20} />
        </IconButton>
      </Header>
    );
  }
);
PreviewHeader.displayName = 'PreviewHeader';
