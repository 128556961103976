import { useTranslation } from '@/i18n';
import { RestError } from '@/libs/error';
import { SendErrorTracking } from '@/utils/errors';
import {
  IconInfo,
  InlineNotification,
} from '@moneyforward/ap-frontend-components';
import { useRollbar } from '@rollbar/react';
import parser from 'html-react-parser';
import {
  FC,
  PropsWithChildren,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

export type TErrorNotification = {
  setError: (error: Error) => void;
};

const context = createContext<TErrorNotification['setError']>(() => {});

type Props = {
  value: TErrorNotification['setError'];
};

const Provider: FC<PropsWithChildren<Props>> = ({ children, value }) => {
  return <context.Provider value={value}>{children}</context.Provider>;
};

export const useErrorInlineNotification = () => {
  const { t } = useTranslation();
  const rollbar = useRollbar();
  const [message, _setMessage] = useState<ReactNode | string>('');

  const setMessage = useCallback(
    (message?: string, code?: string) => {
      if (message) {
        if (code && code === 'require_cloud_series_setting') {
          _setMessage(parser(message));
        } else {
          _setMessage(message);
        }
      } else {
        _setMessage(t('system_error'));
      }
    },
    [t]
  );
  const handleErrorNotification: TErrorNotification = useMemo(
    () => ({
      // TODO: ErrorをもらいRollbarへ通知する
      setError: (err?: Error) => {
        if (err) {
          if (err instanceof RestError) {
            setMessage(err.messages, err.code);
          } else {
            setMessage(err.message);
          }
          if (err instanceof SendErrorTracking) {
            rollbar.error(err);
          }
        } else {
          setMessage();
        }
      },
    }),
    [rollbar, setMessage]
  );
  const onClearMessage = useCallback(() => {
    _setMessage('');
  }, [_setMessage]);

  const contextHolder = useMemo(
    () => (
      <InlineNotification
        icon={<IconInfo size={19} />}
        message={message}
        type='error'
        visible={message !== ''}
        onClick={onClearMessage}
      />
    ),
    [message, onClearMessage]
  );
  return [
    handleErrorNotification,
    contextHolder,
    Provider,
    onClearMessage,
  ] as const;
};

export const useErrorInlineNotificationAPI = () => useContext(context);
