import {
  BusinessDocumentAutomaticStatus,
  BusinessDocumentTimestampStatus,
} from '@/context/services/business_document/type';
import { useTranslation } from '@/i18n';
import { Status } from '@moneyforward/ap-frontend-components/dist/es/atoms/Tag/Tag';
import { InputStatus } from 'antd/lib/_util/statusUtils';

const AutomaticStatuses = {
  waiting_for_ocr_input: 'waiting_for_ocr_input',
  ocr_done: 'ocr_done',
  ocr_skipped: 'ocr_skipped',
} as const;

type TAutomaticStatus = typeof AutomaticStatuses;
type AutomaticStatus = keyof TAutomaticStatus;
type AutomaticStatusValue = TAutomaticStatus[AutomaticStatus];
export type AutomaticStatusValues = {
  [K in AutomaticStatus as TAutomaticStatus[K]]: K;
};
const AutomaticStatusesValue: AutomaticStatusValues = Object.entries(
  AutomaticStatuses
).reduce(
  (prev, [key, value]) => ({ ...prev, [value]: key }),
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as AutomaticStatusValues
);
export const typeGuardAutomaticStatusValue = (
  value: BusinessDocumentAutomaticStatus | undefined
): value is AutomaticStatusValue => {
  if (value === undefined) throw new Error('Type guard failed.');
  return value in AutomaticStatusesValue;
};

const TimestampStatuses = {
  creating: 'creating',
  done: 'done',
  need_retry: 'need_retry',
  failed: 'failed',
  unsupported: 'unsupported',
} as const;

type TTimestampStatus = typeof TimestampStatuses;
type TimestampStatus = keyof TTimestampStatus;
type TimestampStatusValue = TTimestampStatus[TimestampStatus];
export type TimestampStatusValues = {
  [K in TimestampStatus as TTimestampStatus[K]]: K;
};
const TimestampStatusesValue: TimestampStatusValues = Object.entries(
  TimestampStatuses
).reduce(
  (prev, [key, value]) => ({ ...prev, [value]: key }),
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as TimestampStatusValues
);
export const typeGuardTimestampStatusValue = (
  value: BusinessDocumentTimestampStatus | undefined
): value is TimestampStatusValue => {
  if (value === undefined) throw new Error('Type guard failed.');
  return value in TimestampStatusesValue;
};

export type TProcessingStatus =
  | BusinessDocumentAutomaticStatus
  | BusinessDocumentTimestampStatus
  | undefined;

type UseProcessingStatusTagProps = (status: TProcessingStatus) => {
  label: string;
  colorType: Status | InputStatus;
};

export const useProcessingStatusTag: UseProcessingStatusTagProps = (status) => {
  const { t } = useTranslation();

  let label = '';
  let colorType: Status | InputStatus = '';

  switch (status) {
    case 'creating':
    case 'need_retry':
      label = t('label_status_timestamp_processing');
      colorType = 'success';
      break;
    case 'failed':
      label = t('label_status_timestamp_failed');
      colorType = 'error';
      break;
    case 'waiting_for_ocr_input':
      label = t('label_status_ocr_processing');
      colorType = 'success';
      break;
    default:
      break;
  }
  return {
    label,
    colorType,
  };
};
