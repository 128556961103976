import { FC, ReactNode, memo } from 'react';

type AppsProps = {
  children: ReactNode;
};

export const App: FC<AppsProps> = memo(({ children }) => {
  return <>{children}</>;
});
App.displayName = 'App';
