import { splitFileNameExtension } from '@/libs/files/split';
import classnames from 'classnames/bind';
import { FC, memo, useMemo } from 'react';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  children: string;
  containerClassName?: string;
  textClassName?: string;
  extensionClassName?: string;
  allowedFileTypes?: string;
};

export const FileNameSeparatorWithExtension: FC<Props> = memo(
  ({
    children,
    containerClassName,
    textClassName,
    extensionClassName,
    allowedFileTypes,
  }) => {
    const { name, ext } = useMemo(
      () => splitFileNameExtension(children, allowedFileTypes),
      [children, allowedFileTypes]
    );

    return (
      <div className={cx(styles['container'], containerClassName)}>
        <div className={cx(styles['text'], textClassName)}>{name}</div>
        {ext && (
          <div className={cx(styles['extension'], extensionClassName)}>
            .{ext}
          </div>
        )}
      </div>
    );
  }
);
FileNameSeparatorWithExtension.displayName = 'FileNameSeparatorWithExtension';
