import { Error404 } from '@/features/errors/404';
import { Layout } from '@moneyforward/ap-frontend-components';
import { FC, memo } from 'react';

const Error404Page: FC = memo(() => {
  return (
    <Layout title='お探しのページは見つかりませんでした'>
      <Error404 />
    </Layout>
  );
});
Error404Page.displayName = 'Error404Page';

export default Error404Page;
