import { useProcessingStatusChecker } from '@/features/business_documents/Detail/Context/ProcessingStatus';
import { useTranslation } from '@/i18n';
import { ButtonV2 } from '@moneyforward/ap-frontend-components';
import { FC, memo, useCallback, useState } from 'react';

type Props = {
  onClick: () => Promise<void>;
  isDisabled: boolean;
};

export const RestoreArchiveButton: FC<Props> = memo(
  ({ onClick: _onClick, isDisabled }) => {
    const { t } = useTranslation();
    const processingChecker = useProcessingStatusChecker();
    const [isLoading, setIsLoading] = useState(false);

    const onClick = useCallback(async () => {
      setIsLoading(true);
      await _onClick();
      setIsLoading(false);
    }, [_onClick]);

    if (!processingChecker.isArchived) {
      return <></>;
    }

    return (
      <ButtonV2
        isTertiary
        size='sm'
        onClick={onClick}
        isLoading={isLoading}
        isDisabled={isDisabled}
        testId='business-document-restore-button'
      >
        {t('restore')}
      </ButtonV2>
    );
  }
);
RestoreArchiveButton.displayName = 'RestoreArchiveButton';
