import { BusinessDocumentSearchQuery } from '@/features/business_documents/List/type';
import { TBusinessDocumentCondition } from '@/features/business_documents/UploadForm/Content/type';
import { toISODateString } from 'date-util';

export function convertToFilterPayload(
  search: TBusinessDocumentCondition
): BusinessDocumentSearchQuery {
  const {
    keyword_cont,
    document_type_in,
    pic_id_in,
    transaction_date,
    amount,
    include_archive,
  } = search;

  const result: BusinessDocumentSearchQuery = {};

  if (amount !== undefined && amount !== null) {
    result.amount = amount;
  }

  if (keyword_cont) {
    result.keyword_cont = keyword_cont;
  }

  if (document_type_in && document_type_in.length > 0) {
    result.document_type_in = document_type_in;
  }

  if (pic_id_in) {
    result.pic_id_in = pic_id_in;
  }

  if (include_archive) {
    result.include_archive = include_archive;
  }

  if (
    transaction_date &&
    transaction_date[0] !== '' &&
    transaction_date[1] !== ''
  ) {
    result.transaction_date_gteq =
      transaction_date[0] && toISODateString(transaction_date[0]);
    result.transaction_date_lteq =
      transaction_date[1] && toISODateString(transaction_date[1]);
  }

  return result;
}
