import { CommonError, GetTpmInvoices400 } from 'ap-openapi';

export const isCommonError = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>
): data is CommonError => {
  return 'messages' in data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isResponseError = <T extends Record<string, any>>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>,
  name: string
): data is T => {
  return name in data;
};

export const tpmResponseHasMessage = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>
): data is GetTpmInvoices400 => {
  return 'message' in data;
};
