import {
  BusinessDocumentColumn,
  BusinessDocumentColumnWithMfFile,
  BusinessDocumentSearchQuery,
} from '@/features/business_documents/List/type';
import { ModernPaginationProps } from '@moneyforward/ap-frontend-components';
import {
  BusinessDocumentAutomaticStatus as AutomaticStatus,
  CommonError,
  BusinessDocumentDocumentType as DocumentType,
  Error,
  GetBusinessDocumentResponse,
  BusinessDocumentReceiptType as ReceiptType,
  SearchBusinessDocumentsQuery,
  BusinessDocumentTimestampStatus as TimestampStatus,
  UpdateBusinessDocumentErrorResponse as UpdateErrorResponse,
  useGetBusinessDocumentSuspense,
  useSearchBusinessDocumentsSuspense,
} from 'ap-openapi';

export type BusinessDocumentDetail = GetBusinessDocumentResponse;
export type BusinessDocumentsSearchQuery = SearchBusinessDocumentsQuery;
export type ResponseError = Error;
export type BusinessDocumentDocumentType = DocumentType;
export const BusinessDocumentDocumentType = DocumentType;
export type BusinessDocumentReceiptType = ReceiptType;
export const BusinessDocumentReceiptType = ReceiptType;
export type BusinessDocumentAutomaticStatus = AutomaticStatus;
export const BusinessDocumentAutomaticStatus = AutomaticStatus;
export type BusinessDocumentTimestampStatus = TimestampStatus;
export const BusinessDocumentTimestampStatus = TimestampStatus;
export type UpdateBusinessDocumentErrorResponse = UpdateErrorResponse;
export type BusinessDocumentsSearchRefetch = ReturnType<
  typeof useSearchBusinessDocumentsSuspense
>['refetch'];

export type FetchBusinessDocumentsArgs = {
  page?: number;
  perPage?: number;
  query?: BusinessDocumentSearchQuery;
  onPageChange?: (page: number) => void;
};

export type FetchBusinessDocumentsHooks = (
  args: FetchBusinessDocumentsArgs
) => FetchBusinessDocumentsResult;

export type FetchBusinessDocumentsResult = {
  pagination: ModernPaginationProps;
  data: BusinessDocumentColumn[];
  refetch: BusinessDocumentsSearchRefetch;
  error: CommonError | null;
  isLoading: boolean;
};

export type FetchBusinessDocumentsSearchWithMfFileHooks = (
  args: FetchBusinessDocumentsArgs
) => FetchBusinessDocumentsSearchWithMfFileResult;

export type FetchBusinessDocumentsSearchWithMfFileResult = {
  pagination: ModernPaginationProps;
  data: BusinessDocumentColumnWithMfFile[];
  refetch: BusinessDocumentsSearchRefetch;
  isLoading: boolean;
  isError: boolean;
};

type BusinessDocumentSearchRefetch = ReturnType<
  typeof useGetBusinessDocumentSuspense
>['refetch'];
type FetchBusinessDocumentResult = {
  data: GetBusinessDocumentResponse;
  refetch: BusinessDocumentSearchRefetch;
};
export type FetchBusinessDocumentHooks = (
  businessDocumentId: string
) => FetchBusinessDocumentResult;
