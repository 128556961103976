import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import useCustomClient from '../../mutator/custom-instance';

// eslint-disable-next-line
export type SecondParameter<T extends (...args: any) => any> = T extends (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config: any,
  args: infer P
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => any
  ? P
  : never;

export const useLazyQuery = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  THooks extends (...args: any) => any = (...args: any) => any,
  TError = unknown,
  TData = Awaited<ReturnType<ReturnType<THooks>>>,
  TParameter = Parameters<Awaited<ReturnType<THooks>>>[0]
>(
  queryFn: Awaited<ReturnType<THooks>>,
  queryKeyFn: (param: TParameter) => QueryKey
) => {
  const client = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const fetch = useCallback(
    async (
      param: TParameter,
      request?: SecondParameter<ReturnType<typeof useCustomClient>>
    ) => {
      setIsLoading(true);
      const queryKey = queryKeyFn(param);
      try {
        return await client.ensureQueryData<unknown, TError, TData>({
          queryKey: queryKey,
          queryFn: ({ signal }) => queryFn(param, request, signal),
        });
      } finally {
        setIsLoading(false);
      }
    },
    [client, queryFn, queryKeyFn]
  );
  return {
    isLoading,
    fetch,
  };
};
