import {
  TReceivedInvoiceCondition,
  TSetupConditionProps,
} from '@/context/services/invoice/type';
import { useReceivedInvoiceOperationStatusOptions } from '@/features/invoices/hooks/useReceivedInvoiceOperationStatusOptions';
import { useTranslation } from '@/i18n';
import {
  FormSelect,
  type SelectProps,
} from '@moneyforward/ap-frontend-components';
import { FC, memo, useMemo } from 'react';

export const OperationStatus: FC<TSetupConditionProps> = memo(
  ({ control, name }) => {
    const { t } = useTranslation();
    const options = useReceivedInvoiceOperationStatusOptions();
    const selectProps: SelectProps = useMemo(
      () => ({
        options: options,
        maxTagCount: 1,
        mode: 'multiple',
      }),
      [options]
    );
    const labelProps = useMemo(
      () => ({
        text: t('label_operation_status'),
      }),
      [t]
    );

    return (
      <FormSelect<TReceivedInvoiceCondition>
        control={control}
        name={name}
        selectProps={selectProps}
        label={labelProps}
        inputId='invoice-search-operation-status'
        testId='invoice-search-operation-status'
      />
    );
  }
);
OperationStatus.displayName = 'OperationStatus';
