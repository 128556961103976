import { InvoiceColumn } from '@/context/services/invoice/type';
import { useEmptyState } from '@/features/invoices/components/List/hooks';
import { useNetworkStatus } from '@/hooks/useNetworkStatus';
import {
  ModernPaginationProps,
  Table,
  TableProps,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo, useMemo } from 'react';
import { useColumns } from './Columns';
import styles from './Table.module.scss';

const cx = classnames.bind(styles);

export type InternalTableProps = Omit<
  TableProps<InvoiceColumn>,
  'columns' | 'pagination' | 'emptyType'
>;

export type Props = {
  pagination: ModernPaginationProps;
  table: InternalTableProps;
};

export const InvoiceTable: FC<Props> = memo(({ pagination, table }) => {
  const columns = useColumns();
  const { emptyType, emptyMessage } = useEmptyState();

  return (
    <>
      <div className={cx(styles['container'])} data-testid='invoice-table'>
        <Table
          emptyType={emptyType}
          {...table}
          legacy={false}
          key={pagination.currentPage}
          testId='invoice-table'
          emptyText={emptyMessage}
          columns={columns}
          pagination={pagination}
        />
      </div>
    </>
  );
});
InvoiceTable.displayName = 'InvoiceTable';

export const LoadingInvoiceTable: FC = memo(() => {
  const isOnline = useNetworkStatus();
  const pagination = useMemo(
    () => ({
      currentPage: 1,
      maxPage: 1,
      perPage: 1,
      maxRecord: 0,
    }),
    []
  );
  const tableProps = useMemo(
    () => ({ data: [], loading: isOnline }),
    [isOnline]
  );
  return <InvoiceTable table={tableProps} pagination={pagination} />;
});
LoadingInvoiceTable.displayName = 'LoadingInvoiceTable';

type ErrorInvoiceTableProps = {
  error: Error | null;
};

export const ErrorInvoiceTable: FC<ErrorInvoiceTableProps> = memo(() => {
  const pagination = useMemo(
    () => ({
      currentPage: 1,
      maxPage: 1,
      perPage: 1,
      maxRecord: 0,
    }),
    []
  );
  const tableProps = useMemo(
    () => ({ data: [], loading: false, emptyType: 'error' }),
    []
  );
  return <InvoiceTable table={tableProps} pagination={pagination} />;
});
ErrorInvoiceTable.displayName = 'ErrorInvoiceTable';
