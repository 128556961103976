import { useTranslation } from '@/i18n';
import classnames from 'classnames/bind';
import { FC, ReactNode, memo } from 'react';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  description: ReactNode;
  idLabel: string;
  id: string;
  fileName: string;
};

export const ModifyConfirmationModalBody: FC<Props> = memo(
  ({ description, idLabel, id, fileName }) => {
    const { t } = useTranslation();
    return (
      <div className={cx(styles['container'])}>
        <div>
          {description}
          <ul>
            <li>
              {idLabel}: {id}
            </li>
            <li>
              {t('modal_body_file_name')}
              {fileName}
            </li>
          </ul>
        </div>
      </div>
    );
  }
);
ModifyConfirmationModalBody.displayName = 'ModifyConfirmationModalBody';
