import { useTranslation } from '@/i18n';
import {
  DatepickerProps,
  FormDatepicker,
} from '@moneyforward/ap-frontend-components';
import { memo, useMemo } from 'react';
import { Control, FieldPath, FieldValues } from 'react-hook-form';

type Props<TFieldValues extends FieldValues> = {
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
};

const _UpdatedAt = <TFieldValues extends FieldValues>({
  control,
  name,
}: Props<TFieldValues>) => {
  const { t } = useTranslation();
  const datepickerProps: DatepickerProps = useMemo(
    () => ({
      placeholder: [t('date_gteq_placeholder'), t('date_lteq_placeholder')],
    }),
    [t]
  );
  const labelProps = useMemo(
    () => ({
      text: t('label_uploaded_at'),
    }),
    [t]
  );

  return (
    <FormDatepicker<TFieldValues>
      control={control}
      name={name}
      inputId='select-uploaded-at'
      testId='select-uploaded-at'
      datepickerProps={datepickerProps}
      label={labelProps}
    />
  );
};

_UpdatedAt.displayName = '_UpdatedAt';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const UpdatedAt = memo((props) => (
  <_UpdatedAt {...props} />
)) as typeof _UpdatedAt;
UpdatedAt.displayName = 'UpdatedAt';
