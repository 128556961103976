import classnames from 'classnames/bind';
import { FC, memo, PropsWithChildren } from 'react';
import styles from './Header.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  className?: string;
  testId?: string;
};

export const Header: FC<PropsWithChildren<Props>> = memo(
  ({ className, children, testId }) => {
    return (
      <div data-testid={testId} className={cx(styles['container'], className)}>
        {children}
      </div>
    );
  }
);
Header.displayName = 'Header';
