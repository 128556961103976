import { BusinessDocumentSearch } from '@/features/business_documents/List/Search/Search';
import { BusinessDocumentTableView } from '@/features/business_documents/List/Table';
import {
  useBusinessDocumentFormInitial,
  useBusinessDocumentSearchQuery,
} from '@/features/business_documents/List/hooks';
import { memo } from 'react';

export const BusinessDocumentTableList = memo(() => {
  const defaultValues = useBusinessDocumentFormInitial();
  const { searchQuery, handleSearch } =
    useBusinessDocumentSearchQuery(defaultValues);

  return (
    <>
      <BusinessDocumentSearch
        defaultValues={defaultValues}
        onSearch={handleSearch}
      />
      <BusinessDocumentTableView
        searchQuery={searchQuery}
        onSearch={handleSearch}
      />
    </>
  );
});
BusinessDocumentTableList.displayName = 'BusinessDocumentTableList';
