import { useProcessingStatusChecker } from '@/features/business_documents/Detail/Context/ProcessingStatus';
import { useTranslation } from '@/i18n';
import { ButtonV2 } from '@moneyforward/ap-frontend-components';
import { FC, useMemo } from 'react';

type ArchiveButtonProps = {
  onClick: () => void;
  isDisabled: boolean;
};

export const ArchiveButton: FC<ArchiveButtonProps> = ({
  onClick,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const processingChecker = useProcessingStatusChecker();

  const isButtonDisabled = useMemo(() => {
    return isDisabled || processingChecker.waiting_for_ocr_input;
  }, [isDisabled, processingChecker.waiting_for_ocr_input]);

  if (processingChecker.isArchived) {
    return <></>;
  }

  return (
    <ButtonV2
      size='sm'
      color='danger'
      isSecondary
      onClick={onClick}
      isDisabled={isButtonDisabled}
      testId='business-document-archive-button'
    >
      {t('archive')}
    </ButtonV2>
  );
};
