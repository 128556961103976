import {
  FormInput,
  InputProps,
  LabelProps,
} from '@moneyforward/ap-frontend-components';
import { memo, useMemo } from 'react';
import { Control, FieldPath, FieldValues } from 'react-hook-form';

type Props<TFieldValues extends FieldValues> = {
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
  labelProps?: LabelProps;
  inputProps?: InputProps;
};

export const _KeywordCont = <TFieldValues extends FieldValues>({
  control,
  name,
  labelProps,
  inputProps: _inputProps,
}: Props<TFieldValues>) => {
  const inputProps = useMemo(
    () => ({
      search: true,
      ..._inputProps,
    }),
    [_inputProps]
  );

  return (
    <FormInput<TFieldValues>
      control={control}
      name={name}
      inputId='keyword_cont'
      inputProps={inputProps}
      label={labelProps}
      testId='keyword_cont'
    />
  );
};

_KeywordCont.displayName = '_KeywordCont';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const KeywordCont = memo((props) => (
  <_KeywordCont {...props} />
)) as typeof _KeywordCont;

KeywordCont.displayName = 'KeywordCont';
