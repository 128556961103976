import { Configuration } from 'rollbar';

export const rollbarConfig = (
  accessToken: string,
  environment: string,
  version: string
): Configuration => {
  return {
    accessToken: accessToken,
    environment: environment,
    captureUncaught: true,
    captureUnhandledRejections: true,
    version: version,
    payload: {
      environment: environment,
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: version,
          guess_uncaught_frames: true,
        },
      },
    },
  };
};
