/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * APIs for RCI FE
 * This document describes the APIs provided by NeoAP BE for RCI FE, designed to be integrated into NeoAP FE using `iframe`.\
These APIs utilize cookie-based authentication. All API requests must include the cookie `_ap_session`.

 * OpenAPI spec version: 1
 */

export type ApReportStatus =
  (typeof ApReportStatus)[keyof typeof ApReportStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApReportStatus = {
  unsubmitted: 'unsubmitted',
  waiting_step_one: 'waiting_step_one',
  waiting_step_two: 'waiting_step_two',
  waiting_step_three: 'waiting_step_three',
  waiting_step_four: 'waiting_step_four',
  waiting_step_five: 'waiting_step_five',
  waiting_step_six: 'waiting_step_six',
  waiting_step_seven: 'waiting_step_seven',
  waiting_step_eight: 'waiting_step_eight',
  waiting_step_nine: 'waiting_step_nine',
  waiting_step_ten: 'waiting_step_ten',
  approved: 'approved',
  canceled: 'canceled',
  rejected: 'rejected',
} as const;
