/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * APIs for RCI FE
 * This document describes the APIs provided by NeoAP BE for RCI FE, designed to be integrated into NeoAP FE using `iframe`.\
These APIs utilize cookie-based authentication. All API requests must include the cookie `_ap_session`.

 * OpenAPI spec version: 1
 */

export type BusinessDocumentDocumentType =
  (typeof BusinessDocumentDocumentType)[keyof typeof BusinessDocumentDocumentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BusinessDocumentDocumentType = {
  not_specified: 'not_specified',
  quotation: 'quotation',
  purchase_order: 'purchase_order',
  contract: 'contract',
  delivery_note: 'delivery_note',
  acceptance_certificate: 'acceptance_certificate',
  invoice: 'invoice',
  receipt: 'receipt',
  others: 'others',
} as const;
