import { Layout } from '@/Layout';
import { Invoice } from '@features/invoices';
import { FC } from 'react';

const InvoicePage: FC = () => {
  return (
    <Layout title='インボイス'>
      <Invoice />
    </Layout>
  );
};

export default InvoicePage;
