export const splitFileNameExtension = (
  value: string,
  allowedFileTypes?: string
) => {
  const names = value.split('.');

  let ext = names.length > 1 ? names.pop() : null;
  let name = names?.join('.');

  // If allowedFileTypes is provided, check the extension
  if (allowedFileTypes) {
    const allowedTypesPattern = allowedFileTypes.replaceAll(',', '|');

    const regex = new RegExp(`^(${allowedTypesPattern})$`);
    if (ext && !regex.test(ext)) {
      name = value;
      ext = null;
    }
  }

  return {
    name,
    ext,
  };
};
