import { ErrorBoundary } from '@/components/Rollbar';
import { useNotification } from '@moneyforward/ap-frontend-components';
import { FC, ReactNode, Suspense, useEffect } from 'react';
import { SuspenseLoading } from './fallback';

type ErrorFallbackProps = {
  onClose?: VoidFunction;
  error: Error | null;
};

const ErrorFallback: FC<ErrorFallbackProps> = ({ error, onClose }) => {
  const notify = useNotification();
  useEffect(() => {
    onClose?.();
    notify.error({
      title: error?.message ?? 'エラー',
      opts: {
        duration: 3000,
        style: {
          position: 'fixed',
        },
      },
    });
  }, [error, notify, onClose]);
  return <></>;
};
ErrorFallback.displayName = 'ErrorFallback';

type TSuspenseErrorBoundary = {
  children: ReactNode;
  onClose?: () => void;
  fallback?: ReactNode;
};

export const SuspenseErrorBoundary: FC<TSuspenseErrorBoundary> = (props) => {
  return (
    <Suspense fallback={props.fallback ?? <SuspenseLoading />}>
      <ErrorBoundary
        fallback={({ error }) => (
          <ErrorFallback onClose={props.onClose} error={error} />
        )}
      >
        {props.children}
      </ErrorBoundary>
    </Suspense>
  );
};
SuspenseErrorBoundary.displayName = 'SuspenseErrorBoundary';
