import { UploadLabel } from '@/context/services/invoice/type';
import { useGetFlags } from '@/hooks/useFeatureFlag';
import { SelectProps } from '@moneyforward/ap-frontend-components';
import { useMemo } from 'react';

export const useReceivedInvoiceUploadTypeOptions =
  (): SelectProps['options'] => {
    const flagMail = useGetFlags('SHOW_MAIL_OPTION');
    const flagPeppol = useGetFlags('SHOW_PEPPOL_OPTION');
    const options = useMemo(
      () =>
        [
          { value: 'agent_receive', label: UploadLabel.agent_receive || '' },
          {
            value: 'supplier_upload',
            label: UploadLabel.supplier_upload || '',
          },
        ]
          .concat(
            flagMail ? [{ value: 'email', label: UploadLabel.email || '' }] : []
          )
          .concat(
            flagPeppol
              ? [
                  {
                    value: 'peppol_gateway',
                    label: UploadLabel.peppol_gateway || '',
                  },
                ]
              : []
          ),
      [flagMail, flagPeppol]
    );

    return options;
  };
