import { useErrorInlineNotificationAPI } from '@/components/ErrorInlineNotification/ErrorInlineNotification';
import { useUpdateBuesinessDocumentDetail } from '@/context/services/business_document/BusinessDocument.service';
import { UpdateBusinessDocumentErrorResponse } from '@/context/services/business_document/type';
import { BusinessDocumentDetailForm } from '@/features/business_documents/Detail/components/Right/type';
import { useIsView } from '@/features/invoices/components/Details/Components/Context/ViewEdit';
import { useTranslation } from '@/i18n';
import { isCommonError, isResponseError } from '@/libs/typeguard/isError';
import { SendErrorTracking } from '@/utils/errors';
import { useNotification } from '@moneyforward/ap-frontend-components';
import { format } from 'date-util';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { SubmitHandler, UseFormSetError } from 'react-hook-form';

export const useSubmit = (
  id: string,
  setError: UseFormSetError<BusinessDocumentDetailForm>,
  setPicError: Dispatch<SetStateAction<string[]>>
) => {
  const setErrorNotification = useErrorInlineNotificationAPI();
  const { t } = useTranslation();
  const notify = useNotification();
  const { onView } = useIsView();
  const [isLoading, setLoading] = useState(false);
  const { mutateAsync } = useUpdateBuesinessDocumentDetail();
  const onSubmit: SubmitHandler<BusinessDocumentDetailForm> = useCallback(
    async (form) => {
      setLoading(true);
      try {
        const resp = await mutateAsync({
          id,
          data: {
            business_document: {
              amount: form.amount,
              counterparty_name: form.counterpartyName,
              document_title: form.documentTitle,
              document_type: form.documentType,
              memo: form.memo,
              pic_ids: form.formPics,
              receipt_type: form.receiptType,
              transaction_date: format(form.transactionDate, 'YYYY-MM-DD'),
            },
          },
        });
        if (resp.status === 'success') {
          notify.success({
            title: t('business_document_update_successful'),
            opts: {
              duration: 3000,
              style: {
                position: 'fixed',
              },
            },
            bodyProps: {
              disabledSuffix: true,
            },
          });
          onView();
        } else if (resp.status === 'error') {
          setErrorNotification(new Error(resp.errors.messages?.join('\n')));
        } else {
          if (
            isResponseError<UpdateBusinessDocumentErrorResponse>(
              resp.errors,
              'errors'
            )
          ) {
            Object.entries(resp.errors.errors ?? {}).forEach(
              ([key, values]) => {
                values.forEach((value) => {
                  const message = value.message;
                  switch (key) {
                    case 'amount':
                      setError('amount', {
                        type: 'validate',
                        message: message,
                      });
                      return;
                    case 'counterparty_name':
                      setError('counterpartyName', {
                        type: 'validate',
                        message: message,
                      });
                      return;
                    case 'document_title':
                      setError('documentTitle', {
                        type: 'validate',
                        message: message,
                      });
                      return;
                    case 'document_type':
                      setError('documentType', {
                        type: 'validate',
                        message: message,
                      });
                      return;
                    case 'pic_ids':
                      if (value.code === 'not_found') {
                        const v = value.value;
                        if (Array.isArray(v)) {
                          setPicError((old) => [...old, ...v]);
                        }
                      }
                      setError('formPics', {
                        type: 'validate',
                        message: message,
                      });
                      return;
                    case 'receipt_type':
                      setError('receiptType', {
                        type: 'validate',
                        message: message,
                      });
                      return;
                    case 'transaction_date':
                      setError('transactionDate', {
                        type: 'validate',
                        message: message,
                      });
                      return;
                    case 'memo':
                      setError('memo', {
                        type: 'validate',
                        message: message,
                      });
                      return;
                    case 'status':
                      setErrorNotification(new Error(message));
                      return;
                    default:
                      return;
                  }
                });
              }
            );
          }
        }
      } catch (err) {
        if (err instanceof Error && err?.name === 'AxiosError') {
          setErrorNotification(new SendErrorTracking(err));
        } else if (err && isCommonError(err)) {
          setErrorNotification(new Error(err.messages?.join('\n')));
        } else {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          setErrorNotification(new SendErrorTracking(err as Error));
        }
      } finally {
        setLoading(false);
      }
    },
    [
      id,
      mutateAsync,
      notify,
      onView,
      setError,
      setErrorNotification,
      setPicError,
      t,
    ]
  );
  return {
    isLoading,
    onSubmit,
  };
};
