import { FunctionHeader } from '@/components/FunctionHeader';
import styles from '@/features/business_documents/Detail/components/Right/BusinessDocumentView/index.module.scss';
import { useBusinessDocumentDetailLabel } from '@/features/business_documents/Detail/components/Right/hooks/useBusinessDocumentDetail';
import { BusinessDocumentData } from '@/features/business_documents/Detail/components/Right/type';
import { ProcessingStatusTag } from '@/features/business_documents/components/ProcessingStatus';
import { useIsView } from '@/features/invoices/components/Details/Components/Context/ViewEdit';
import { useTranslation } from '@/i18n';
import {
  Bottom,
  ButtonV2,
  ListView,
  ListViewBody,
  ListViewLabel,
  Top,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo, useMemo } from 'react';

const cx = classnames.bind(styles);

type TBusinessDocumentContentViewProps = {
  data: BusinessDocumentData;
  isEditable: boolean;
};

export const BusinessDocumentView: FC<TBusinessDocumentContentViewProps> = memo(
  ({ data, isEditable }) => {
    const { t } = useTranslation();
    const { onEdit } = useIsView();
    const {
      timeStampLabel,
      documentTitleLabel,
      documentTypeLabel,
      counterpartyNameLabel,
      picsLabel,
      transactionDateLabel,
      amountLabel,
      memoLabel,
      receiptTypeLabel,
    } = useBusinessDocumentDetailLabel(data);
    const leftRightRatio = useMemo(
      () => ({
        left: {
          value: '30%',
        },
        right: {
          value: '70%',
        },
      }),
      []
    );

    return (
      <FunctionHeader
        functionName={t('business_document_info')}
        testId='business-document-detail'
        button={
          isEditable && (
            <ButtonV2
              color='primary'
              size='xs'
              isSecondary
              onClick={onEdit}
              testId='business-document-edit-button'
            >
              {t('edit')}
            </ButtonV2>
          )
        }
      >
        <div className={cx(styles['container'])}>
          <ListView
            leftRightRatio={leftRightRatio}
            testId='business-document-detail'
          >
            <Top>
              <ListViewLabel key='left'>
                {t('business_document_file_name')}
              </ListViewLabel>
              <ListViewBody key='right'>
                <div>{documentTitleLabel}</div>
                <ProcessingStatusTag status={timeStampLabel} />
              </ListViewBody>
            </Top>
            <Top>
              <ListViewLabel key='left'>
                {t('business_document_receipt_type')}
              </ListViewLabel>
              <ListViewBody key='right'>{receiptTypeLabel}</ListViewBody>
            </Top>
            <Top>
              <ListViewLabel key='left'>
                {t('business_document_document_type')}
              </ListViewLabel>
              <ListViewBody key='right'>{documentTypeLabel}</ListViewBody>
            </Top>
            <Top>
              <ListViewLabel key='left'>
                {t('business_document_pics')}
              </ListViewLabel>
              <ListViewBody key='right'>{picsLabel}</ListViewBody>
            </Top>
            <Top>
              <ListViewLabel key='left'>
                {t('business_document_counterparty_name')}
              </ListViewLabel>
              <ListViewBody key='right'>{counterpartyNameLabel}</ListViewBody>
            </Top>
            <Top>
              <ListViewLabel key='left'>
                {t('business_document_transaction_date')}
              </ListViewLabel>
              <ListViewBody key='right'>{transactionDateLabel}</ListViewBody>
            </Top>
            <Top>
              <ListViewLabel key='left'>
                {t('business_document_amount')}
              </ListViewLabel>
              <ListViewBody key='right'>{amountLabel}</ListViewBody>
            </Top>
            <Bottom>
              <ListViewLabel key='left'>
                {t('business_document_memo')}
              </ListViewLabel>
              <ListViewBody
                key='right'
                className={cx({ [styles['memoContainer']]: !!memoLabel })}
              >
                {memoLabel}
              </ListViewBody>
            </Bottom>
          </ListView>
        </div>
      </FunctionHeader>
    );
  }
);

BusinessDocumentView.displayName = 'BusinessDocumentView';
