import classnames from 'classnames/bind';
import { FC, PropsWithChildren, memo } from 'react';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  testId?: string;
};

export const Label: FC<PropsWithChildren<Props>> = memo(
  ({ children, testId }) => {
    return (
      <div className={cx(styles['container'])} data-testid={testId}>
        {children}
      </div>
    );
  }
);
Label.displayName = 'ListViewLabel';
