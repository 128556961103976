import { useOperationStatusChecker } from '@/features/invoices/components/Details/Components/Context/OperationStatus';
import { useTranslation } from '@/i18n';
import {
  IconApproval,
  IconCaution,
  InlineNotification,
} from '@moneyforward/ap-frontend-components';
import { FC, memo } from 'react';

export type Props = {};

export const Notification: FC<Props> = memo(({}) => {
  const { t } = useTranslation();
  const operationChecker = useOperationStatusChecker();
  return (
    <>
      {operationChecker.isArchived && (
        // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
        <div style={{ width: '100%' }}>
          <InlineNotification
            type='alert'
            visible
            message={t('「ゴミ箱」に移動した請求書です')}
            icon={<IconCaution size={24} />}
            visibleClose={false}
          />
        </div>
      )}
      {operationChecker.isOcrProcessing && (
        // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
        <div style={{ width: '100%' }}>
          <InlineNotification
            type='success'
            visible
            message={t('OCR読取り中です。しばらくお待ちください。')}
            icon={<IconApproval size={24} />}
            visibleClose={false}
          />
        </div>
      )}
    </>
  );
});
Notification.displayName = 'Notification';
