import { BusinessDocumentDocumentType } from '@/context/services/business_document/type';
import {
  DocumentTypeLabel,
  TypeOfDocumentType,
} from '@/features/business_documents/List/type';

export type DocumentTypeValues = {
  [K in DocumentTypeLabel as TypeOfDocumentType[K]]: K;
};

const DocumentTypesValue: DocumentTypeValues = Object.entries(
  BusinessDocumentDocumentType
).reduce(
  (prev, [key, value]) => ({ ...prev, [value]: key }),
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as DocumentTypeValues
);

export const convertDocumentTypeKeyToValue = (
  value: BusinessDocumentDocumentType
) => {
  const v = DocumentTypesValue[value];
  if (!v) {
    throw new Error('Type conversion failed.');
  }
  return v;
};

export const typeGuardDocumentTypeValue = (
  value: BusinessDocumentDocumentType | undefined
): value is BusinessDocumentDocumentType => {
  if (value === undefined) throw new Error('Type guard failed.');
  return value in DocumentTypesValue;
};
