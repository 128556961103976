import { FC, PropsWithChildren, createContext, useContext } from 'react';
import {
  ReceivedInvoiceSearchRefetch,
  ReceivedInvoicesSearchRefetch,
} from './type';

const receivedInvoicesSearchContext = createContext<
  ReceivedInvoicesSearchRefetch | undefined
>(undefined);

type TValue<T extends Function> = {
  value: T;
};

export const ReceivedInvoicesSearchRefetchProvider: FC<
  PropsWithChildren<TValue<ReceivedInvoicesSearchRefetch>>
> = ({ children, value }) => {
  return (
    <receivedInvoicesSearchContext.Provider value={value}>
      {children}
    </receivedInvoicesSearchContext.Provider>
  );
};

export const useReceivedInvoicesSearchRefetch = ():
  | ReceivedInvoicesSearchRefetch
  | undefined => useContext(receivedInvoicesSearchContext);

const receivedInvoiceSearchContext = createContext<
  ReceivedInvoiceSearchRefetch | undefined
>(undefined);

export const ReceivedInvoiceSearchRefetchProvider: FC<
  PropsWithChildren<TValue<ReceivedInvoiceSearchRefetch>>
> = ({ children, value }) => {
  return (
    <receivedInvoiceSearchContext.Provider value={value}>
      {children}
    </receivedInvoiceSearchContext.Provider>
  );
};

export const useReceivedInvoiceSearchRefetch = ():
  | ReceivedInvoiceSearchRefetch
  | undefined => useContext(receivedInvoiceSearchContext);
