import {
  TBusinessDocumentCondition,
  TSetupConditionProps,
} from '@/features/business_documents/UploadForm/Content/type';
import { useTranslation } from '@/i18n';
import {
  FormDatepicker,
  type DatepickerProps,
} from '@moneyforward/ap-frontend-components';
import { FC, memo, useMemo } from 'react';

export const TransactionDate: FC<TSetupConditionProps> = memo(
  ({ control, name }) => {
    const { t } = useTranslation();
    const datepickerProps: DatepickerProps = useMemo(
      () => ({
        placeholder: [t('date_gteq_placeholder'), t('date_lteq_placeholder')],
      }),
      [t]
    );
    const labelProps = useMemo(
      () => ({
        text: t('business_document_transaction_date'),
      }),
      [t]
    );
    return (
      <FormDatepicker<TBusinessDocumentCondition>
        control={control}
        name={name}
        inputId='transaction_date'
        datepickerProps={datepickerProps}
        label={labelProps}
        testId='transaction-date'
      />
    );
  }
);
TransactionDate.displayName = 'TransactionDate';
