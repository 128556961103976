import {
  TProcessingStatus,
  useProcessingStatusTag,
} from '@/features/business_documents/hooks/useProcessingStatusTag';
import { Tag } from '@moneyforward/ap-frontend-components';
import { FC, memo } from 'react';

type Props = {
  status: TProcessingStatus;
};

export const ProcessingStatusTag: FC<Props> = memo(({ status }) => {
  const { label, colorType } = useProcessingStatusTag(status);
  if (!label && !colorType) {
    return <></>;
  }

  return (
    <Tag key={colorType} status={colorType} closable={false}>
      {label}
    </Tag>
  );
});

ProcessingStatusTag.displayName = 'ProcessingStatusTag';
