import { useTranslation } from '@/i18n';
import { ButtonGroup, ButtonV2 } from '@moneyforward/ap-frontend-components';
import { FC, memo } from 'react';

export type FooterProps = {
  onCancelClick: () => void;
  onModifyClick: () => void;
};

export const ModifyConfirmationModalFooter: FC<FooterProps> = memo(
  ({ onCancelClick, onModifyClick }) => {
    const { t } = useTranslation();

    return (
      <ButtonGroup>
        <ButtonV2 color='primary' isSecondary onClick={onCancelClick} size='sm'>
          {t('cancel')}
        </ButtonV2>
        <ButtonV2 color='primary' onClick={onModifyClick} size='sm'>
          {t('modify')}
        </ButtonV2>
      </ButtonGroup>
    );
  }
);
ModifyConfirmationModalFooter.displayName = 'ModifyConfirmationModalFooter';
