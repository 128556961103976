import { useMemo } from 'react';

export const useMakeCopyIdURL = (id: string, param: string = 'id') => {
  return useMemo(() => {
    const url = new URL(window.location.origin);
    url.pathname = window.location.pathname;
    const q = url.searchParams;
    q.set(param, id);
    return url.toString();
  }, [id, param]);
};
