import { createInstance, i18n as Ti18n } from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import ja from './locales/ja.json';

export const i18n: Ti18n = createInstance({
  fallbackLng: 'ja',
  returnEmptyString: false,
  interpolation: {
    escapeValue: false,
  },
  resources: {
    ja: {
      apps: ja,
    },
    en: {
      apps: en,
    },
  },
});

i18n.use(initReactI18next).init();
