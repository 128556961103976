import { SubHeader } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, ReactNode, memo } from 'react';
import styles from './Layout.module.scss';

const cx = classnames.bind(styles);

type Props = {
  children: ReactNode;
  title?: string;
};

export const Layout: FC<Props> = memo(({ children, title }) => {
  return (
    <div>
      <div className={cx(styles['container'])}>
        <div className={cx(styles['top'])}>
          <SubHeader title={title || ''} />
        </div>
        <div className={cx(styles['bottom'])}>
          <main className={cx(styles['content'])}>{children}</main>
        </div>
      </div>
    </div>
  );
});
Layout.displayName = 'Layout';
