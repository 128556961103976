import {
  InvoiceColumn,
  OperationStatus,
  OperationStatusLabel,
  UploadLabel,
  UploadType,
} from '@/context/services/invoice/type';
import { useTranslation } from '@/i18n';
import { splitFileNameExtension } from '@/libs/files/split';
import { IconMemo } from '@moneyforward/ap-frontend-components';
import { Tooltip } from 'antd';
import type { ColumnType } from 'antd/es/table/interface';
import classnames from 'classnames/bind';
import { formatDateWithTime } from 'date-util';
import { Dayjs } from 'dayjs';
import styles from './columns.module.scss';

const cx = classnames.bind(styles);

export const useColumns = (): ColumnType<InvoiceColumn>[] => {
  const { t } = useTranslation();
  return [
    {
      title: t('インボイスID'),
      dataIndex: 'id',
      key: 'id',
      render(value) {
        return `IV-${value}`;
      },
      width: '70px',
    },
    {
      title: t('ファイル名'),
      dataIndex: 'fileName',
      key: 'fileName',
      render(value: string) {
        const { name, ext } = splitFileNameExtension(value ?? '');
        return (
          <div className={cx(styles['fileNameContainer'])}>
            <div className={cx(styles['fileName'])}>{name}</div>
            {ext && <div className={cx(styles['fileExtension'])}>.{ext}</div>}
          </div>
        );
      },
      className: cx(styles['fileTd']),
    },
    {
      title: (
        <>
          <div>{t('支払先コード')}</div>
          <div>{t('label_ap_payee')}</div>
        </>
      ),
      key: 'payeeCode',
      dataIndex: 'payeeCode',
      render(value, record) {
        return (
          <div className={cx(styles['payeeContainer'])}>
            <div className={cx(styles['payee'])}>{value}</div>
            <div className={cx(styles['payee'])}>{record.payeeName}</div>
          </div>
        );
      },
      width: '220px',
    },
    {
      title: t('請求書担当者'),
      key: 'pics',
      render(_, record) {
        return <div>{record.pics.map((pic) => pic.name).join(',')}</div>;
      },
      width: '140px',
    },
    {
      title: t('アップロード時刻'),
      dataIndex: 'uploadedAt',
      render(value: Dayjs) {
        return formatDateWithTime(value);
      },
      width: '140px',
    },
    {
      title: t('受領方法'),
      dataIndex: 'uploadType',
      render(value: UploadType) {
        return <div>{UploadLabel[value]}</div>;
      },
      width: '90px',
    },
    {
      title: (
        <>
          <div>{t('作業')}</div>
          <div>{t('ステータス')}</div>
        </>
      ),
      dataIndex: 'operationStatus',
      render(value: OperationStatus) {
        return OperationStatusLabel[value];
      },
      width: '110px',
    },
    {
      title: t('メモ'),
      dataIndex: 'memo',
      render(value) {
        return (
          <Tooltip title={value}>
            <div className={cx(styles['memo'])} data-testid='memo-tooltip'>
              <IconMemo />
            </div>
          </Tooltip>
        );
      },
      width: '50px',
    },
  ];
};
