import { Layout } from '@/Layout';
import { useErrorInlineNotification } from '@/components/ErrorInlineNotification';
import { Error404 } from '@/features/errors/404';
import {
  IgnoreErrorTracking,
  InvalidError,
  SendErrorTracking,
} from '@/utils/errors';
import { ButtonGroup, ButtonV2 } from '@moneyforward/ap-frontend-components';
import { FC, memo, useCallback } from 'react';

const DebugPage: FC = memo(({}) => {
  const [{ setError }, ErrorContextHolder, ErrorInlineNotificationProvider] =
    useErrorInlineNotification();
  const onInvalidError = useCallback(() => {
    throw new InvalidError('エラー');
  }, []);
  const onIgnoreError = useCallback(() => {
    throw new IgnoreErrorTracking('Ignoreエラー');
  }, []);
  const onSendError = useCallback(() => {
    const err = new SendErrorTracking('Sendエラー');
    setError(err);
  }, [setError]);

  return (
    <Layout title='テスト'>
      <ErrorInlineNotificationProvider value={setError}>
        {ErrorContextHolder}
        <ButtonGroup>
          <ButtonV2 onClick={onInvalidError}>InvalidError</ButtonV2>
          <ButtonV2 onClick={onIgnoreError}>IgnoreError</ButtonV2>
          <ButtonV2 onClick={onSendError}>SendError</ButtonV2>
        </ButtonGroup>
      </ErrorInlineNotificationProvider>
    </Layout>
  );
});
DebugPage.displayName = 'DebugPage';

const Page: FC = memo(() => {
  if (import.meta.env.PROD) {
    return <Error404 />;
  }
  return <DebugPage />;
});
Page.displayName = 'Page';
export default Page;
