import { useOperationStatusChecker } from '@/features/invoices/components/Details/Components/Context/OperationStatus';
import { useIsView } from '@/features/invoices/components/Details/Components/Context/ViewEdit';
import { useTranslation } from '@/i18n';
import {
  Tooltip as BaseTooltip,
  ButtonV2,
  ButtonV2Size,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import {
  FC,
  PropsWithChildren,
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  onClick: () => Promise<void>;
  size: ButtonV2Size;
  isDisabled?: boolean;
};

export const CreateInvoiceReportButton: FC<Props> = memo(
  ({ onClick: _onClick, size, isDisabled: _isDisabled }) => {
    const { t } = useTranslation();
    const { isView } = useIsView();
    const operationChecker = useOperationStatusChecker();
    const [isLoading, setLoading] = useState(false);
    const onClick = useCallback(async () => {
      setLoading(true);
      try {
        await _onClick();
      } finally {
        setLoading(false);
      }
    }, [_onClick]);
    const isDisabled = useMemo(() => {
      return (
        _isDisabled ||
        !isView ||
        operationChecker.isEdit ||
        operationChecker.isOcrProcessing
      );
    }, [
      _isDisabled,
      isView,
      operationChecker.isEdit,
      operationChecker.isOcrProcessing,
    ]);
    const Tooltip: FC<PropsWithChildren> = ({ children }) => {
      const message = useMemo(
        () => (
          <div className={cx(styles['container'])}>
            <div>すでに、下書き状態の支払依頼申請が作成されています。</div>
          </div>
        ),
        []
      );
      return isDisabled && !operationChecker.isNotStated ? (
        <BaseTooltip
          overlayClassName={cx(styles['tooltipContainer'])}
          title={message}
          placement='bottomRight'
          wrapSpanTag
        >
          {children}
        </BaseTooltip>
      ) : (
        <>{children}</>
      );
    };
    return (
      <>
        {!(
          operationChecker.isApplied ||
          operationChecker.isArchived ||
          operationChecker.isOcrProcessing
        ) && (
          <Tooltip>
            <ButtonV2
              size={size}
              isDisabled={isDisabled}
              isLoading={isLoading}
              onClick={onClick}
            >
              {t('支払依頼を作成')}
            </ButtonV2>
          </Tooltip>
        )}
      </>
    );
  }
);
CreateInvoiceReportButton.displayName = 'CreateInvoiceReportButton';
