import { Layout } from '@/Layout';
import { BusinessDocuments } from '@/features/business_documents/businessDocuments';
import { useTranslation } from '@/i18n';

const BusinessDocumentsPage = () => {
  const { t } = useTranslation();

  return (
    <Layout title={t('business_document_page_title')}>
      <BusinessDocuments />
    </Layout>
  );
};
export default BusinessDocumentsPage;
