import classnames from 'classnames/bind';
import { FC, PropsWithChildren, memo } from 'react';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  className?: string;
};

export const Body: FC<PropsWithChildren<Props>> = memo(
  ({ children, className }) => {
    return <div className={cx(styles['container'], className)}>{children}</div>;
  }
);
Body.displayName = 'ListViewBody';
