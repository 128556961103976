import { BusinessDocumentDocumentType } from '@/context/services/business_document/type';
import { useTranslation } from '@/i18n';
import { useMemo } from 'react';

type DocumentTypeOption = {
  value: BusinessDocumentDocumentType;
  label: string;
};

type DocumentTypeMap = Record<BusinessDocumentDocumentType, string>;

function useDocumentTypeOptions(): DocumentTypeOption[] {
  const { t } = useTranslation();

  return useMemo(
    () =>
      Object.values(BusinessDocumentDocumentType).map((type) => ({
        value: type,
        label: t(`document_type_${type}`),
      })),
    [t]
  );
}

function useDocumentTypeLabel(): DocumentTypeMap {
  const { t } = useTranslation();

  return useMemo(
    () =>
      Object.values(BusinessDocumentDocumentType).reduce(
        (acc, type) => ({
          ...acc,
          [type]: t(`document_type_${type}`),
        }),
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        {} as DocumentTypeMap
      ),
    [t]
  );
}

export { useDocumentTypeLabel, useDocumentTypeOptions };
