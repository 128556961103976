import { useTranslation } from '@/i18n';
import { ButtonGroup, ButtonV2 } from '@moneyforward/ap-frontend-components';
import { FC, memo, useCallback, useState } from 'react';

export type FooterProps = {
  onCancelClick: () => void;
  onArchiveClick: () => void;
  submitText?: string;
};

export const ArchiveConfirmationModalFooter: FC<FooterProps> = memo(
  ({ onCancelClick, onArchiveClick: _onArchiveClick, submitText }) => {
    const { t } = useTranslation();
    const [isLoading, setLoading] = useState(false);
    const onArchiveClick = useCallback(() => {
      setLoading(true);
      _onArchiveClick();
    }, [_onArchiveClick]);

    return (
      <ButtonGroup>
        <ButtonV2
          color='primary'
          isSecondary
          onClick={onCancelClick}
          isDisabled={isLoading}
          size='sm'
        >
          {t('cancel')}
        </ButtonV2>
        <ButtonV2
          color='primary'
          onClick={onArchiveClick}
          isLoading={isLoading}
          size='sm'
        >
          {submitText ?? t('移動')}
        </ButtonV2>
      </ButtonGroup>
    );
  }
);
ArchiveConfirmationModalFooter.displayName = 'ArchiveConfirmationModalFooter';
