import { useFeatureFlag } from 'packages-featureflags';
import usePromise from 'react-promise-suspense';

export const useGetFlags = (name: string, skip: boolean = false) => {
  const flagModule = useFeatureFlag();
  return usePromise(flagModule.module.check.bind(flagModule.module), [
    name,
    skip,
  ]);
};
