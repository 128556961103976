/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * APIs for RCI FE
 * This document describes the APIs provided by NeoAP BE for RCI FE, designed to be integrated into NeoAP FE using `iframe`.\
These APIs utilize cookie-based authentication. All API requests must include the cookie `_ap_session`.

 * OpenAPI spec version: 1
 */
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import type { BadRequestResponse } from '../model/badRequestResponse';
import type { BulkUploadBusinessDocumentsErrorResponse } from '../model/bulkUploadBusinessDocumentsErrorResponse';
import type { BulkUploadBusinessDocumentsRequest } from '../model/bulkUploadBusinessDocumentsRequest';
import type { BulkUploadBusinessDocumentsResponse } from '../model/bulkUploadBusinessDocumentsResponse';
import type { ForbiddenResponse } from '../model/forbiddenResponse';
import type { GetBusinessDocumentResponse } from '../model/getBusinessDocumentResponse';
import type { NotFoundResponse } from '../model/notFoundResponse';
import type { SearchBusinessDocumentsRequest } from '../model/searchBusinessDocumentsRequest';
import type { SearchBusinessDocumentsResponse } from '../model/searchBusinessDocumentsResponse';
import type { UnauthorizedResponse } from '../model/unauthorizedResponse';
import type { UnprocessableEntityResponse } from '../model/unprocessableEntityResponse';
import type { UpdateBusinessDocumentErrorResponse } from '../model/updateBusinessDocumentErrorResponse';
import type { UpdateBusinessDocumentRequest } from '../model/updateBusinessDocumentRequest';
import type { UpdateBusinessDocumentResponse } from '../model/updateBusinessDocumentResponse';
import { useCustomClient } from '../../mutator/custom-instance';
import type { ErrorType, BodyType } from '../../mutator/custom-instance';
import { customFomDataFn } from '../../api/formData/custom-function';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * @summary Bulk upload business documents
 */
export const useBulkUploadBusinessDocumentHook = () => {
  const bulkUploadBusinessDocument =
    useCustomClient<BulkUploadBusinessDocumentsResponse>();

  return (
    bulkUploadBusinessDocumentsRequest: BodyType<BulkUploadBusinessDocumentsRequest>,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>
  ) => {
    const formData = customFomDataFn(bulkUploadBusinessDocumentsRequest);
    return bulkUploadBusinessDocument(
      {
        url: `/api/js/business_document/v1/bulk/business_documents`,
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
      },
      options
    );
  };
};

export const useBulkUploadBusinessDocumentMutationOptions = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | BulkUploadBusinessDocumentsErrorResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useBulkUploadBusinessDocumentHook>>>,
    TError,
    { data: BodyType<BulkUploadBusinessDocumentsRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useBulkUploadBusinessDocumentHook>>>,
  TError,
  { data: BodyType<BulkUploadBusinessDocumentsRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const bulkUploadBusinessDocument = useBulkUploadBusinessDocumentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useBulkUploadBusinessDocumentHook>>>,
    { data: BodyType<BulkUploadBusinessDocumentsRequest> }
  > = (props) => {
    const { data } = props ?? {};

    return bulkUploadBusinessDocument(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type BulkUploadBusinessDocumentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useBulkUploadBusinessDocumentHook>>>
>;
export type BulkUploadBusinessDocumentMutationBody =
  BodyType<BulkUploadBusinessDocumentsRequest>;
export type BulkUploadBusinessDocumentMutationError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | BulkUploadBusinessDocumentsErrorResponse
>;

/**
 * @summary Bulk upload business documents
 */
export const useBulkUploadBusinessDocument = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | BulkUploadBusinessDocumentsErrorResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useBulkUploadBusinessDocumentHook>>>,
    TError,
    { data: BodyType<BulkUploadBusinessDocumentsRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}) => {
  const mutationOptions = useBulkUploadBusinessDocumentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get a list of business documents (new API)
 */
export const useSearchBusinessDocumentsHook = () => {
  const searchBusinessDocuments =
    useCustomClient<SearchBusinessDocumentsResponse>();

  return (
    searchBusinessDocumentsRequest: BodyType<SearchBusinessDocumentsRequest>,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>
  ) => {
    return searchBusinessDocuments(
      {
        url: `/api/js/business_document/v1/business_documents/search`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: searchBusinessDocumentsRequest,
      },
      options
    );
  };
};

export const getSearchBusinessDocumentsQueryKey = (
  searchBusinessDocumentsRequest: SearchBusinessDocumentsRequest
) => {
  return [
    `/api/js/business_document/v1/business_documents/search`,
    searchBusinessDocumentsRequest,
  ] as const;
};

export const useSearchBusinessDocumentsQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useSearchBusinessDocumentsHook>>
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  searchBusinessDocumentsRequest: SearchBusinessDocumentsRequest,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useSearchBusinessDocumentsHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSearchBusinessDocumentsQueryKey(searchBusinessDocumentsRequest);

  const searchBusinessDocuments = useSearchBusinessDocumentsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useSearchBusinessDocumentsHook>>>
  > = () =>
    searchBusinessDocuments(searchBusinessDocumentsRequest, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useSearchBusinessDocumentsHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchBusinessDocumentsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSearchBusinessDocumentsHook>>>
>;
export type SearchBusinessDocumentsQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get a list of business documents (new API)
 */
export const useSearchBusinessDocuments = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useSearchBusinessDocumentsHook>>
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  searchBusinessDocumentsRequest: SearchBusinessDocumentsRequest,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useSearchBusinessDocumentsHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useSearchBusinessDocumentsQueryOptions(
    searchBusinessDocumentsRequest,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useSearchBusinessDocumentsSuspenseQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useSearchBusinessDocumentsHook>>
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  searchBusinessDocumentsRequest: SearchBusinessDocumentsRequest,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useSearchBusinessDocumentsHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSearchBusinessDocumentsQueryKey(searchBusinessDocumentsRequest);

  const searchBusinessDocuments = useSearchBusinessDocumentsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useSearchBusinessDocumentsHook>>>
  > = () =>
    searchBusinessDocuments(searchBusinessDocumentsRequest, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useSearchBusinessDocumentsHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchBusinessDocumentsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSearchBusinessDocumentsHook>>>
>;
export type SearchBusinessDocumentsSuspenseQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get a list of business documents (new API)
 */
export const useSearchBusinessDocumentsSuspense = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useSearchBusinessDocumentsHook>>
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  searchBusinessDocumentsRequest: SearchBusinessDocumentsRequest,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useSearchBusinessDocumentsHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useSearchBusinessDocumentsSuspenseQueryOptions(
    searchBusinessDocumentsRequest,
    options
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get details of a business document (new API)
 */
export const useGetBusinessDocumentHook = () => {
  const getBusinessDocument = useCustomClient<GetBusinessDocumentResponse>();

  return (
    id: string,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>,
    signal?: AbortSignal
  ) => {
    return getBusinessDocument(
      {
        url: `/api/js/business_document/v1/business_documents/${id}`,
        method: 'GET',
        signal,
      },
      options
    );
  };
};

export const getGetBusinessDocumentQueryKey = (id: string) => {
  return [`/api/js/business_document/v1/business_documents/${id}`] as const;
};

export const useGetBusinessDocumentQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBusinessDocumentHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetBusinessDocumentHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBusinessDocumentQueryKey(id);

  const getBusinessDocument = useGetBusinessDocumentHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetBusinessDocumentHook>>>
  > = ({ signal }) => getBusinessDocument(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetBusinessDocumentHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBusinessDocumentQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetBusinessDocumentHook>>>
>;
export type GetBusinessDocumentQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get details of a business document (new API)
 */
export const useGetBusinessDocument = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBusinessDocumentHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetBusinessDocumentHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetBusinessDocumentQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetBusinessDocumentSuspenseQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBusinessDocumentHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetBusinessDocumentHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBusinessDocumentQueryKey(id);

  const getBusinessDocument = useGetBusinessDocumentHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetBusinessDocumentHook>>>
  > = ({ signal }) => getBusinessDocument(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetBusinessDocumentHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBusinessDocumentSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetBusinessDocumentHook>>>
>;
export type GetBusinessDocumentSuspenseQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get details of a business document (new API)
 */
export const useGetBusinessDocumentSuspense = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBusinessDocumentHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetBusinessDocumentHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetBusinessDocumentSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Update a Business document (new API)
 */
export const useUpdateBusinessDocumentHook = () => {
  const updateBusinessDocument =
    useCustomClient<UpdateBusinessDocumentResponse>();

  return (
    id: string,
    updateBusinessDocumentRequest: BodyType<UpdateBusinessDocumentRequest>,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>
  ) => {
    return updateBusinessDocument(
      {
        url: `/api/js/business_document/v1/business_documents/${id}`,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        data: updateBusinessDocumentRequest,
      },
      options
    );
  };
};

export const useUpdateBusinessDocumentMutationOptions = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UpdateBusinessDocumentErrorResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateBusinessDocumentHook>>>,
    TError,
    { id: string; data: BodyType<UpdateBusinessDocumentRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateBusinessDocumentHook>>>,
  TError,
  { id: string; data: BodyType<UpdateBusinessDocumentRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const updateBusinessDocument = useUpdateBusinessDocumentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateBusinessDocumentHook>>>,
    { id: string; data: BodyType<UpdateBusinessDocumentRequest> }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateBusinessDocument(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateBusinessDocumentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateBusinessDocumentHook>>>
>;
export type UpdateBusinessDocumentMutationBody =
  BodyType<UpdateBusinessDocumentRequest>;
export type UpdateBusinessDocumentMutationError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UpdateBusinessDocumentErrorResponse
>;

/**
 * @summary Update a Business document (new API)
 */
export const useUpdateBusinessDocument = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UpdateBusinessDocumentErrorResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateBusinessDocumentHook>>>,
    TError,
    { id: string; data: BodyType<UpdateBusinessDocumentRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}) => {
  const mutationOptions = useUpdateBusinessDocumentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Archive a business document (new API)
 */
export const useArchiveBusinessDocumentHook = () => {
  const archiveBusinessDocument = useCustomClient<void>();

  return (
    id: string,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>
  ) => {
    return archiveBusinessDocument(
      {
        url: `/api/js/business_document/v1/business_documents/${id}/archive`,
        method: 'POST',
      },
      options
    );
  };
};

export const useArchiveBusinessDocumentMutationOptions = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useArchiveBusinessDocumentHook>>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useArchiveBusinessDocumentHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const archiveBusinessDocument = useArchiveBusinessDocumentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useArchiveBusinessDocumentHook>>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return archiveBusinessDocument(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ArchiveBusinessDocumentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useArchiveBusinessDocumentHook>>>
>;

export type ArchiveBusinessDocumentMutationError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Archive a business document (new API)
 */
export const useArchiveBusinessDocument = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useArchiveBusinessDocumentHook>>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}) => {
  const mutationOptions = useArchiveBusinessDocumentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Restore an archived business document (new API)
 */
export const useRestoreBusinessDocumentHook = () => {
  const restoreBusinessDocument = useCustomClient<void>();

  return (
    id: string,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>
  ) => {
    return restoreBusinessDocument(
      {
        url: `/api/js/business_document/v1/business_documents/${id}/restoration`,
        method: 'POST',
      },
      options
    );
  };
};

export const useRestoreBusinessDocumentMutationOptions = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRestoreBusinessDocumentHook>>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useRestoreBusinessDocumentHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const restoreBusinessDocument = useRestoreBusinessDocumentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useRestoreBusinessDocumentHook>>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return restoreBusinessDocument(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RestoreBusinessDocumentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useRestoreBusinessDocumentHook>>>
>;

export type RestoreBusinessDocumentMutationError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Restore an archived business document (new API)
 */
export const useRestoreBusinessDocument = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRestoreBusinessDocumentHook>>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}) => {
  const mutationOptions = useRestoreBusinessDocumentMutationOptions(options);

  return useMutation(mutationOptions);
};
