import { useCallback, useState } from 'react';

type ButtonHandlers = {
  onArchiveClick: () => void;
  onArchiveRestoreClick: () => Promise<void>;
  onCreateInvoiceReportsClick?: () => Promise<void>;
  onCopyURLClick?: () => void;
};

export type TypeOfFunction = 'archive' | 'restore' | 'invoiceReport' | 'copy';

export const useWrapperClick = ({
  onArchiveClick,
  onArchiveRestoreClick,
  onCreateInvoiceReportsClick,
  onCopyURLClick,
}: ButtonHandlers) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const onClick = useCallback(
    (type: TypeOfFunction) => {
      return async () => {
        setIsDisabled(true);
        try {
          switch (type) {
            case 'archive':
              onArchiveClick();
              return;
            case 'restore':
              await onArchiveRestoreClick();
              return;
            case 'invoiceReport':
              await onCreateInvoiceReportsClick?.();
              return;
            case 'copy':
              onCopyURLClick?.();
              return;
          }
        } finally {
          setIsDisabled(false);
        }
      };
    },
    [
      onArchiveClick,
      onArchiveRestoreClick,
      onCreateInvoiceReportsClick,
      onCopyURLClick,
    ]
  );
  return {
    onClick,
    isDisabled,
  };
};
