import { BusinessDocumentReceiptType } from '@/context/services/business_document/type';
import {
  ReceiptTypeLabel,
  TypeOfReceiptType,
} from '@/features/business_documents/List/type';

export type ReceiptTypeValues = {
  [K in ReceiptTypeLabel as TypeOfReceiptType[K]]: K;
};

const ReceiptTypesValue: ReceiptTypeValues = Object.entries(
  BusinessDocumentReceiptType
).reduce(
  (prev, [key, value]) => ({ ...prev, [value]: key }),
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as ReceiptTypeValues
);

export const convertReceiptTypeKeyToValue = (
  value: BusinessDocumentReceiptType
) => {
  const v = ReceiptTypesValue[value];
  if (!v) {
    throw new Error('Type conversion failed.');
  }
  return v;
};

export const typeGuardReceiptTypeValue = (
  value: BusinessDocumentReceiptType | undefined
): value is BusinessDocumentReceiptType => {
  if (value === undefined) throw new Error('Type guard failed.');
  return value in ReceiptTypesValue;
};
