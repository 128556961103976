import { useCallback } from 'react';
import {
  useReceivedInvoiceSearchRefetch,
  useReceivedInvoicesSearchRefetch,
} from '../invoice/refetchContext';

export const useRefetchQueries = () => {
  const receivedInvoicesSearchRefetch = useReceivedInvoicesSearchRefetch();
  const receivedInvoiceSearchRefetch = useReceivedInvoiceSearchRefetch();

  return useCallback(
    (refetchQueries: FetchServices[] = []) => {
      const queries =
        refetchQueries
          ?.map((query) => {
            switch (query) {
              case 'ReceivedInvoices':
                return receivedInvoicesSearchRefetch?.();
              case 'ReceivedInvoice':
                return receivedInvoiceSearchRefetch?.();
              default:
                if (typeof query === 'function') {
                  return query();
                } else {
                  return Promise.resolve();
                }
            }
          })
          .filter(Boolean) ?? [];
      return new Promise<void>((resolve) => {
        Promise.all(queries).finally(() => resolve());
      });
    },
    [receivedInvoiceSearchRefetch, receivedInvoicesSearchRefetch]
  );
};
