import { ArchiveModalAPI } from '@/components/ConfirmationModal/Archive';
import { TErrorNotification } from '@/components/ErrorInlineNotification/ErrorInlineNotification';
import { useDrawerClose } from '@/context/Drawer';
import {
  useBusinessDocumentArchive,
  useBusinessDocumentRestore,
} from '@/context/services/business_document/BusinessDocument.service';
import { BusinessDocumentDetail } from '@/context/services/business_document/type';
import { BusinessDocumentData } from '@/features/business_documents/Detail/components/Right/type';
import {
  convertDocumentTypeKeyToValue,
  convertReceiptTypeKeyToValue,
  typeGuardDocumentTypeValue,
  typeGuardReceiptTypeValue,
  useDocumentTypeLabel,
  useReceiptTypeLabel,
} from '@/features/business_documents/hooks';
import {
  typeGuardAutomaticStatusValue,
  typeGuardTimestampStatusValue,
} from '@/features/business_documents/hooks/useProcessingStatusTag';
import { useUploadedAtLabel } from '@/features/invoices/components/Details/Components/ReceivedInvoice/hooks/useReceivedInvoice';
import { OfficeMembers } from '@/features/invoices/components/Details/Components/ReceivedInvoice/type';
import { useTranslation } from '@/i18n';
import { isCommonError } from '@/libs/typeguard/isError';
import { SendErrorTracking } from '@/utils/errors';
import { API } from '@moneyforward/ap-frontend-components';
import { DATE_FORMAT } from 'date-util';
import { useCallback, useMemo } from 'react';

export const useConvertBusinessDocumentPayloadToFormData = (
  data: BusinessDocumentDetail
): BusinessDocumentData => {
  const pics: OfficeMembers = useMemo(() => {
    const _pics =
      data.pics?.map((item) => ({
        id: item.id!,
        name: item.display_name ?? item.name ?? '',
      })) || [];
    return {
      values: _pics,
      toFormValues: () => _pics.map((item) => item.id),
      toLabels: () => _pics.map((item) => item.name).join(','),
      toOptions: () =>
        _pics.map((item) => ({ label: item.name, value: item.id })),
    };
  }, [data.pics]);

  const result = useMemo(
    () => ({
      amount: data.amount,
      archived: !!data.archived,
      automaticStatus: typeGuardAutomaticStatusValue(data.automatic_status)
        ? data.automatic_status
        : 'ocr_skipped',
      timestampStatus: typeGuardTimestampStatusValue(data.timestamp_status)
        ? data.timestamp_status
        : 'unsupported',
      counterpartyName: data.counterparty_name ?? '',
      documentTitle: data.document_title ?? '',
      documentType: typeGuardDocumentTypeValue(data.document_type)
        ? data.document_type
        : 'not_specified',
      filename: data.filename ?? '',
      id: String(data.id),
      memo: data.memo ?? '',
      pics,
      transactionDate: data.transaction_date || '',
      receiptType: typeGuardReceiptTypeValue(data.receipt_type)
        ? data.receipt_type
        : 'paper',
    }),
    [data, pics]
  );
  return result;
};

export const useBusinessDocumentDetailLabel = (data: BusinessDocumentData) => {
  const { t } = useTranslation();
  const DocumentTypeLabel = useDocumentTypeLabel();
  const ReceiptTypeLabel = useReceiptTypeLabel();
  const transactionLabel = useUploadedAtLabel(
    data.transactionDate,
    DATE_FORMAT
  );

  const amountLabel =
    data.amount !== null && !Number.isNaN(Number(data.amount))
      ? Number(data.amount).toLocaleString() + '円'
      : '';

  const documentTypeLabel = data.documentType
    ? DocumentTypeLabel[convertDocumentTypeKeyToValue(data.documentType)]
    : '';

  const receiptTypeLabel = data.receiptType
    ? ReceiptTypeLabel[convertReceiptTypeKeyToValue(data.receiptType)]
    : '';

  return useMemo(
    () => ({
      timeStampLabel: data.timestampStatus,
      documentTitleLabel: data.documentTitle,
      documentTypeLabel: t(documentTypeLabel),
      counterpartyNameLabel: data.counterpartyName,
      picsLabel: data.pics?.toLabels() ?? '',
      transactionDateLabel: transactionLabel,
      amountLabel: amountLabel,
      memoLabel: data.memo,
      receiptTypeLabel,
    }),
    [
      data.timestampStatus,
      data.documentTitle,
      data.counterpartyName,
      data.memo,
      data.pics,
      transactionLabel,
      amountLabel,
      documentTypeLabel,
      receiptTypeLabel,
      t,
    ]
  );
};

export const useBusinessDocumentEditable = (data: BusinessDocumentDetail) => {
  return useMemo(() => {
    return (
      !data.archived &&
      (data.automatic_status === 'ocr_skipped' ||
        data.automatic_status === 'ocr_done')
    );
  }, [data.archived, data.automatic_status]);
};

type APIResult = 'success' | 'error';

export const useBusinessDocumentArchiveCallback = (
  modal: ArchiveModalAPI,
  notify: API
) => {
  const { t } = useTranslation();
  const drawerClose = useDrawerClose();
  return useCallback(
    async (result: APIResult) => {
      modal.close();
      if (result === 'success') {
        drawerClose();
        notify.success({
          title: t('business_document_archive_successful'),
          opts: {
            duration: 3000,
            style: {
              position: 'fixed',
            },
          },
          bodyProps: {
            disabledSuffix: true,
          },
        });
      }
    },
    [drawerClose, modal, notify, t]
  );
};

export const useCallBusinessDocumentArchive = (
  id: string,
  callback: (result: APIResult) => void,
  setError: TErrorNotification['setError']
) => {
  const { mutateAsync } = useBusinessDocumentArchive();
  return useCallback(async () => {
    let result: APIResult = 'success';
    try {
      await mutateAsync({ id: id });
    } catch (err) {
      result = 'error';
      if (err instanceof Error && err?.name === 'AxiosError') {
        setError(new SendErrorTracking(err));
      } else if (err && isCommonError(err)) {
        setError(new Error(err.messages?.join('\n')));
      } else {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        setError(new SendErrorTracking(err as Error));
      }
    } finally {
      callback(result);
    }
  }, [callback, id, mutateAsync, setError]);
};

export const useBusinessDocumentRestoreCallback = (notify: API) => {
  const { t } = useTranslation();
  return useCallback(
    (result: APIResult) => {
      if (result === 'success') {
        notify.success({
          title: t('business_document_restore_successful'),
          opts: {
            duration: 3000,
            style: {
              position: 'fixed',
            },
          },
          bodyProps: {
            disabledSuffix: true,
          },
        });
      }
    },
    [notify, t]
  );
};

export const useCallRestoreBusinessDocument = (
  id: string,
  callback: (result: APIResult) => void,
  setError: TErrorNotification['setError']
) => {
  const { mutateAsync } = useBusinessDocumentRestore();
  return useCallback(async () => {
    let result: APIResult = 'success';
    try {
      await mutateAsync({ id });
    } catch (err) {
      result = 'error';
      if (err instanceof Error && err?.name === 'AxiosError') {
        setError(err);
      } else if (err && isCommonError(err)) {
        setError(new Error(err.messages?.join('\n')));
      } else {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        setError(new SendErrorTracking(err as Error));
      }
    } finally {
      callback(result);
    }
  }, [callback, id, mutateAsync, setError]);
};
