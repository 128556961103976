import { DATE_FORMAT, format } from 'date-util';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback } from 'react';

/**
 * Custom hook to provide utility functions for URLSearchParams.
 * @returns An object containing the utility functions.
 */
export const useQueryParams = () => {
  const getArrayParam = useCallback(
    <T = string>(
      searchParams: URLSearchParams,
      key: string
    ): T[] | undefined => {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const values = searchParams.getAll(key).filter(Boolean) as unknown as T[];
      return values.length ? values : undefined;
    },
    []
  );

  const getDateParam = useCallback(
    (searchParams: URLSearchParams, key: string): string | Dayjs => {
      const value = searchParams.get(key);

      return value ? dayjs(format(value, DATE_FORMAT)) : '';
    },
    []
  );

  return { getArrayParam, getDateParam };
};
