import { useCallback, useMemo } from 'react';

type Model = {
  display_name?: string;
  name?: string;
  id?: string;
  code?: string;
  number?: string;
};
export type BaseOption = {
  label: string;
  value: string | number;
  caption?: string;
};
type options<T> = {
  labelKeys?: Array<keyof T>;
  value?: keyof T;
  caption?: keyof T | Array<keyof T>;
};

export const useModelsToOptions = <T extends Model>(
  query: string | undefined,
  defaultOptions: BaseOption[],
  option?: options<T>
) => {
  // NOTE: I don't think options change dynamically, so I'll make a note of it.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { labelKeys, value, caption } = useMemo(() => option ?? {}, []);
  const memoLabelKeys: (keyof T)[] = useMemo(
    () => labelKeys ?? ['display_name', 'name'],
    [labelKeys]
  );
  const memoValue: keyof T = useMemo(() => value ?? 'id', [value]);
  const memoCaptions: (keyof T)[] | undefined = useMemo(
    () =>
      caption === undefined
        ? undefined
        : Array.isArray(caption)
        ? caption
        : [caption],
    [caption]
  );
  return useCallback(
    (items: T[]) => {
      const arr = items.map(
        (item): BaseOption => ({
          label:
            memoLabelKeys.map((v) => String(item[v])).filter(Boolean)?.[0] ??
            '',
          value: String(item[memoValue]),
          caption: memoCaptions
            ? memoCaptions
                .map((v) => String(item[v] ?? ''))
                .filter(Boolean)?.[0] ?? ''
            : undefined,
        })
      );
      return Array.from(
        new Map(
          [...(query ? [] : defaultOptions), ...arr].map((item) => [
            item.value,
            item,
          ])
        ).values()
      );
    },
    [query, defaultOptions, memoLabelKeys, memoValue, memoCaptions]
  );
};
