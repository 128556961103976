import { PropsWithChildren } from 'react';

export type ServiceProviderProps<T> = Required<PropsWithChildren<{ value: T }>>;

export const isSuccess = (status: number) => status >= 200 && status < 300;

export const isAllSuccess = (statuses: string[]) =>
  statuses.every((status) => status === 'success');

// eslint-disable-next-line
export type SecondParameter<T extends (...args: any) => any> = T extends (
  // eslint-disable-next-line
  config: any,
  args: infer P
  // eslint-disable-next-line
) => any
  ? P
  : never;
