import { useQueryParameter } from '@/context/QueryParameter';
import { useCallback } from 'react';

type TBusinessDocumentListProps = () => {
  selectedId: string;
  onDrawerOpen: (id: string) => void;
  onDrawerClose: () => void;
};

export const useBusinessDocumentList: TBusinessDocumentListProps = () => {
  const [param, setParam] = useQueryParameter();
  const selectedId = param.get('id') || '';

  const onDrawerOpen = useCallback(
    (id: string) => {
      if (id) {
        param.set('id', id);
        setParam(param);
      }
    },
    [param, setParam]
  );

  const onDrawerClose = useCallback(() => {
    param.delete('id');
    setParam(param);
  }, [param, setParam]);

  return {
    selectedId,
    onDrawerOpen,
    onDrawerClose,
  };
};
