import {
  TReceivedInvoiceCondition,
  TSetupConditionProps,
} from '@/context/services/invoice/type';
import { useReceivedInvoiceUploadTypeOptions } from '@/features/invoices/hooks/useReceivedInvoiceUploadTypeOptions';
import { useTranslation } from '@/i18n';
import { FormSelect, SelectProps } from '@moneyforward/ap-frontend-components';
import { FC, memo, useMemo } from 'react';

export const UploadType: FC<TSetupConditionProps> = memo(
  ({ control, name }) => {
    const { t } = useTranslation();
    const options = useReceivedInvoiceUploadTypeOptions();
    const selectProps: SelectProps = useMemo(
      () => ({
        options: options,
        mode: 'multiple',
        selectAll: true,
        maxTagCount: 1,
      }),
      [options]
    );
    const labelProps = useMemo(
      () => ({
        text: t('label_upload_type'),
      }),
      [t]
    );

    return (
      <FormSelect<TReceivedInvoiceCondition>
        control={control}
        name={name}
        selectProps={selectProps}
        label={labelProps}
        inputId='invoice-search-upload-type'
        testId='invoice-search-upload-type'
      />
    );
  }
);
UploadType.displayName = 'UploadType';
