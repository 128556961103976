import {
  TBusinessDocumentCondition,
  TSetupConditionProps,
} from '@/features/business_documents/UploadForm/Content/type';
import { useDocumentTypeOptions } from '@/features/business_documents/hooks';
import { useTranslation } from '@/i18n';
import {
  FormSelect,
  type SelectProps,
} from '@moneyforward/ap-frontend-components';
import { FC, memo, useMemo } from 'react';

export const DocumentTypeIn: FC<TSetupConditionProps> = memo(
  ({ control, name }) => {
    const { t } = useTranslation();
    const options = useDocumentTypeOptions();
    const selectProps: SelectProps = useMemo(
      () => ({
        options: options,
        maxTagCount: 1,
        mode: 'multiple',
        selectAll: true,
      }),
      [options]
    );
    const labelProps = useMemo(
      () => ({
        text: t('business_document_document_type'),
      }),
      [t]
    );

    return (
      <FormSelect<TBusinessDocumentCondition>
        control={control}
        name={name}
        selectProps={selectProps}
        label={labelProps}
        inputId='document_type_in'
        testId='document-type-in'
      />
    );
  }
);
DocumentTypeIn.displayName = 'DocumentTypeIn';
