/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * APIs for RCI FE
 * This document describes the APIs provided by NeoAP BE for RCI FE, designed to be integrated into NeoAP FE using `iframe`.\
These APIs utilize cookie-based authentication. All API requests must include the cookie `_ap_session`.

 * OpenAPI spec version: 1
 */
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import type { ForbiddenResponse } from '../model/forbiddenResponse';
import type { GetTpmInvoices400 } from '../model/getTpmInvoices400';
import type { GetTpmInvoicesParams } from '../model/getTpmInvoicesParams';
import type { GetTpmInvoicesResponse } from '../model/getTpmInvoicesResponse';
import type { NotFoundResponse } from '../model/notFoundResponse';
import type { UnauthorizedResponse } from '../model/unauthorizedResponse';
import type { UnprocessableEntityResponse } from '../model/unprocessableEntityResponse';
import { useCustomClient } from '../../mutator/custom-instance';
import type { ErrorType } from '../../mutator/custom-instance';
import { customFomDataFn } from '../../api/formData/custom-function';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * @summary Get invoice registration info (existing API)
 */
export const useGetTpmInvoicesHook = () => {
  const getTpmInvoices = useCustomClient<GetTpmInvoicesResponse>();

  return (
    params: GetTpmInvoicesParams,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>,
    signal?: AbortSignal
  ) => {
    return getTpmInvoices(
      { url: `/api/js/tpm_invoices`, method: 'GET', params, signal },
      options
    );
  };
};

export const getGetTpmInvoicesQueryKey = (params: GetTpmInvoicesParams) => {
  return [`/api/js/tpm_invoices`, ...(params ? [params] : [])] as const;
};

export const useGetTpmInvoicesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetTpmInvoicesHook>>>,
  TError = ErrorType<
    | GetTpmInvoices400
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params: GetTpmInvoicesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetTpmInvoicesHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTpmInvoicesQueryKey(params);

  const getTpmInvoices = useGetTpmInvoicesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetTpmInvoicesHook>>>
  > = ({ signal }) => getTpmInvoices(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetTpmInvoicesHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTpmInvoicesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetTpmInvoicesHook>>>
>;
export type GetTpmInvoicesQueryError = ErrorType<
  | GetTpmInvoices400
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get invoice registration info (existing API)
 */
export const useGetTpmInvoices = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetTpmInvoicesHook>>>,
  TError = ErrorType<
    | GetTpmInvoices400
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params: GetTpmInvoicesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetTpmInvoicesHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetTpmInvoicesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetTpmInvoicesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetTpmInvoicesHook>>>,
  TError = ErrorType<
    | GetTpmInvoices400
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params: GetTpmInvoicesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetTpmInvoicesHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTpmInvoicesQueryKey(params);

  const getTpmInvoices = useGetTpmInvoicesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetTpmInvoicesHook>>>
  > = ({ signal }) => getTpmInvoices(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetTpmInvoicesHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTpmInvoicesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetTpmInvoicesHook>>>
>;
export type GetTpmInvoicesSuspenseQueryError = ErrorType<
  | GetTpmInvoices400
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get invoice registration info (existing API)
 */
export const useGetTpmInvoicesSuspense = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetTpmInvoicesHook>>>,
  TError = ErrorType<
    | GetTpmInvoices400
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params: GetTpmInvoicesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetTpmInvoicesHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetTpmInvoicesSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
