import { BusinessDocumentEdit } from '@/features/business_documents/Detail/components/Right/BusinessDocumentEdit';
import { BusinessDocumentView } from '@/features/business_documents/Detail/components/Right/BusinessDocumentView';
import { WrapContentProps } from '@/features/business_documents/Detail/components/Right/Right';
import { useConvertBusinessDocumentPayloadToFormData } from '@/features/business_documents/Detail/components/Right/hooks/useBusinessDocumentDetail';
import { useIsView } from '@/features/invoices/components/Details/Components/Context/ViewEdit';
import { FC, memo } from 'react';

type TBusinessDocumentDetailProps = {
  isEditable: boolean;
} & WrapContentProps;

export const BusinessDocumentBodyContent: FC<TBusinessDocumentDetailProps> =
  memo(({ isEditable, data }) => {
    const convertData = useConvertBusinessDocumentPayloadToFormData(data);
    const { isView } = useIsView();

    return (
      <>
        {isView && (
          <BusinessDocumentView data={convertData} isEditable={isEditable} />
        )}
        {!isView && <BusinessDocumentEdit data={convertData} />}
      </>
    );
  });

BusinessDocumentBodyContent.displayName = 'BusinessDocumentBodyContent';
