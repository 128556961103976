import { useGlobalRefs } from '@/wc/helper/ref';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

export const useCopy = (text: string, ref?: RefObject<HTMLElement>) => {
  const { globalContentRef } = useGlobalRefs();
  const [copied, setCopied] = useState(false);
  const reset = useRef(() => setCopied(false));
  const copy = useCallback(() => {
    const targetRef = ref?.current || globalContentRef.current;
    copyToClipboard(text, targetRef!)
      .then(() => setCopied(true))
      .catch(() => setCopied((copied) => copied));
  }, [globalContentRef, text, ref]);
  useEffect(() => reset.current, [text]);
  return {
    copied,
    copy,
    reset: reset.current,
  } as const;
};

const copyToClipboard = (text: string, ref: HTMLElement) => {
  if (navigator.clipboard) return navigator.clipboard.writeText(text);
  const span = document.createElement('span');
  span.textContent = text;
  span.style.whiteSpace = 'pre';
  ref.appendChild(span);
  // makes a selection object representing the range of text selected by the user
  const selection = window.getSelection();
  if (!selection) return Promise.reject();
  const range = window.document.createRange();
  selection.removeAllRanges();
  range.selectNode(span);
  selection.addRange(range);
  // copies text to the clipboard
  try {
    window.document.execCommand('copy');
  } catch (err) {
    return Promise.reject();
  }
  // cleans up the dom element and selection
  selection.removeAllRanges();
  ref.removeChild(span);
  // the Async Clipboard API returns a promise that may reject with `undefined`
  // so we match that here for consistency
  return Promise.resolve();
};
