import { useLocale } from 'i18n';
import type { FlatNamespace, i18n } from 'i18next';
import { useTranslation as useOriginalTranslation } from 'react-i18next';
import { i18n as i18nClient } from './config';

export const useTranslation = <Ns extends FlatNamespace>(
  ns?: Ns,
  options: { i18n: i18n } = { i18n: i18nClient }
) => {
  const locale = useLocale();
  return useOriginalTranslation(ns ?? 'apps', { ...options, lng: locale });
};
