import {
  ArchiveConfirmationModalProvider,
  useArchiveConfirmationModal,
} from '@/components/ConfirmationModal';
import { useErrorInlineNotification } from '@/components/ErrorInlineNotification';
import { BusinessDocumentDetail } from '@/context/services/business_document/type';
import { ProcessingStatusProvider } from '@/features/business_documents/Detail/Context/ProcessingStatus';
import { PaymentRequestApp } from '@/features/business_documents/Detail/components/PaymentRequestApp';
import { BusinessDocumentBodyContent } from '@/features/business_documents/Detail/components/Right/BusinessDocumentBodyContent';
import { HeaderButtons } from '@/features/business_documents/Detail/components/Right/HeaderButton';
import {
  useBusinessDocumentArchiveCallback,
  useBusinessDocumentEditable,
  useBusinessDocumentRestoreCallback,
  useCallBusinessDocumentArchive,
  useCallRestoreBusinessDocument,
} from '@/features/business_documents/Detail/components/Right/hooks/useBusinessDocumentDetail';
import { ViewContextProvider } from '@/features/invoices/components/Details/Components/Context/ViewEdit';
import { Header } from '@/features/invoices/components/Details/Components/Header';
import { useGetFlags } from '@/hooks/useFeatureFlag';
import { useMakeCopyIdURL } from '@/hooks/useMakeCopyURL';
import { useTranslation } from '@/i18n';
import { isString } from '@/libs/typeguard/string';
import { useNotification } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo, useCallback } from 'react';
import { Notification } from './InlineNotification';
import styles from './Right.module.scss';

const cx = classnames.bind(styles);

export type WrapContentProps = {
  data: BusinessDocumentDetail;
};

export const WrapperRight: FC<WrapContentProps> = memo(({ data }) => {
  const { t } = useTranslation();
  const [{ setError }, ErrorContextHolder, ErrorInlineNotificationProvider] =
    useErrorInlineNotification();
  const editable = useBusinessDocumentEditable(data);
  const modal = useArchiveConfirmationModal();
  const notify = useNotification();
  const flag = useGetFlags('RELATION_FEATURE');

  const onCancelClick = useCallback(() => {
    modal.close();
  }, [modal]);

  const handleArchiveCallback = useBusinessDocumentArchiveCallback(
    modal,
    notify
  );

  const onHandleArchive = useCallBusinessDocumentArchive(
    data.id!,
    handleArchiveCallback,
    setError
  );

  const onArchiveClick = useCallback(() => {
    modal.open({
      title: t('business_document_archive_confirmation_title'),
      bodyTitle: t('archive_modal_content_business_document'),
      fileName: data.filename ?? '',
      pics: data.pics?.map((item) => item.name).filter(isString) || [],
      onCancelClick: onCancelClick,
      onArchiveClick: onHandleArchive,
      size: 'md',
    });
  }, [modal, data.filename, data.pics, onCancelClick, onHandleArchive, t]);

  const handleRestoreArchiveCallback =
    useBusinessDocumentRestoreCallback(notify);
  const onRestoreArchiveClick = useCallRestoreBusinessDocument(
    data.id!,
    handleRestoreArchiveCallback,
    setError
  );

  const copyIdUrl = useMakeCopyIdURL(`${data.id}`);

  const processingStatus = {
    OCRStatus: data.automatic_status,
    timestampStatus: data.timestamp_status,
    isArchived: !!data.archived,
  };

  return (
    <ViewContextProvider>
      <ProcessingStatusProvider value={processingStatus}>
        <div className={cx(styles['container'])}>
          <Header className={cx(styles['headerContainer'])}>
            <HeaderButtons
              onArchiveClick={onArchiveClick}
              copyText={copyIdUrl}
              onArchiveRestoreClick={onRestoreArchiveClick}
              hasInvoiceReports={!!data.invoice_reports?.length && flag}
            />
          </Header>
          <ErrorInlineNotificationProvider value={setError}>
            <div className={cx(styles['contentContainer'])}>
              <div className={cx(styles['content'])}>
                {ErrorContextHolder}
                <Notification isArchived={!!data.archived} />
                <BusinessDocumentBodyContent
                  isEditable={editable}
                  data={data}
                />
                {flag && <PaymentRequestApp data={data} />}
              </div>
            </div>
          </ErrorInlineNotificationProvider>
        </div>
      </ProcessingStatusProvider>
    </ViewContextProvider>
  );
});
WrapperRight.displayName = 'WrapperRight';

export const BusinessDocumentDetailRightView: FC<WrapContentProps> = memo(
  ({ data }) => {
    return (
      <ArchiveConfirmationModalProvider>
        <WrapperRight data={data} />
      </ArchiveConfirmationModalProvider>
    );
  }
);
BusinessDocumentDetailRightView.displayName = 'BusinessDocumentDetailRightView';
