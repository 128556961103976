import {
  FC,
  PropsWithChildren,
  createContext,
  memo,
  useContext,
  useMemo,
} from 'react';
import { TEditableProps } from './type';

type Editable = {
  [name in TEditableProps]: boolean;
};
const Context = createContext<Editable | undefined>(undefined);
export const useEditable = (name: TEditableProps) => {
  const value = useContext(Context);
  return value?.[name];
};

type Props = {
  name: TEditableProps;
  isEditable: boolean;
};

export const EditableProvider: FC<PropsWithChildren<Props>> = memo(
  ({ name, isEditable, children }) => {
    const parentContext = useContext(Context);
    const value = useMemo(
      () => ({ ...{ [name]: isEditable }, ...parentContext }),
      [isEditable, name, parentContext]
    );
    return <Context.Provider value={value}>{children}</Context.Provider>;
  }
);
EditableProvider.displayName = 'EditableProvider';
