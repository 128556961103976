import {
  BusinessDocumentAutomaticStatus,
  BusinessDocumentTimestampStatus,
} from '@/context/services/business_document/type';
import { FC, PropsWithChildren, createContext, useContext } from 'react';

type ProcessingStatusType = {
  OCRStatus: BusinessDocumentAutomaticStatus | undefined;
  timestampStatus: BusinessDocumentTimestampStatus | undefined;
  isArchived: boolean;
};

const Context = createContext<ProcessingStatusType>({
  OCRStatus: undefined,
  timestampStatus: undefined,
  isArchived: false,
});

type Props = {
  value: ProcessingStatusType;
};

export const ProcessingStatusProvider: FC<PropsWithChildren<Props>> = ({
  value,
  children,
}) => {
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useProcessingStatusChecker = () => {
  const { OCRStatus, timestampStatus, isArchived } = useContext(Context);
  const checker = {
    waiting_for_ocr_input: OCRStatus === 'waiting_for_ocr_input',
    ocr_done: OCRStatus === 'ocr_done',
    ocr_skipped: OCRStatus === 'ocr_skipped',

    timeStamp_creating: timestampStatus === 'creating',
    timeStamp_done: timestampStatus === 'done',
    timeStamp_need_retry: timestampStatus === 'need_retry',
    timeStamp_failed: timestampStatus === 'failed',
    timeStamp_unsupported: timestampStatus === 'unsupported',

    isArchived,
  };
  return checker;
};
