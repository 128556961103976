export class IgnoreErrorTracking extends Error {}

export class InvalidError extends Error {}
export class InvalidErrorWithIgnoreErrorTracking extends IgnoreErrorTracking {}

export class SendErrorTracking extends Error {
  constructor(err: string | Error | undefined) {
    const isErr = err instanceof Error;
    super(isErr ? err.message : err, isErr ? { cause: err.cause } : undefined);
    if (isErr) {
      this.stack = err.stack;
    }
  }
}
