import { useQueryParameter } from '@/context/QueryParameter';
import { useCallback } from 'react';

/**
 * Custom hook to convert an object with array values into URLSearchParams.
 * @param params The object to convert.
 * @returns The URLSearchParams instance.
 */
export const convertToURLSearchParams = <T extends Record<string, unknown>>(
  params: T
): URLSearchParams => {
  const searchParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((val) => searchParams.append(key, val));
    } else if (value !== undefined && value !== null) {
      searchParams.append(key, String(value));
    }
  });
  return searchParams;
};

/**
 * Custom hook to get a function that converts an object to URLSearchParams.
 * @returns A function that converts the given object to URLSearchParams.
 */
export const useSerializeToURLSearchParams = <
  T extends Record<string, unknown>
>() => {
  return useCallback((params: T) => convertToURLSearchParams(params), []);
};

/**
 * Custom hook to update the URL search query with the given object.
 * @returns A function that updates the URL search query.
 */

export const useUpdateURLSearchParams = <
  T extends Record<string, unknown>
>() => {
  const convertToURLSearchParams = useSerializeToURLSearchParams<T>();
  const [, setParam] = useQueryParameter();

  return useCallback(
    (searchQuery: T) => {
      const searchQueryParams = convertToURLSearchParams(searchQuery);
      setParam(searchQueryParams);
    },
    [convertToURLSearchParams, setParam]
  );
};
