import { MAX_VALUE_AMOUNT, MIN_VALUE_AMOUNT } from '@/components/constants';
import {
  TBusinessDocumentCondition,
  TSetupConditionProps,
} from '@/features/business_documents/UploadForm/Content/type';
import { useTranslation } from '@/i18n';
import {
  FormInputNumber,
  InputNumberProps,
} from '@moneyforward/ap-frontend-components';
import { FC, memo, useMemo } from 'react';
export const Amount: FC<TSetupConditionProps> = memo(({ control, name }) => {
  const { t } = useTranslation();
  const inputProps: InputNumberProps = useMemo(
    () => ({
      placeholder: t('business_document_amount_placeholder'),
      search: true,
      className: 'amountInput',
      suffix: '円',
      format: 'price_with_empty',
      max: MAX_VALUE_AMOUNT,
      min: MIN_VALUE_AMOUNT,
    }),
    [t]
  );
  const labelProps = useMemo(
    () => ({
      text: t('business_document_amount'),
    }),
    [t]
  );
  return (
    <FormInputNumber<TBusinessDocumentCondition>
      control={control}
      name={name}
      inputId='amount'
      inputProps={inputProps}
      label={labelProps}
      testId='amount'
    />
  );
});
Amount.displayName = 'Amount';
