import { ReceivedInvoiceDetail } from '@/context/services/invoice/type';
import { useIsView } from '@/features/invoices/components/Details/Components/Context/ViewEdit';
import { View } from '@/features/invoices/components/Details/Components/ReceivedInvoice/View';
import { useReceivedInvoiceDetailToReceivedInvoiceData } from '@/features/invoices/components/Details/Components/ReceivedInvoice/hooks/useReceivedInvoice';
import classnames from 'classnames/bind';
import { FC, memo, useMemo } from 'react';
import { Edit } from './Edit';
import styles from './ReceivedInvoice.module.scss';

const cx = classnames.bind(styles);

type Props = {
  data: ReceivedInvoiceDetail;
};

export const ReceivedInvoice: FC<Props> = memo(({ data }) => {
  const { isView } = useIsView();
  const convertData = useReceivedInvoiceDetailToReceivedInvoiceData(data);
  const content = useMemo(
    () => (isView ? <View data={convertData} /> : <Edit data={convertData} />),
    [convertData, isView]
  );
  return <div className={cx(styles['container'])}>{content}</div>;
});

ReceivedInvoice.displayName = 'ReceivedInvoice';
