import { ModernPaginationProps } from '@moneyforward/ap-frontend-components';
import { PaginationResponse } from 'ap-openapi';
import { useMemo } from 'react';

export const useConvertToModernPagination = (
  pagination: PaginationResponse | undefined,
  onChange?: (value: number) => void
): ModernPaginationProps => {
  return useMemo(
    () => ({
      currentPage: pagination?.page ?? 1,
      currentPerPage: pagination?.per_page ?? 0,
      maxPage: pagination?.total_pages ?? 0,
      mode: 'label',
      onChange: onChange!,
    }),
    [pagination, onChange]
  );
};
