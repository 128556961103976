import { OfficeMember } from '@/components/OfficeMember';
import {
  FormSelectProps,
  LabelProps,
} from '@moneyforward/ap-frontend-components';
import { memo, useMemo } from 'react';
import { Control, FieldPath, FieldValues } from 'react-hook-form';

type Props<TFieldValues extends FieldValues> = {
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
  labelProps?: LabelProps;
  selectProps?: Omit<FormSelectProps<TFieldValues>['selectProps'], 'options'>;
};

export const _PicIdIn = <TFieldValues extends FieldValues>({
  control,
  name,
  labelProps,
  selectProps: _selectProps,
}: Props<TFieldValues>) => {
  const selectProps = useMemo(
    () => ({
      placeholder: '',
      search: true,
      maxTagCount: 1,
      className: 'picSelect',
      inlineOptions: true,
      ..._selectProps,
    }),
    [_selectProps]
  );

  return (
    <OfficeMember<TFieldValues>
      control={control}
      name={name}
      inputId='pic-select'
      testId='pic-select'
      selectProps={selectProps}
      label={labelProps}
      // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
      defaultOptions={[]}
    />
  );
};

_PicIdIn.displayName = '_PicIdIn';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const PicIdIn = memo((props) => (
  <_PicIdIn {...props} />
)) as typeof _PicIdIn;

PicIdIn.displayName = 'PicIdIn';
