import { AxiosResponse } from 'axios';
import { AxiosCustomOptions, ErrorType } from '../../mutator/custom-instance';
import {
  BadRequestResponse,
  ForbiddenResponse,
  GetTpmInvoicesResponse,
  NotFoundResponse,
  UnauthorizedResponse,
  UnprocessableEntityResponse,
} from '../../openapi/model';
import type { GetTpmInvoicesParams } from '../../openapi/model/getTpmInvoicesParams';
import {
  getGetTpmInvoicesQueryKey,
  useGetTpmInvoicesHook,
} from '../../openapi/tpm-invoice/tpm-invoice';
import { useLazyQuery } from '../query/lazy-query';

export type FetchTpmInvoice = (
  param: GetTpmInvoicesParams,
  request?: AxiosCustomOptions | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => Promise<AxiosResponse<GetTpmInvoicesResponse, any>>;

export const useLazyTpmInvoice = (): {
  fetch: FetchTpmInvoice;
  isLoading: boolean;
} => {
  const getTpmInvoices = useGetTpmInvoicesHook();
  const { fetch, isLoading } = useLazyQuery<
    typeof useGetTpmInvoicesHook,
    ErrorType<
      | BadRequestResponse
      | UnauthorizedResponse
      | ForbiddenResponse
      | NotFoundResponse
      | UnprocessableEntityResponse
    >
  >(getTpmInvoices, getGetTpmInvoicesQueryKey);
  return {
    fetch,
    isLoading,
  };
};
