import { ArchiveModalAPI } from '@/components/ConfirmationModal';
import { TErrorNotification } from '@/components/ErrorInlineNotification/ErrorInlineNotification';
import { useDrawerClose } from '@/context/Drawer';
import {
  useReceivedInvoiceArchive,
  useReceivedInvoiceRestoreArchive,
} from '@/context/services/invoice/ReceivedInvoice.service';
import { useTranslation } from '@/i18n';
import { API } from '@moneyforward/ap-frontend-components';
import { useCallback } from 'react';

type APIResult = 'success' | 'error';

export const useCallArchiveCallback = (modal: ArchiveModalAPI, notify: API) => {
  const { t } = useTranslation();
  const drawerClose = useDrawerClose();
  return useCallback(
    async (result: APIResult) => {
      modal.close();
      if (result === 'success') {
        drawerClose();
        notify.success({
          title: t('インボイスファイルをゴミ箱に移動しました'),
          opts: {
            duration: 3000,
            style: {
              position: 'fixed',
            },
          },
          bodyProps: {
            disabledSuffix: true,
          },
        });
      }
    },
    [drawerClose, modal, notify, t]
  );
};

export const useCallArchive = (
  id: string,
  callback: (result: APIResult) => void,
  setError: TErrorNotification['setError']
) => {
  const { mutateAsync } = useReceivedInvoiceArchive();
  return useCallback(async () => {
    let result: APIResult = 'success';
    try {
      await mutateAsync(
        { id: id },
        {
          refetchQueries: ['ReceivedInvoice', 'ReceivedInvoices'],
        }
      );
    } catch (err) {
      result = 'error';
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      setError(err as Error);
    } finally {
      callback(result);
    }
  }, [callback, id, mutateAsync, setError]);
};

export const useCallRestoreArchiveCallback = (notify: API) => {
  const { t } = useTranslation();
  return useCallback(
    (result: APIResult) => {
      if (result === 'success') {
        notify.success({
          title: t('インボイスファイルをゴミ箱から戻しました'),
          opts: {
            duration: 3000,
            style: {
              position: 'fixed',
            },
          },
          bodyProps: {
            disabledSuffix: true,
          },
        });
      }
    },
    [notify, t]
  );
};

export const useCallRestoreArchive = (
  id: string,
  callback: (result: APIResult) => void,
  setError: TErrorNotification['setError']
) => {
  const { mutateAsync } = useReceivedInvoiceRestoreArchive();
  return useCallback(async () => {
    let result: APIResult = 'success';
    try {
      await mutateAsync(
        { id },
        {
          refetchQueries: ['ReceivedInvoice', 'ReceivedInvoices'],
        }
      );
    } catch (err) {
      result = 'error';
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      setError(err as Error);
    } finally {
      callback(result);
    }
  }, [callback, id, mutateAsync, setError]);
};
