import { FC, ReactNode, memo, useEffect, useMemo, useState } from 'react';
import { useFeatureFlag } from './context';
import {
  IFeatureFlag,
  OverrideCheck,
  FeatureFlagProps as Props,
} from './types';

type InnerProps = {
  isComponent: boolean;
  page?: ReactNode;
  component?: ReactNode;
  notFoundComponent?: ReactNode;
} & Props;

const defaultChecker: OverrideCheck = async (
  module: IFeatureFlag,
  flagName: string,
  skip?: boolean
) => await module.check(flagName, skip);

const FeatureFlags: FC<InnerProps> = memo(
  ({
    flagName,
    children,
    isComponent,
    page,
    component,
    notFoundComponent,
    overrideChecker = defaultChecker,
  }) => {
    const [isCheck, setCheck] = useState(false);
    const context = useFeatureFlag();
    useEffect(() => {
      const fn = async () => {
        const flg = await overrideChecker(context.module, flagName);
        setCheck(flg);
      };
      fn();
    }, [context.module, flagName, overrideChecker]);
    const componentNode = useMemo(
      () => (component ? <>{component}</> : null),
      [component]
    );
    const pageNode = useMemo(
      () => (page ? <>{page}</> : <>{notFoundComponent}</>),
      [notFoundComponent, page]
    );
    return isCheck ? <>{children}</> : isComponent ? componentNode : pageNode;
  }
);
FeatureFlags.displayName = 'FeatureFlags';

export const FeatureFlagPages: FC<Props> = memo(
  ({ flagName, children, customNode, overrideChecker }) => {
    return (
      <FeatureFlags
        isComponent={false}
        flagName={flagName}
        page={customNode}
        overrideChecker={overrideChecker}
      >
        {children}
      </FeatureFlags>
    );
  }
);
FeatureFlagPages.displayName = 'FeatureFlagPages';

export const FeatureFlagComponent: FC<Props> = memo(
  ({ flagName, children, customNode, overrideChecker }) => {
    return (
      <FeatureFlags
        flagName={flagName}
        isComponent
        component={customNode}
        overrideChecker={overrideChecker}
      >
        {children}
      </FeatureFlags>
    );
  }
);
FeatureFlagComponent.displayName = 'FeatureFlagComponent';
