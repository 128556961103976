import { BusinessDocumentDetail } from '@/context/services/business_document/type';
import { BusinessDocumentDetailLeftView } from '@/features/business_documents/Detail/components/Left';
import { BusinessDocumentDetailRightView } from '@/features/business_documents/Detail/components/Right';
import { SplitView } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';
import styles from './Content.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  data: BusinessDocumentDetail;
  onClose: () => void;
};

export const BusinessDocumentContent: FC<Props> = memo(({ data, onClose }) => {
  return (
    <SplitView
      className={cx(styles['container'])}
      draggableClassName={cx(styles['draggable'])}
      minWidthLeftPanel={484}
      minWidthRightPanel={472}
      left={<BusinessDocumentDetailLeftView data={data} onClose={onClose} />}
      right={<BusinessDocumentDetailRightView data={data} />}
    />
  );
});
BusinessDocumentContent.displayName = 'BusinessDocumentContent';
