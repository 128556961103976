import { useModelsToOptions } from '@/hooks/useTypeToOptions';
import { useTranslation } from '@/i18n';
import {
  FormSelect,
  FormSelectProps,
  SelectProps,
} from '@moneyforward/ap-frontend-components';
import {
  OfficeMember as OfficeMemberModel,
  useGetOfficeMembersInfinite,
} from 'ap-openapi';
import {
  KeyboardEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FieldValues } from 'react-hook-form';

type Options = {
  label: string;
  value: string;
};

export type Props<TFieldValues extends FieldValues> = {
  selectProps?: Omit<FormSelectProps<TFieldValues>['selectProps'], 'options'>;
  query?: string;
  per?: number;
  statusValues?: (string | number)[];
  validateMaxCount?: number;
  defaultOptions: Options[];
} & Omit<FormSelectProps<TFieldValues>, 'selectProps'>;

export const useValidateCount = (validateMaxCount: number | undefined) => {
  const { t } = useTranslation();
  if (validateMaxCount === undefined || validateMaxCount <= 0) {
    return {
      counter: () => true,
    };
  }
  const msg = t('設定できる人数は{count}名までです');
  return {
    counter: (value: unknown[]) =>
      value.length > validateMaxCount
        ? msg.format({ count: validateMaxCount })
        : true,
  };
};

export const _OfficeMember = <TFieldValues extends FieldValues>({
  selectProps: _selectProps,
  statusValues,
  validateMaxCount,
  defaultOptions: _defaultOptions,
  per = 25,
  ...rest
}: Props<TFieldValues>) => {
  const defaultOptions = useMemo(
    () => _defaultOptions ?? [],
    [_defaultOptions]
  );
  const { t } = useTranslation();
  const [query, setQuery] = useState<string | undefined>(undefined);
  const { data, error, fetchNextPage, hasNextPage } =
    useGetOfficeMembersInfinite(
      {
        query,
        per: per.toString(),
      },
      {
        query: {
          getNextPageParam: (page) => {
            const url = new URL(page.data.next ?? '/', location.href);
            if (!url.searchParams.has('page')) return undefined;
            return url.searchParams.get('page');
          },
        },
      }
    );
  if (error) throw error;
  const selectedCountValidation = useValidateCount(validateMaxCount);
  const modelsToOptions = useModelsToOptions<OfficeMemberModel>(
    query,
    defaultOptions,
    {
      labelKeys: ['name'],
      caption: 'number',
    }
  );

  const [options, setOption] = useState<SelectProps['options']>(
    modelsToOptions(
      data?.pages.flatMap((page) => page.data.office_members ?? []) ?? []
    )
  );
  useEffect(() => {
    if (!data) return;
    setOption(
      modelsToOptions(
        data.pages.flatMap((page) => page.data.office_members ?? []) ?? []
      )
    );
  }, [data, modelsToOptions]);

  const fetchMore = useCallback(
    async (callback: () => void) => {
      if (hasNextPage) {
        const { data } = await fetchNextPage();
        setOption(
          modelsToOptions(
            data?.pages.flatMap((page) => page.data.office_members ?? []) ?? []
          )
        );
      }
      callback();
    },
    [fetchNextPage, hasNextPage, modelsToOptions]
  );

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission on Enter key press
    }
  };

  const selectProps: SelectProps = useMemo(() => {
    return {
      placeholder: t('office_member_placeholder'),
      ..._selectProps,
      options: options,
      fetchMore: fetchMore,
      mode: 'multiple',
      comboBox: true,
      statusTagValues: statusValues,
      onSearch: setQuery,
      inputPlaceholder: t('office_member_input_placeholder'),
      onSearchDebounceWait: 600,
      onKeyPress: handleKeyPress,
    };
  }, [_selectProps, fetchMore, setQuery, options, statusValues, t]);

  return (
    <FormSelect<TFieldValues>
      selectProps={selectProps}
      {...rest}
      // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
      rules={{
        validate: {
          ...selectedCountValidation,
        },
      }}
    />
  );
};
_OfficeMember.displayName = '_OfficeMember';
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const OfficeMember = memo((props) => {
  return <_OfficeMember {...props} />;
}) as typeof _OfficeMember;
OfficeMember.displayName = 'OfficeMember';
