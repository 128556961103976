import { BusinessDocumentsSearchRefetch } from '@/context/services/business_document/type';
import { FC, PropsWithChildren, createContext, useContext } from 'react';

const BusinessDocumentsSearchContext = createContext<
  BusinessDocumentsSearchRefetch | undefined
>(undefined);

type TValue<T extends Function> = {
  value: T;
};

export const BusinessDocumentsSearchRefetchProvider: FC<
  PropsWithChildren<TValue<BusinessDocumentsSearchRefetch>>
> = ({ children, value }) => {
  return (
    <BusinessDocumentsSearchContext.Provider value={value}>
      {children}
    </BusinessDocumentsSearchContext.Provider>
  );
};

export const useBusinessDocumentsSearchRefetch = ():
  | BusinessDocumentsSearchRefetch
  | undefined => useContext(BusinessDocumentsSearchContext);
