import { createObjectURL } from 'packages-hooks';
import { useCallback } from 'react';

export const useDownloadClick = (data: Blob, fileName: string) => {
  return useCallback(() => {
    const link = document.createElement('a');
    const url = createObjectURL(data);
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }, [data, fileName]);
};
