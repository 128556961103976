import classnames from 'classnames/bind';
import styles from './foreignCurrencySettings.module.scss';

const cx = classnames.bind(styles);

export const ForeignCurrencySettings = () => {
  return (
    <div className={cx(styles['container'])}>
      <div>ForeignCurrencySettings</div>
    </div>
  );
};
