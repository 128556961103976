import { useGlobalContainerRef } from '@/wc/helper/ref';
import { FC, ReactNode, useCallback, useMemo } from 'react';
import {
  BaseApiArgs,
  ConfirmationModalProvider,
  useConfirmationModal,
} from '../Base';
import { ModifyConfirmationModalBody } from './Body';
import { ModifyConfirmationModalFooter } from './Footer';

type ApiArgs = {
  description: ReactNode;
  idLabel: string;
  id: string;
  fileName: string;
  onModifyClick: VoidFunction;
} & Omit<BaseApiArgs, 'body' | 'children'>;

export type ModifyModalAPI = {
  open: (args: ApiArgs) => void;
  close: () => void;
};

type Props = {
  children: ReactNode;
  getContainer?: () => HTMLElement | ShadowRoot;
};

export const ModifyConfirmationModalProvider: FC<Props> = ({
  children,
  getContainer: _getContainer,
}) => {
  const containerRef = useGlobalContainerRef();
  const getContainer = useMemo(
    () => _getContainer ?? containerRef,
    [_getContainer, containerRef]
  );
  return (
    <ConfirmationModalProvider getContainer={getContainer}>
      {children}
    </ConfirmationModalProvider>
  );
};

export const useModifyConfirmationModal = (): ModifyModalAPI => {
  const modal = useConfirmationModal();
  const onCancel = useCallback(() => {
    modal.close();
  }, [modal]);

  return {
    open(args) {
      const key = Date.now().toString();
      modal.open({
        ...args,
        title: args.title,
        body: (
          <ModifyConfirmationModalBody
            key={key}
            description={args.description}
            idLabel={args.idLabel}
            id={args.id}
            fileName={args.fileName}
          />
        ),
        footer: (
          <ModifyConfirmationModalFooter
            key={key}
            onCancelClick={onCancel}
            onModifyClick={args.onModifyClick}
          />
        ),
      });
    },
    close: onCancel,
  };
};
