import { Path } from '@/router';
import { useCallback } from 'react';

type UrlType = Extract<Path, '/received_invoices' | '/business_documents'>;

export const useInputOrParseUrl = (
  urlType: UrlType,
  queryName: string = 'id'
) => {
  return useCallback(
    (v: string) => {
      let value = '';
      try {
        const url = new URL(v);
        if (url.pathname === urlType) {
          value = url.searchParams.get(queryName) ?? v;
        }
      } catch (error) {
        value = v;
      }
      return value;
    },
    [queryName, urlType]
  );
};
