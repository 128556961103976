import { BusinessDocumentTableList } from '@/features/business_documents/List';
import classnames from 'classnames/bind';
import styles from './businessDocuments.module.scss';

const cx = classnames.bind(styles);

export const BusinessDocuments = () => {
  return (
    <div className={cx(styles['container'])}>
      <BusinessDocumentTableList />
    </div>
  );
};
