import { SuspenseErrorBoundary } from '@/components/SuspenseErrorBoundary';
import { Drawer } from '@/features/business_documents/UploadForm/Drawer';
import { TUploadForm } from '@/features/business_documents/UploadForm/type';
import { FC } from 'react';

export const UploadForm: FC<TUploadForm> = (props) => {
  return (
    <SuspenseErrorBoundary {...props}>
      <Drawer {...props} />
    </SuspenseErrorBoundary>
  );
};

UploadForm.displayName = 'UploadForm';
