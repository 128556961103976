import { isSuccess } from '@/context/services/type';
import type { GetTpmInvoicesResponse } from 'ap-openapi';
import { useGetTpmInvoices, useLazyTpmInvoice } from 'ap-openapi';

export const useTpmInvoices = (
  number: string
): GetTpmInvoicesResponse | null => {
  const { data, error } = useGetTpmInvoices(
    {
      number,
    },
    {
      query: {
        enabled: Boolean(number),
      },
    }
  );
  if (error) throw error;
  const body = data?.data;
  if (data && !isSuccess(data.status)) {
    return null;
  }
  return body ?? null;
};

export const useLazyTpmInvoices = () => {
  return useLazyTpmInvoice();
};
