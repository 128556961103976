import { useOperationStatusChecker } from '@/features/invoices/components/Details/Components/Context/OperationStatus';
import { useTranslation } from '@/i18n';
import { ButtonV2, ButtonV2Size } from '@moneyforward/ap-frontend-components';
import { FC, memo, useCallback, useState } from 'react';

type Props = {
  onClick: () => Promise<void>;
  size: ButtonV2Size;
  isDisabled?: boolean;
};

export const RestoreArchiveButton: FC<Props> = memo(
  ({ onClick: _onClick, size, isDisabled }) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const operationChecker = useOperationStatusChecker();
    const onClick = useCallback(async () => {
      setIsLoading(true);
      await _onClick();
      setIsLoading(false);
    }, [_onClick]);
    return (
      operationChecker.isArchived && (
        <ButtonV2
          isTertiary
          size={size}
          onClick={onClick}
          isLoading={isLoading}
          isDisabled={isDisabled}
        >
          {t('restore')}
        </ButtonV2>
      )
    );
  }
);
RestoreArchiveButton.displayName = 'RestoreArchiveButton';
