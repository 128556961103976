import { Content } from '@/features/business_documents/UploadForm/Content';
import { DEFAULT_DRAWER_WIDTH } from '@/features/business_documents/UploadForm/Content/constant';
import { TUploadForm } from '@/features/business_documents/UploadForm/type';
import { Drawer as DefaultDrawer } from '@moneyforward/ap-frontend-components';
import { FC, memo } from 'react';

export const Drawer: FC<TUploadForm> = memo(({ open, onClose }) => {
  return (
    <DefaultDrawer
      width={DEFAULT_DRAWER_WIDTH}
      visible={open}
      onClose={onClose}
      withOutDefaultTitle
      mask
      maskClosable={false}
    >
      <Content onClose={onClose} />
    </DefaultDrawer>
  );
});
Drawer.displayName = 'Drawer';
