/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * APIs for RCI FE
 * This document describes the APIs provided by NeoAP BE for RCI FE, designed to be integrated into NeoAP FE using `iframe`.\
These APIs utilize cookie-based authentication. All API requests must include the cookie `_ap_session`.

 * OpenAPI spec version: 1
 */

export type BusinessDocumentReceiptType =
  (typeof BusinessDocumentReceiptType)[keyof typeof BusinessDocumentReceiptType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BusinessDocumentReceiptType = {
  paper: 'paper',
  e_doc: 'e_doc',
} as const;
