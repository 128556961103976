import { useCopy } from '@/hooks/useCopy';
import {
  IconApproval,
  IconButton,
  IconButtonProps,
  IconCopy,
  Tooltip,
} from '@moneyforward/ap-frontend-components';
import { ReactNode, RefObject, forwardRef, memo, useCallback } from 'react';

export type Props = Omit<IconButtonProps, 'onClick'> & {
  text: string;
  show: boolean;
  copyTooltip?: ReactNode;
  copiedTooltip?: ReactNode;
  resetMilliSecond?: number;
};

export const CopyButton = memo(
  forwardRef<HTMLButtonElement, Props>(
    (
      {
        text,
        show,
        copyTooltip: tooltipTitle,
        copiedTooltip: copiedTooltipTitle,
        resetMilliSecond = 1000,
        ...rest
      },
      ref
    ) => {
      const { copy, reset, copied } = useCopy(
        text,
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        ref as RefObject<HTMLButtonElement>
      );

      const onClick = useCallback(() => {
        copy();
        const timeoutId = setTimeout(reset, resetMilliSecond);
        return () => clearTimeout(timeoutId);
      }, [copy, reset, resetMilliSecond]);

      if (!show) return null;

      return (
        <Tooltip title={copied ? copiedTooltipTitle : tooltipTitle}>
          <IconButton ref={ref} onClick={onClick} {...rest}>
            {copied ? <IconApproval /> : <IconCopy />}
          </IconButton>
        </Tooltip>
      );
    }
  )
);
CopyButton.displayName = 'CopyButton';
