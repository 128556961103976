import { BulkUploadBusinessDocumentsRequest } from '../../openapi/model/bulkUploadBusinessDocumentsRequest';

const isBulkUploadFormRequest = (
  body: any
): body is BulkUploadBusinessDocumentsRequest => {
  return (
    'files' in body &&
    'do_ai_ocr' in body &&
    'document_type' in body &&
    'receipt_type' in body
  );
};

export const customFomDataFn = <Body>(body: Body): FormData => {
  const formData = new FormData();

  if (isBulkUploadFormRequest(body)) {
    if (body.files.length > 0) {
      body.files.forEach((value, idx) =>
        formData.append(`files[${idx}]`, value)
      );
    }

    formData.append('do_ai_ocr', body.do_ai_ocr.toString());
    formData.append('document_type', body.document_type);
    formData.append('receipt_type', body.receipt_type);
  }
  return formData;
};
