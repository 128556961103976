import { EditableProvider } from '@/components/Editable';
import { SuspenseErrorBoundary } from '@/components/SuspenseErrorBoundary';
import { useReceivedInvoiceSearch } from '@/context/services/invoice/ReceivedInvoice.service';
import { ReceivedInvoiceSearchRefetchProvider } from '@/context/services/invoice/refetchContext';
import { Content } from '@/features/invoices/components/Details/Components/Content';
import { OperationStatusProvider } from '@/features/invoices/components/Details/Components/Context/OperationStatus';
import { FC, memo } from 'react';

export type Props = {
  invoiceId: string;
  onClose: () => void;
  parentWidth: number;
};

const InnerDetails: FC<Props> = memo(({ invoiceId, onClose, ...rest }) => {
  const { data, refetch } = useReceivedInvoiceSearch(invoiceId);
  return (
    <ReceivedInvoiceSearchRefetchProvider value={refetch}>
      <OperationStatusProvider value={data.operation_status}>
        <EditableProvider
          name='ReceivedInvoiceEdit'
          isEditable={data.invoice_report ? false : true}
        >
          <Content splitView data={data} onClose={onClose} {...rest} />
        </EditableProvider>
      </OperationStatusProvider>
    </ReceivedInvoiceSearchRefetchProvider>
  );
});
InnerDetails.displayName = 'InnerDetails';

export const Details: FC<Props> = memo((props) => {
  return (
    <SuspenseErrorBoundary {...props}>
      <InnerDetails {...props} />
    </SuspenseErrorBoundary>
  );
});
Details.displayName = 'Details';
