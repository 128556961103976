import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useRef,
} from 'react';

type Ref = {
  current: null | HTMLDivElement;
};

type GlobalRefs = {
  globalContentRef: Ref;
  globalSectionRef: Ref;
  globalMainRef: Ref;
};

const context = createContext<GlobalRefs>({
  globalContentRef: { current: null },
  globalSectionRef: { current: null },
  globalMainRef: { current: null },
});

export const GlobalRefsProvider: FC<PropsWithChildren> = ({ children }) => {
  const globalContentRef = useRef<HTMLDivElement>(null);
  const globalSectionRef = useRef<HTMLDivElement>(null);
  const globalMainRef = useRef<HTMLDivElement>(null);
  return (
    <context.Provider
      // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
      value={{
        globalContentRef,
        globalMainRef,
        globalSectionRef,
      }}
    >
      {children}
    </context.Provider>
  );
};

export const useGlobalRefs = () => useContext(context);
export const useGlobalContainerRef = () => {
  const globalRefs = useGlobalRefs();
  return () =>
    globalRefs.globalMainRef.current || globalRefs.globalContentRef.current!;
};
