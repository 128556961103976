import { useGlobalContainerRef, useGlobalRefs } from '@/wc/helper/ref';
import { App } from '@features/apps';
import {
  NotificationProvider,
  useNotificationHolder,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { Outlet } from 'react-router-dom';
import styles from './_app.module.scss';

const cx = classnames.bind(styles);

export default function _App() {
  const globalRefs = useGlobalRefs();
  const containerRef = useGlobalContainerRef();
  const [api, contextHolder] = useNotificationHolder({
    isEndPause: undefined,
    isStartPause: undefined,
    getContainer: containerRef,
  });
  return (
    <section
      ref={globalRefs.globalSectionRef}
      className={cx(styles['section'])}
    >
      <main ref={globalRefs.globalMainRef}>
        <App>
          <NotificationProvider value={api} contextHolder={contextHolder}>
            <Outlet />
          </NotificationProvider>
        </App>
      </main>
    </section>
  );
}
