import { useEffect, useReducer } from 'react';

export type ReactiveVar<T> = {
  (newValue?: T | ((value: T) => T)): T;
  subscribe: (handler: Function) => () => void;
  unsubscribe: (handler: Function) => void;
};

type EqualsFunc<T> = (a: T, b: T) => boolean;

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const makeVar = <T extends unknown>(
  initialValue: T,
  equalsFunc?: EqualsFunc<T>
): ReactiveVar<T> => {
  let value = initialValue;
  const subscribers = new Set<Function>();
  const reactiveVar = (newValue?: T | ((value: T) => T)) => {
    if (newValue !== undefined) {
      let nextValue = value;
      let valueChanged;

      if (newValue instanceof Function) {
        nextValue = newValue(value);
      } else {
        nextValue = newValue;
      }

      // eslint-disable-next-line prefer-const
      valueChanged = equalsFunc
        ? !equalsFunc(nextValue, value)
        : nextValue !== value;
      value = nextValue;

      if (valueChanged) {
        subscribers.forEach((handler) => handler(value));
      }
    }
    return value;
  };
  reactiveVar.subscribe = (handler: Function) => {
    subscribers.add(handler);
    return () => subscribers.delete(handler);
  };
  reactiveVar.unsubscribe = (handler: Function) => {
    subscribers.delete(handler);
  };
  return reactiveVar;
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const useReactiveVar = <T extends unknown>(
  reactiveVar: ReactiveVar<T>
) => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const handler = useReducer((x) => x + 1, 0)[1] as () => void;

  useEffect(() => {
    reactiveVar.subscribe(handler);
    return () => {
      reactiveVar.unsubscribe(handler);
    };
  }, [handler, reactiveVar]);

  return reactiveVar();
};
