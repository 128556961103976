import {
  ReceivedInvoicesSearchQuery,
  TReceivedInvoiceCondition,
} from '@/context/services/invoice/type';
import { toISODateString } from 'date-util';

export function convertToInvoiceFilterPayload(
  search: TReceivedInvoiceCondition
): ReceivedInvoicesSearchQuery {
  const {
    searchable_number_or_filename_cont,
    ap_payee_id_in,
    operation_status_in,
    pic_id_in,
    upload_type_in,
    uploaded_date,
  } = search;

  const result: ReceivedInvoicesSearchQuery = {};

  if (searchable_number_or_filename_cont) {
    result.searchable_number_or_filename_cont =
      searchable_number_or_filename_cont;
  }

  if (ap_payee_id_in) {
    result.ap_payee_id_in = ap_payee_id_in;
  }

  if (operation_status_in) {
    result.operation_status_in = operation_status_in;
  }

  if (pic_id_in) {
    result.pic_id_in = pic_id_in;
  }

  if (upload_type_in) {
    result.upload_type_in = upload_type_in;
  }

  if (uploaded_date && uploaded_date[0] !== '' && uploaded_date[1] !== '') {
    result.uploaded_at_gteq =
      uploaded_date[0] && toISODateString(uploaded_date[0]);
    result.uploaded_at_lteq =
      uploaded_date[1] && toISODateString(uploaded_date[1]);
  }

  return result;
}
