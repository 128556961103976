import { useErrorInlineNotification } from '@/components/ErrorInlineNotification';
import { DocumentType } from '@/features/business_documents/UploadForm/Content/DocumentType';
import { OCR } from '@/features/business_documents/UploadForm/Content/OCR';
import { ReceiptType } from '@/features/business_documents/UploadForm/Content/ReceiptType';
import { SelectedFiles } from '@/features/business_documents/UploadForm/Content/SelectedFiles';
import {
  ALLOWED_FILE_TYPES,
  FILE_TYPE_UPLOAD_ALLOWED,
  UPLOAD_FILE_LIMIT_TOTAL,
  UPLOAD_LIMIT_SIZE_PER_FILE,
} from '@/features/business_documents/UploadForm/Content/constant';
import { useSubmitBulkUpload } from '@/features/business_documents/UploadForm/Content/hooks';
import { TBusinessDocumentUpload } from '@/features/business_documents/UploadForm/Content/type';
import { TUploadForm } from '@/features/business_documents/UploadForm/type';
import { Description } from '@/features/business_documents/components/Description';
import {
  useAlertVisible,
  useValidationFiles,
} from '@/features/business_documents/hooks';
import { Header } from '@/features/invoices/components/Details/Components/Header';
import { useTranslation } from '@/i18n';
import {
  ButtonV2,
  Flex,
  Form,
  FormUploader,
  FunctionHeader,
  IconClose,
  Loading,
  UploadFiles,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import styles from './Content.module.scss';

const cx = classnames.bind(styles);

type Props = Omit<TUploadForm, 'open'>;

export const Content: FC<Props> = memo(({ onClose }) => {
  const { t } = useTranslation();

  const [keyForm, setKeyForm] = useState<number>();

  const methods = useForm<TBusinessDocumentUpload>({
    defaultValues: {
      files: [],
      receipt_type: 'paper',
      do_ai_ocr: true,
      document_type: 'not_specified',
    },
    mode: 'all',
  });
  const {
    setError,
    control,
    clearErrors,
    watch,
    formState: { isSubmitting, errors, isValid: isStateValid, isDirty },
    reset,
  } = methods;
  const { replace, remove } = useFieldArray({ control, name: 'files' });

  const [
    { setError: setInlineError },
    ErrorContextHolder,
    ErrorInlineNotificationProvider,
    onClearMessage,
  ] = useErrorInlineNotification();

  const files = watch('files');

  const setFiles = useCallback(
    (files: UploadFiles) => {
      setKeyForm(Date.now());
      replace(files);
    },
    [replace]
  );
  const { visible, onHidden } = useAlertVisible(files, setFiles);
  useValidationFiles({ files, setError, clearErrors, errors });

  const isFormValid = Object.values(errors)
    .map((err) => {
      const inValid = Array.isArray(err) ? err.length > 0 : !!err.message;
      return inValid;
    })
    .some(Boolean);

  const handleResetForm = useCallback(() => {
    setKeyForm(Date.now());
    reset();
    onClearMessage();
    onHidden();
  }, [reset, onClearMessage, onHidden]);

  const onCloseClick = useCallback(() => {
    handleResetForm();
    onClose();
    onHidden();
  }, [onClose, handleResetForm, onHidden]);

  const { isLoading, onHandleSubmit, errorMessage } = useSubmitBulkUpload(
    setInlineError,
    handleResetForm,
    remove,
    onClearMessage
  );

  useEffect(() => {
    if (errorMessage && errorMessage.length > 0) {
      errorMessage.map((error, idx) => {
        setError(`files.${idx}`, error);
      });
    }
  }, [errorMessage, setError]);

  const onDeleteClick = useCallback(
    (id: string) => () => {
      const removedIndex = files.findIndex((file) => file.uid === id);
      remove(removedIndex);
    },
    [remove, files]
  );

  const onSubmit = useCallback(
    (data: TBusinessDocumentUpload) => {
      onHandleSubmit(data);
      onHidden();
    },
    [onHandleSubmit, onHidden]
  );

  return (
    <ErrorInlineNotificationProvider value={setInlineError}>
      <Form<TBusinessDocumentUpload>
        formMethod={methods}
        onSubmit={onSubmit}
        key={keyForm}
        testId='uploadForm'
      >
        {isLoading && <Loading isOverlay size='xlarge' color='default' />}

        <Header
          className={cx(styles['headerContainer'])}
          testId='uploadForm-header'
        >
          <Flex columnGap='8px'>
            <div
              className={cx(styles['closeButton'])}
              onClick={onCloseClick}
              data-testid='uploadForm-close-icon'
            >
              <IconClose size={20} />
            </div>
            <span className={cx(styles['title'])}>
              {t('drawer_upload_title')}
            </span>
          </Flex>
          <ButtonV2
            size='sm'
            type='submit'
            isDisabled={
              isFormValid || isSubmitting || !isStateValid || !isDirty
            }
            testId='uploadForm-submit-button'
          >
            {t('batch_upload_button')}
          </ButtonV2>
        </Header>

        <div className={cx(styles['bodyContainer'])}>
          <Flex direction='column' rowGap='32px'>
            {ErrorContextHolder}
            <FormUploader<TBusinessDocumentUpload>
              control={control}
              name='files'
              uploadLimitAtOnce={UPLOAD_FILE_LIMIT_TOTAL}
              append
              // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
              rules={{ required: t('required') }}
              accept={ALLOWED_FILE_TYPES}
              testId='uploadForm-files'
              description={
                <>
                  {t('max_file_upload_and_maximum_size', {
                    max_file: UPLOAD_FILE_LIMIT_TOTAL,
                    max_size: UPLOAD_LIMIT_SIZE_PER_FILE,
                  })}
                  <br />
                  {t('file_type_upload_allowed', {
                    file_types: FILE_TYPE_UPLOAD_ALLOWED,
                  })}
                </>
              }
            />
            <Flex
              columnGap='32px'
              className={cx(styles['settingUploadContainer'])}
            >
              <Flex flex={1}>
                <Description>
                  <FunctionHeader
                    name={t('file_setting_title')}
                    testId='setupHeader'
                    className={cx(styles['headerRow'])}
                  />
                  <ReceiptType control={control} name='receipt_type' />
                  <DocumentType control={control} name='document_type' />
                  <OCR control={control} name='do_ai_ocr' />
                </Description>
              </Flex>
              <Flex flex={1}>
                <SelectedFiles
                  files={files}
                  control={control}
                  visible={visible}
                  onHidden={onHidden}
                  onDeleteClick={onDeleteClick}
                />
              </Flex>
            </Flex>
          </Flex>
        </div>
      </Form>
    </ErrorInlineNotificationProvider>
  );
});

Content.displayName = 'Content';
