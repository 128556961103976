/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * APIs for RCI FE
 * This document describes the APIs provided by NeoAP BE for RCI FE, designed to be integrated into NeoAP FE using `iframe`.\
These APIs utilize cookie-based authentication. All API requests must include the cookie `_ap_session`.

 * OpenAPI spec version: 1
 */

export type BusinessDocumentTimestampStatus =
  (typeof BusinessDocumentTimestampStatus)[keyof typeof BusinessDocumentTimestampStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BusinessDocumentTimestampStatus = {
  creating: 'creating',
  done: 'done',
  need_retry: 'need_retry',
  failed: 'failed',
  unsupported: 'unsupported',
} as const;
