import { useProcessingStatusChecker } from '@/features/business_documents/Detail/Context/ProcessingStatus';
import { useTranslation } from '@/i18n';
import {
  IconApproval,
  IconCaution,
  InlineNotification,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  isArchived: boolean;
};

export const Notification: FC<Props> = memo(({ isArchived }) => {
  const { t } = useTranslation();
  const processingStatus = useProcessingStatusChecker();

  return (
    <>
      {isArchived && (
        <div className={cx(styles['container'])}>
          <InlineNotification
            type='alert'
            visible
            message={t('inline_label_status_in_trash')}
            icon={<IconCaution size={18} />}
            visibleClose={false}
          />
        </div>
      )}

      {processingStatus.waiting_for_ocr_input && (
        <div className={cx(styles['container'])}>
          <InlineNotification
            type='success'
            visible
            message={t('inline_label_status_ocr_processing')}
            icon={<IconApproval size={18} />}
            visibleClose={false}
          />
        </div>
      )}

      {processingStatus.timeStamp_failed && (
        <div className={cx(styles['container'])}>
          <InlineNotification
            type='error'
            visible
            message={t('inline_label_status_timestamp_failed')}
            icon={<IconCaution size={18} />}
            visibleClose={false}
          />
        </div>
      )}
    </>
  );
});
Notification.displayName = 'Notification';
