import { ReceivedInvoiceDetail } from '@/context/services/invoice/type';
import { Ratio } from '@/features/invoices/components/Details/Components/Content/Ratio';
import { Split } from '@/features/invoices/components/Details/Components/Content/Split';
import { FC, memo } from 'react';

export type Props = {
  splitView: boolean;
  data: ReceivedInvoiceDetail;
  onClose: () => void;
};

export const Content: FC<Props> = memo(({ splitView, data, onClose }) => {
  return splitView ? (
    <Split data={data} onClose={onClose} />
  ) : (
    <Ratio data={data} onClose={onClose} />
  );
});
Content.displayName = 'Content';
