import { CopyButton } from '@/components/CopyButton';
import { useWrapperClick } from '@/features/invoices/components/Details/Components/Right/HeaderButton/hooks';
import { useGetFlags } from '@/hooks/useFeatureFlag';
import { useTranslation } from '@/i18n';
import { ButtonGroup } from '@moneyforward/ap-frontend-components';
import { FeatureFlagComponent } from 'packages-featureflags';
import { FC, useMemo } from 'react';
import { ArchiveButton } from './ArchiveButton';
import { CreateInvoiceReportButton } from './CreateInvoiceReport';
import { RestoreArchiveButton } from './RestoreButton';

type HeaderButton = {
  onArchiveClick: () => void;
  onArchiveRestoreClick: () => Promise<void>;
  onCreateInvoiceReportsClick: () => Promise<void>;
  copyText?: string;
};

export const HeaderButtons: FC<HeaderButton> = ({
  onArchiveClick,
  onArchiveRestoreClick,
  onCreateInvoiceReportsClick,
  copyText,
}) => {
  const { t } = useTranslation();
  const { isDisabled, onClick } = useWrapperClick({
    onArchiveClick,
    onArchiveRestoreClick,
    onCreateInvoiceReportsClick,
  });
  const flag = useGetFlags('RECEIVED_INVOICE_COPY_BUTTON');
  const size = useMemo(() => (flag ? 'sm' : 'md'), [flag]);
  const copyMessages = useMemo(
    () => ({
      coping: t('received_invoice_url_copy_text'),
      copied: t('received_invoice_url_copied_text'),
    }),
    [t]
  );

  return (
    <ButtonGroup>
      <FeatureFlagComponent flagName='RECEIVED_INVOICE_COPY_BUTTON'>
        <CopyButton
          size={size}
          text={copyText ?? ''}
          show
          copyTooltip={copyMessages.coping}
          copiedTooltip={copyMessages.copied}
        />
      </FeatureFlagComponent>
      <ArchiveButton
        onClick={onClick('archive')}
        size={size}
        isDisabled={isDisabled}
      />
      <CreateInvoiceReportButton
        onClick={onClick('invoiceReport')}
        size={size}
        isDisabled={isDisabled}
      />
      <RestoreArchiveButton
        onClick={onClick('restore')}
        size={size}
        isDisabled={isDisabled}
      />
    </ButtonGroup>
  );
};
