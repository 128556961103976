export const UPLOAD_FILE_LIMIT_TOTAL = 20;
export const DEFAULT_DRAWER_WIDTH = 992;
export const DEFAULT_DRAWER_DETAIL_WIDTH = 992;
export const FILE_TYPE_UPLOAD_ALLOWED = 'PDF,JPG,JPEG,PNG';
export const UPLOAD_LIMIT_SIZE_PER_FILE_BYTE = 50_000_000;
export const UPLOAD_LIMIT_SIZE_PER_FILE_UNIT = 'MB';
export const UPLOAD_LIMIT_SIZE_PER_FILE = `${
  UPLOAD_LIMIT_SIZE_PER_FILE_BYTE / 1_000_000
}${UPLOAD_LIMIT_SIZE_PER_FILE_UNIT}`;
export const ALLOWED_FILE_TYPES =
  'application/pdf, image/png, image/jpeg, image/jpg';
