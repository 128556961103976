import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

type ViewChange = {
  isView: boolean;
  onView: () => void;
  onEdit: () => void;
};

const ViewContext = createContext<ViewChange>({
  isView: true,
  onView() {},
  onEdit() {},
});

export const ViewContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isView, setIsView] = useState(true);
  const handler = useMemo((): ViewChange => {
    return {
      isView,
      onView: () => {
        setIsView(true);
      },
      onEdit: () => {
        setIsView(false);
      },
    };
  }, [isView]);
  return (
    <ViewContext.Provider value={handler}>{children}</ViewContext.Provider>
  );
};

export const useIsView = () => useContext(ViewContext);
