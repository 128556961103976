import { OperationStatus } from '@/context/services/invoice/type';
import { useTranslation } from '@/i18n';

export const useOperationStatusToErrorMessage = (
  status: OperationStatus | undefined
) => {
  const { t } = useTranslation();
  switch (status) {
    case undefined:
      return;
    case 'ocr_processing':
      return t('received_invoice_is_ocr_progressing');
    case 'applying':
    case 'applied':
      return t('received_invoice_is_not_reportable');
    case 'archived':
      return t('received_invoice_is_archived');
    default:
      return undefined;
  }
};
