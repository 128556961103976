import { useReceivedInvoiceSearchQuery } from '@/features/invoices/components/List/Table/hook';
import { useReceivedInvoicesFormInitial } from '@/features/invoices/components/List/hooks';
import {
  List,
  ReceivedInvoiceSearch,
} from '@features/invoices/components/List';
import classNames from 'classnames/bind';
import { FC, memo } from 'react';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

export const TemplateInvoiceList: FC = memo(() => {
  const defaultValues = useReceivedInvoicesFormInitial();

  const { searchQuery, handleSearch } =
    useReceivedInvoiceSearchQuery(defaultValues);

  return (
    <div className={cx(styles['container'])}>
      <ReceivedInvoiceSearch
        defaultValues={defaultValues}
        onSearch={handleSearch}
      />
      <List searchQuery={searchQuery} onSearch={handleSearch} />
    </div>
  );
});

TemplateInvoiceList.displayName = 'TemplateInvoiceList';
