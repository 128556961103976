import { ReceivedInvoiceDetail } from '@/context/services/invoice/type';
import { Left } from '@/features/invoices/components/Details/Components/Left';
import { Right } from '@/features/invoices/components/Details/Components/Right';
import { useGetFlags } from '@/hooks/useFeatureFlag';
import { SplitView } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo, useMemo } from 'react';
import styles from './Content.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  data: ReceivedInvoiceDetail;
  onClose: () => void;
};

export const Split: FC<Props> = memo(({ data, onClose }) => {
  const flag = useGetFlags('RECEIVED_INVOICE_COPY_BUTTON');
  const minWidthLeftPanel = useMemo(() => (flag ? 484 : 550), [flag]);
  const minWidthRightPanel = useMemo(() => (flag ? 484 : 452), [flag]);

  return (
    <SplitView
      className={cx(styles['container'])}
      draggableClassName={cx(styles['draggable'])}
      minWidthLeftPanel={minWidthLeftPanel}
      minWidthRightPanel={minWidthRightPanel}
      left={<Left data={data} onClose={onClose} />}
      right={<Right data={data} />}
    />
  );
});
Split.displayName = 'Split';
