import {
  ErrorBoundary as RollbarErrorBoundary,
  ErrorBoundaryProps as RollbarErrorBoundaryProps,
} from '@rollbar/react';
import { ComponentType, FC, memo } from 'react';

type FallbackProps = {
  error: Error | null;
};

type Fallback = ComponentType<FallbackProps>;
export type ErrorBoundaryProps = { fallback: Fallback } & Omit<
  RollbarErrorBoundaryProps,
  'fallbackUI'
>;

export const ErrorBoundary: FC<ErrorBoundaryProps> = memo(
  ({ children, fallback: UI, ...rest }) => {
    return (
      <RollbarErrorBoundary
        fallbackUI={({ error }) => <UI error={error} />}
        {...rest}
      >
        {children}
      </RollbarErrorBoundary>
    );
  }
);
ErrorBoundary.displayName = 'ErrorBoundary';
