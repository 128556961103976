import { OperationStatusLabel } from '@/context/services/invoice/type';
import { SelectProps } from '@moneyforward/ap-frontend-components';
import { useMemo } from 'react';

export const useReceivedInvoiceOperationStatusOptions =
  (): SelectProps['options'] => {
    const options = useMemo(
      () => [
        {
          value: 'ocr_processing',
          label: OperationStatusLabel.ocr_processing || '',
        },
        { value: 'not_started', label: OperationStatusLabel.not_started || '' },
        { value: 'applying', label: OperationStatusLabel.applying || '' },
        { value: 'applied', label: OperationStatusLabel.applied || '' },
        { value: 'archived', label: OperationStatusLabel.archived || '' },
      ],
      []
    );

    return options;
  };
