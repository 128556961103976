import { useProcessingStatusChecker } from '@/features/business_documents/Detail/Context/ProcessingStatus';
import { useCopy } from '@/hooks/useCopy';
import { useTranslation } from '@/i18n';
import {
  IconApproval,
  IconButton,
  IconCopy,
  Tooltip,
} from '@moneyforward/ap-frontend-components';
import { FC, useCallback, useMemo } from 'react';

type CopyURLButtonProps = {
  copyText: string;
};

export const CopyURLButton: FC<CopyURLButtonProps> = ({ copyText }) => {
  const { t } = useTranslation();
  const { copy, copied, reset } = useCopy(copyText);

  const onClick = useCallback(() => {
    copy();
    setTimeout(() => {
      reset();
    }, 2000);
  }, [copy, reset]);

  const tooltipContent = useMemo(() => {
    return copied
      ? t('business_document_copied_text')
      : t('business_document_copy_text');
  }, [copied, t]);

  const processingChecker = useProcessingStatusChecker();
  if (processingChecker.isArchived) {
    return <></>;
  }

  return (
    <Tooltip title={tooltipContent}>
      <IconButton
        size='sm'
        onClick={onClick}
        testId='business-document-copy-button'
      >
        {copied ? <IconApproval /> : <IconCopy />}
      </IconButton>
    </Tooltip>
  );
};
