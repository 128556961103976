import { useGetXMLFileAndObjectURL } from '@/context/services/invoice/ReceivedInvoice.service';
import { useDownloadClick } from '@/features/invoices/components/Details/Components/Left/hooks';
import { useTranslation } from '@/i18n';
import {
  ActionMenu,
  ActionMenuItems,
  ButtonV2Props,
  IconTrailing,
} from '@moneyforward/ap-frontend-components';
import { FC, memo, useMemo } from 'react';

type InvoiceDownloadButtonProps = {
  onDownloadPDFClick: VoidFunction;
  fileName: string | undefined;
  invoiceId: string | undefined;
};
export const FileDownloadDropdown: FC<InvoiceDownloadButtonProps> = memo(
  ({ fileName, onDownloadPDFClick, invoiceId }) => {
    const { t } = useTranslation();
    const blob = useGetXMLFileAndObjectURL(invoiceId);

    const onDownloadXMLClick = useDownloadClick(blob, fileName || '');

    const items: ActionMenuItems = useMemo(
      () => [
        {
          label: t('XMLファイル（原本）'),
          onClick: onDownloadXMLClick,
          key: '1',
        },
        {
          label: t('PDFファイル'),
          key: '2',
          onClick: onDownloadPDFClick,
        },
      ],
      [t, onDownloadPDFClick, onDownloadXMLClick]
    );

    const buttonProps: ButtonV2Props = useMemo(
      () => ({
        children: t('ダウンロード'),
        color: 'primary',
        isSecondary: true,
        isTertiary: true,
        rightIcon: <IconTrailing size={16} />,
      }),
      [t]
    );
    return <ActionMenu type='button' items={items} buttonProps={buttonProps} />;
  }
);
FileDownloadDropdown.displayName = 'FileDownloadDropdown';
