import classnames from 'classnames/bind';
import { FC, memo, ReactNode, useMemo } from 'react';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

type Props = {
  children: ReactNode;
  className?: string;
};

export const Description: FC<Props> = memo(({ children }) => {
  return <div className={cx(styles.container)}>{children}</div>;
});
Description.displayName = 'Description';

type DescriptionRowProps = Props & {
  visible?: boolean;
};

export const DescriptionRow: FC<DescriptionRowProps> = memo(
  ({ children, className, visible = true }) => {
    return visible ? (
      <div className={cx(styles.row, className)}>{children}</div>
    ) : null;
  }
);
DescriptionRow.displayName = 'DescriptionRow';

type DescriptionColProps = Partial<{
  label: ReactNode;
  labelWidth: string;
  value: ReactNode;
  valueWidth: string;
  align?: string;
  className?: string;
}>;

export const DescriptionCol: FC<DescriptionColProps> = memo(
  ({ label, labelWidth, align, value, valueWidth, className }) => {
    const props = useMemo(() => {
      const flexDefault = `1 1 0`;
      const labelStyle = {
        flex: labelWidth ? `0 0 ${labelWidth}` : flexDefault,
      };
      const valueStyle = {
        flex: valueWidth ? `0 0 ${valueWidth}` : flexDefault,
        justifyContent: align,
      };

      return {
        labelStyle,
        valueStyle,
      };
    }, [align, labelWidth, valueWidth]);

    return (
      <>
        {label && (
          <div
            className={cx([styles.col, styles.label, className])}
            // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
            style={{ ...props.labelStyle }}
          >
            {label}
          </div>
        )}
        {value && (
          <div
            className={cx([styles.col, styles.value, className])}
            // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
            style={{ ...props.valueStyle }}
          >
            {value}
          </div>
        )}
      </>
    );
  }
);
DescriptionCol.displayName = 'DescriptionCol';
