import { Loading } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';
import styles from './fallback.module.scss';

const cx = classnames.bind(styles);

export const SuspenseLoading: FC = memo(() => {
  return (
    <div className={cx(styles['container'])}>
      <Loading className={cx(styles['spinner'])} size='large' />
    </div>
  );
});
SuspenseLoading.displayName = 'SuspenseLoading';
