import { Button, Flex } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

export const Error404: FC = memo(() => {
  const title = 'お探しのページは見つかりませんでした';

  return (
    <>
      <Flex
        justify='center'
        align='center'
        direction='column'
        className={cx(styles['container'])}
      >
        <h2 className={cx(styles['title'])}>{title}</h2>
        <p className={cx(styles['desc'])}>
          アクセスしようとしたページはすでに存在しないか
          <br />
          またはURLが間違っている可能性があります。
        </p>
        <Button
          color='primary-blue'
          onClick={() => (location.href = '/')}
          size='lg'
        >
          トップページへ
        </Button>
      </Flex>
    </>
  );
});

Error404.displayName = 'Error404';
