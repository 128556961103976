import {
  TBusinessDocumentUpload,
  TSetupUploadProps,
} from '@/features/business_documents/UploadForm/Content/type';
import {
  DescriptionCol,
  DescriptionRow,
} from '@/features/business_documents/components/Description';
import { useTranslation } from '@/i18n';
import { FormCheckbox, Label } from '@moneyforward/ap-frontend-components';
import { FC, memo } from 'react';

export const OCR: FC<TSetupUploadProps> = memo(({ control, name }) => {
  const { t } = useTranslation();

  return (
    <DescriptionRow>
      <DescriptionCol
        label={
          <Label
            text={t('AI OCR')}
            tooltip={t('business_document_ocr_tooltip')}
            testId='aiOcrLabel'
          />
        }
        labelWidth='160px'
      />
      <DescriptionCol
        value={
          <FormCheckbox<TBusinessDocumentUpload>
            name={name}
            inputId='ai_ocr'
            label={t('use')}
            control={control}
            testId='OCR'
          />
        }
      />
    </DescriptionRow>
  );
});
OCR.displayName = 'OCR';
