/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * APIs for RCI FE
 * This document describes the APIs provided by NeoAP BE for RCI FE, designed to be integrated into NeoAP FE using `iframe`.\
These APIs utilize cookie-based authentication. All API requests must include the cookie `_ap_session`.

 * OpenAPI spec version: 1
 */
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import type { BadRequestResponse } from '../model/badRequestResponse';
import type { ForbiddenResponse } from '../model/forbiddenResponse';
import type { GetReceivedInvoiceResponse } from '../model/getReceivedInvoiceResponse';
import type { GetSignedXmlFileUrl200 } from '../model/getSignedXmlFileUrl200';
import type { NotFoundResponse } from '../model/notFoundResponse';
import type { SearchReceivedInvoicesRequest } from '../model/searchReceivedInvoicesRequest';
import type { SearchReceivedInvoicesResponse } from '../model/searchReceivedInvoicesResponse';
import type { UnauthorizedResponse } from '../model/unauthorizedResponse';
import type { UnprocessableEntityResponse } from '../model/unprocessableEntityResponse';
import type { UpdateReceivedInvoiceErrorResponse } from '../model/updateReceivedInvoiceErrorResponse';
import type { UpdateReceivedInvoiceRequest } from '../model/updateReceivedInvoiceRequest';
import type { UpdateReceivedInvoiceResponse } from '../model/updateReceivedInvoiceResponse';
import { useCustomClient } from '../../mutator/custom-instance';
import type { ErrorType, BodyType } from '../../mutator/custom-instance';
import { customFomDataFn } from '../../api/formData/custom-function';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * @summary Get a list of received invoices
 */
export const useSearchReceivedInvoicesHook = () => {
  const searchReceivedInvoices =
    useCustomClient<SearchReceivedInvoicesResponse>();

  return (
    searchReceivedInvoicesRequest: BodyType<SearchReceivedInvoicesRequest>,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>
  ) => {
    return searchReceivedInvoices(
      {
        url: `/api/js/received_invoice/v1/received_invoices/search`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: searchReceivedInvoicesRequest,
      },
      options
    );
  };
};

export const getSearchReceivedInvoicesQueryKey = (
  searchReceivedInvoicesRequest: SearchReceivedInvoicesRequest
) => {
  return [
    `/api/js/received_invoice/v1/received_invoices/search`,
    searchReceivedInvoicesRequest,
  ] as const;
};

export const useSearchReceivedInvoicesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useSearchReceivedInvoicesHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  searchReceivedInvoicesRequest: SearchReceivedInvoicesRequest,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useSearchReceivedInvoicesHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSearchReceivedInvoicesQueryKey(searchReceivedInvoicesRequest);

  const searchReceivedInvoices = useSearchReceivedInvoicesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useSearchReceivedInvoicesHook>>>
  > = () =>
    searchReceivedInvoices(searchReceivedInvoicesRequest, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useSearchReceivedInvoicesHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchReceivedInvoicesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSearchReceivedInvoicesHook>>>
>;
export type SearchReceivedInvoicesQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get a list of received invoices
 */
export const useSearchReceivedInvoices = <
  TData = Awaited<ReturnType<ReturnType<typeof useSearchReceivedInvoicesHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  searchReceivedInvoicesRequest: SearchReceivedInvoicesRequest,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useSearchReceivedInvoicesHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useSearchReceivedInvoicesQueryOptions(
    searchReceivedInvoicesRequest,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useSearchReceivedInvoicesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useSearchReceivedInvoicesHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  searchReceivedInvoicesRequest: SearchReceivedInvoicesRequest,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useSearchReceivedInvoicesHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSearchReceivedInvoicesQueryKey(searchReceivedInvoicesRequest);

  const searchReceivedInvoices = useSearchReceivedInvoicesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useSearchReceivedInvoicesHook>>>
  > = () =>
    searchReceivedInvoices(searchReceivedInvoicesRequest, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useSearchReceivedInvoicesHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchReceivedInvoicesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSearchReceivedInvoicesHook>>>
>;
export type SearchReceivedInvoicesSuspenseQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get a list of received invoices
 */
export const useSearchReceivedInvoicesSuspense = <
  TData = Awaited<ReturnType<ReturnType<typeof useSearchReceivedInvoicesHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  searchReceivedInvoicesRequest: SearchReceivedInvoicesRequest,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useSearchReceivedInvoicesHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useSearchReceivedInvoicesSuspenseQueryOptions(
    searchReceivedInvoicesRequest,
    options
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get details of a received invoice
 */
export const useGetReceivedInvoiceHook = () => {
  const getReceivedInvoice = useCustomClient<GetReceivedInvoiceResponse>();

  return (
    id: string,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>,
    signal?: AbortSignal
  ) => {
    return getReceivedInvoice(
      {
        url: `/api/js/received_invoice/v1/received_invoices/${id}`,
        method: 'GET',
        signal,
      },
      options
    );
  };
};

export const getGetReceivedInvoiceQueryKey = (id: string) => {
  return [`/api/js/received_invoice/v1/received_invoices/${id}`] as const;
};

export const useGetReceivedInvoiceQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetReceivedInvoiceHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetReceivedInvoiceHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReceivedInvoiceQueryKey(id);

  const getReceivedInvoice = useGetReceivedInvoiceHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetReceivedInvoiceHook>>>
  > = ({ signal }) => getReceivedInvoice(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetReceivedInvoiceHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReceivedInvoiceQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetReceivedInvoiceHook>>>
>;
export type GetReceivedInvoiceQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get details of a received invoice
 */
export const useGetReceivedInvoice = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetReceivedInvoiceHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetReceivedInvoiceHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetReceivedInvoiceQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetReceivedInvoiceSuspenseQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetReceivedInvoiceHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetReceivedInvoiceHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReceivedInvoiceQueryKey(id);

  const getReceivedInvoice = useGetReceivedInvoiceHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetReceivedInvoiceHook>>>
  > = ({ signal }) => getReceivedInvoice(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetReceivedInvoiceHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReceivedInvoiceSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetReceivedInvoiceHook>>>
>;
export type GetReceivedInvoiceSuspenseQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get details of a received invoice
 */
export const useGetReceivedInvoiceSuspense = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetReceivedInvoiceHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetReceivedInvoiceHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetReceivedInvoiceSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Update a received invoice
 */
export const useUpdateReceivedInvoiceHook = () => {
  const updateReceivedInvoice =
    useCustomClient<UpdateReceivedInvoiceResponse>();

  return (
    id: string,
    updateReceivedInvoiceRequest: BodyType<UpdateReceivedInvoiceRequest>,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>
  ) => {
    return updateReceivedInvoice(
      {
        url: `/api/js/received_invoice/v1/received_invoices/${id}`,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        data: updateReceivedInvoiceRequest,
      },
      options
    );
  };
};

export const useUpdateReceivedInvoiceMutationOptions = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UpdateReceivedInvoiceErrorResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateReceivedInvoiceHook>>>,
    TError,
    { id: string; data: BodyType<UpdateReceivedInvoiceRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateReceivedInvoiceHook>>>,
  TError,
  { id: string; data: BodyType<UpdateReceivedInvoiceRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const updateReceivedInvoice = useUpdateReceivedInvoiceHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateReceivedInvoiceHook>>>,
    { id: string; data: BodyType<UpdateReceivedInvoiceRequest> }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateReceivedInvoice(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateReceivedInvoiceMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateReceivedInvoiceHook>>>
>;
export type UpdateReceivedInvoiceMutationBody =
  BodyType<UpdateReceivedInvoiceRequest>;
export type UpdateReceivedInvoiceMutationError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UpdateReceivedInvoiceErrorResponse
>;

/**
 * @summary Update a received invoice
 */
export const useUpdateReceivedInvoice = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UpdateReceivedInvoiceErrorResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateReceivedInvoiceHook>>>,
    TError,
    { id: string; data: BodyType<UpdateReceivedInvoiceRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}) => {
  const mutationOptions = useUpdateReceivedInvoiceMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Archive a received invoice
 */
export const useArchiveReceivedInvoiceHook = () => {
  const archiveReceivedInvoice = useCustomClient<void>();

  return (
    id: string,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>
  ) => {
    return archiveReceivedInvoice(
      {
        url: `/api/js/received_invoice/v1/received_invoices/${id}/archive`,
        method: 'POST',
      },
      options
    );
  };
};

export const useArchiveReceivedInvoiceMutationOptions = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useArchiveReceivedInvoiceHook>>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useArchiveReceivedInvoiceHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const archiveReceivedInvoice = useArchiveReceivedInvoiceHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useArchiveReceivedInvoiceHook>>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return archiveReceivedInvoice(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ArchiveReceivedInvoiceMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useArchiveReceivedInvoiceHook>>>
>;

export type ArchiveReceivedInvoiceMutationError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Archive a received invoice
 */
export const useArchiveReceivedInvoice = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useArchiveReceivedInvoiceHook>>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}) => {
  const mutationOptions = useArchiveReceivedInvoiceMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Restore an archived received invoice
 */
export const useRestoreReceivedInvoiceHook = () => {
  const restoreReceivedInvoice = useCustomClient<void>();

  return (
    id: string,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>
  ) => {
    return restoreReceivedInvoice(
      {
        url: `/api/js/received_invoice/v1/received_invoices/${id}/restoration`,
        method: 'POST',
      },
      options
    );
  };
};

export const useRestoreReceivedInvoiceMutationOptions = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRestoreReceivedInvoiceHook>>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useRestoreReceivedInvoiceHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const restoreReceivedInvoice = useRestoreReceivedInvoiceHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useRestoreReceivedInvoiceHook>>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return restoreReceivedInvoice(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RestoreReceivedInvoiceMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useRestoreReceivedInvoiceHook>>>
>;

export type RestoreReceivedInvoiceMutationError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Restore an archived received invoice
 */
export const useRestoreReceivedInvoice = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRestoreReceivedInvoiceHook>>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}) => {
  const mutationOptions = useRestoreReceivedInvoiceMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get the signed url that download xml file from RCI service
 */
export const useGetSignedXmlFileUrlHook = () => {
  const getSignedXmlFileUrl = useCustomClient<GetSignedXmlFileUrl200>();

  return (
    id: string,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>,
    signal?: AbortSignal
  ) => {
    return getSignedXmlFileUrl(
      {
        url: `/api/js/received_invoice/v1/received_invoices/${id}/preview_xml_url`,
        method: 'GET',
        signal,
      },
      options
    );
  };
};

export const getGetSignedXmlFileUrlQueryKey = (id: string) => {
  return [
    `/api/js/received_invoice/v1/received_invoices/${id}/preview_xml_url`,
  ] as const;
};

export const useGetSignedXmlFileUrlQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetSignedXmlFileUrlHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetSignedXmlFileUrlHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSignedXmlFileUrlQueryKey(id);

  const getSignedXmlFileUrl = useGetSignedXmlFileUrlHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetSignedXmlFileUrlHook>>>
  > = ({ signal }) => getSignedXmlFileUrl(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetSignedXmlFileUrlHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSignedXmlFileUrlQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetSignedXmlFileUrlHook>>>
>;
export type GetSignedXmlFileUrlQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get the signed url that download xml file from RCI service
 */
export const useGetSignedXmlFileUrl = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetSignedXmlFileUrlHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetSignedXmlFileUrlHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetSignedXmlFileUrlQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetSignedXmlFileUrlSuspenseQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetSignedXmlFileUrlHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetSignedXmlFileUrlHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSignedXmlFileUrlQueryKey(id);

  const getSignedXmlFileUrl = useGetSignedXmlFileUrlHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetSignedXmlFileUrlHook>>>
  > = ({ signal }) => getSignedXmlFileUrl(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetSignedXmlFileUrlHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSignedXmlFileUrlSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetSignedXmlFileUrlHook>>>
>;
export type GetSignedXmlFileUrlSuspenseQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get the signed url that download xml file from RCI service
 */
export const useGetSignedXmlFileUrlSuspense = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetSignedXmlFileUrlHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetSignedXmlFileUrlHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetSignedXmlFileUrlSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
