import {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
  useQuery,
  useSuspenseQuery,
} from '@tanstack/react-query';
import {
  BadRequestResponse,
  ErrorType,
  ForbiddenResponse,
  NotFoundResponse,
  UnauthorizedResponse,
  UnprocessableEntityResponse,
  useCustomClient,
} from 'ap-openapi';

export const useDownloadHook = () => {
  const client = useCustomClient<Blob>();
  return (url: string, signal?: AbortSignal) => {
    return client({
      url: url,
      withCredentials: false,
      method: 'GET',
      signal,
      responseType: 'blob',
    });
  };
};

export const getDownloadUrlQueryKey = (url: string) => {
  try {
    const u = new URL(url);
    return [u.origin, u.pathname];
  } catch (error) {
    return [url];
  }
};

export const useDownloadS3SignedUrlQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useDownloadHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  url: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useDownloadHook>>>,
        TError,
        TData
      >
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDownloadUrlQueryKey(url);

  const downloadFn = useDownloadHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useDownloadHook>>>
  > = ({ signal }) => downloadFn(url, signal);

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return {
    queryKey,
    queryFn,
    enabled: !!url,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useDownloadHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export const useDownloadS3SignedUrl = <
  TData = Awaited<ReturnType<ReturnType<typeof useDownloadHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  url: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useDownloadHook>>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useDownloadS3SignedUrlQueryOptions(url, options);

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useDownloadS3SignedUrlSuspenseQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useDownloadHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  url: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useDownloadHook>>>,
        TError,
        TData
      >
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDownloadUrlQueryKey(url);

  const downloadFn = useDownloadHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useDownloadHook>>>
  > = ({ signal }) => downloadFn(url, signal);

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return {
    queryKey,
    queryFn,
    enabled: !!url,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useDownloadHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export const useDownloadS3SignedUrlSuspense = <
  TData = Awaited<ReturnType<ReturnType<typeof useDownloadHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  url: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useDownloadHook>>>,
        TError,
        TData
      >
    >;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useDownloadS3SignedUrlSuspenseQueryOptions(url, options);

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
