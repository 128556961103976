import { ReceivedInvoiceOperationStatus } from '@/context/services/invoice/type';
import { useTranslation } from '@/i18n';
import { useMemo } from 'react';

export const OperationStatuses = {
  ocrProcessing: 'ocr_processing',
  notStarted: 'not_started',
  applying: 'applying',
  applied: 'applied',
  archived: 'archived',
} as const;
type TOperationStatus = typeof OperationStatuses;
export type OperationStatus = keyof TOperationStatus;
export type OperationStatusValue = TOperationStatus[OperationStatus];
export type OperationStatusValues = {
  [K in OperationStatus as TOperationStatus[K]]: K;
};

const OperationStatusesValue: OperationStatusValues = Object.entries(
  OperationStatuses
).reduce(
  (prev, [key, value]) => ({ ...prev, [value]: key }),
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as OperationStatusValues
);

export const typeGuardOperationStatusValue = (
  value: ReceivedInvoiceOperationStatus | undefined
): value is OperationStatusValue => {
  if (value === undefined) throw new Error('Type guard failed.');
  return value in OperationStatusesValue;
};

export const convertOperationStatus = (
  value: ReceivedInvoiceOperationStatus
) => {
  const v = OperationStatusesValue[value];
  if (!v) {
    throw new Error('Type conversion failed.');
  }
  return v;
};

type TOperationStatusLabel = { [key in OperationStatus]: string };
export const useLabel = (value: OperationStatus): string => {
  const { t } = useTranslation();
  const obj: TOperationStatusLabel = useMemo(
    () => ({
      ocrProcessing: t('operation_status_OCR読取り中'),
      notStarted: t('operation_status_未着手'),
      applying: t('operation_status_編集中'),
      applied: t('operation_status_申請済'),
      archived: t('operation_status_ゴミ箱'),
    }),
    [t]
  );
  const v = useMemo(() => obj[value], [obj, value]);
  return v;
};
