import { useOperationStatusChecker } from '@/features/invoices/components/Details/Components/Context/OperationStatus';
import { useIsView } from '@/features/invoices/components/Details/Components/Context/ViewEdit';
import { useGetFlags } from '@/hooks/useFeatureFlag';
import { useTranslation } from '@/i18n';
import {
  Tooltip as BaseTooltip,
  ButtonV2,
  ButtonV2Size,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, PropsWithChildren, useMemo } from 'react';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

type ArchiveButtonProps = {
  onClick: () => void;
  size: ButtonV2Size;
  isDisabled?: boolean;
};

export const ArchiveButton: FC<ArchiveButtonProps> = ({
  onClick,
  size,
  isDisabled: _isDisabled,
}) => {
  const { t } = useTranslation();
  const operationChecker = useOperationStatusChecker();
  const { isView } = useIsView();
  const isDisabled = useMemo(() => {
    if (_isDisabled) return true;
    if (operationChecker.isEdit) return true;
    return !isView;
  }, [_isDisabled, isView, operationChecker.isEdit]);
  const Tooltip: FC<PropsWithChildren> = ({ children }) => {
    const message = useMemo(
      () => (
        <div className={cx(styles['container'])}>
          <div>すでに支払依頼申請を作成されたファイルは</div>
          <div>ゴミ箱へ移動できません。</div>
          <div>該当の支払依頼申請を削除してください。</div>
        </div>
      ),
      []
    );
    return isDisabled && !operationChecker.isNotStated ? (
      <BaseTooltip
        overlayClassName={cx(styles['tooltipContainer'])}
        title={message}
        placement='bottomRight'
        wrapSpanTag
      >
        {children}
      </BaseTooltip>
    ) : (
      <>{children}</>
    );
  };
  const flag = useGetFlags('RECEIVED_INVOICE_COPY_BUTTON');
  const buttonProps: {
    color: 'danger' | 'primary';
    isSecondary?: boolean;
    isTertiary?: boolean;
  } = useMemo(
    () =>
      flag
        ? {
            color: 'danger',
            isSecondary: true,
          }
        : { color: 'primary', isTertiary: true },
    [flag]
  );

  return (
    <>
      {(operationChecker.isEdit || operationChecker.isNotStated) && (
        <Tooltip>
          <ButtonV2
            size={size}
            {...buttonProps}
            isDisabled={isDisabled}
            onClick={onClick}
          >
            {t('ゴミ箱へ移動')}
          </ButtonV2>
        </Tooltip>
      )}
    </>
  );
};
