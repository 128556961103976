import { makeVar, useReactiveVar } from 'packages-hooks';
import { useEffect, useState } from 'react';
import { Denied } from './modules';
import { FlagSet, featureFlag } from './types';
export type featureFlagContext = {
  isFirst: boolean;
  flags: FlagSet;
  off: boolean; // feature flagの機能をオフにしたい場合
  error?: Error;
  check: (name: string, skip?: boolean) => boolean;
};

const initialValue: featureFlag = {
  module: new Denied(),
};

export const FeatureFlagVar = makeVar<featureFlag>(initialValue);
export const useFeatureFlag = () => useReactiveVar(FeatureFlagVar);
export const useIsMock = (flagName: string) => {
  const flags = useFeatureFlag();
  const [isMock, setIsMock] = useState<boolean | undefined>(undefined);
  useEffect(() => {
    const load = async () => {
      const mock = await flags.module?.check(flagName);
      setIsMock(mock);
    };
    load();
  });
  return isMock;
};
