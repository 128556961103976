/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * APIs for RCI FE
 * This document describes the APIs provided by NeoAP BE for RCI FE, designed to be integrated into NeoAP FE using `iframe`.\
These APIs utilize cookie-based authentication. All API requests must include the cookie `_ap_session`.

 * OpenAPI spec version: 1
 */
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useSuspenseInfiniteQuery,
  useSuspenseQuery,
} from '@tanstack/react-query';
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseInfiniteQueryOptions,
  UseSuspenseInfiniteQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import type { ApCounterpartyItem } from '../model/apCounterpartyItem';
import type { BadRequestResponse } from '../model/badRequestResponse';
import type { CreateApCounterpartyRequest } from '../model/createApCounterpartyRequest';
import type { ForbiddenResponse } from '../model/forbiddenResponse';
import type { GetApCounterpartiesParams } from '../model/getApCounterpartiesParams';
import type { GetApCounterpartiesResponse } from '../model/getApCounterpartiesResponse';
import type { NotFoundResponse } from '../model/notFoundResponse';
import type { UnauthorizedResponse } from '../model/unauthorizedResponse';
import type { UnprocessableEntityResponse } from '../model/unprocessableEntityResponse';
import { useCustomClient } from '../../mutator/custom-instance';
import type { ErrorType, BodyType } from '../../mutator/custom-instance';
import { customFomDataFn } from '../../api/formData/custom-function';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * @summary Get a list of ap_counterparties
 */
export const useGetApCounterpartiesHook = () => {
  const getApCounterparties = useCustomClient<GetApCounterpartiesResponse>();

  return (
    params?: GetApCounterpartiesParams,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>
  ) => {
    return getApCounterparties(
      { url: `/api/js/ap_counterparties`, method: 'GET', params },
      options
    );
  };
};

export const getGetApCounterpartiesQueryKey = (
  params?: GetApCounterpartiesParams
) => {
  return [`/api/js/ap_counterparties`, ...(params ? [params] : [])] as const;
};

export const useGetApCounterpartiesInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>,
    GetApCounterpartiesParams['page']
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetApCounterpartiesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>,
        TError,
        TData,
        Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>,
        QueryKey,
        GetApCounterpartiesParams['page']
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApCounterpartiesQueryKey(params);

  const getApCounterparties = useGetApCounterpartiesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>,
    QueryKey,
    GetApCounterpartiesParams['page']
  > = ({ pageParam }) =>
    getApCounterparties(
      { ...params, page: pageParam || params?.['page'] },
      requestOptions
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>,
    TError,
    TData,
    Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>,
    QueryKey,
    GetApCounterpartiesParams['page']
  > & { queryKey: QueryKey };
};

export type GetApCounterpartiesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>
>;
export type GetApCounterpartiesInfiniteQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get a list of ap_counterparties
 */
export const useGetApCounterpartiesInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>,
    GetApCounterpartiesParams['page']
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetApCounterpartiesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>,
        TError,
        TData,
        Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>,
        QueryKey,
        GetApCounterpartiesParams['page']
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetApCounterpartiesInfiniteQueryOptions(
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetApCounterpartiesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetApCounterpartiesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApCounterpartiesQueryKey(params);

  const getApCounterparties = useGetApCounterpartiesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>
  > = () => getApCounterparties(params, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApCounterpartiesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>
>;
export type GetApCounterpartiesQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get a list of ap_counterparties
 */
export const useGetApCounterparties = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetApCounterpartiesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetApCounterpartiesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetApCounterpartiesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetApCounterpartiesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApCounterpartiesQueryKey(params);

  const getApCounterparties = useGetApCounterpartiesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>
  > = () => getApCounterparties(params, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApCounterpartiesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>
>;
export type GetApCounterpartiesSuspenseQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get a list of ap_counterparties
 */
export const useGetApCounterpartiesSuspense = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetApCounterpartiesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetApCounterpartiesSuspenseQueryOptions(
    params,
    options
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetApCounterpartiesSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>,
    GetApCounterpartiesParams['page']
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetApCounterpartiesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>,
        TError,
        TData,
        Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>,
        QueryKey,
        GetApCounterpartiesParams['page']
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApCounterpartiesQueryKey(params);

  const getApCounterparties = useGetApCounterpartiesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>,
    QueryKey,
    GetApCounterpartiesParams['page']
  > = ({ pageParam }) =>
    getApCounterparties(
      { ...params, page: pageParam || params?.['page'] },
      requestOptions
    );

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>,
    TError,
    TData,
    Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>,
    QueryKey,
    GetApCounterpartiesParams['page']
  > & { queryKey: QueryKey };
};

export type GetApCounterpartiesSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>
>;
export type GetApCounterpartiesSuspenseInfiniteQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get a list of ap_counterparties
 */
export const useGetApCounterpartiesSuspenseInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>,
    GetApCounterpartiesParams['page']
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetApCounterpartiesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>,
        TError,
        TData,
        Awaited<ReturnType<ReturnType<typeof useGetApCounterpartiesHook>>>,
        QueryKey,
        GetApCounterpartiesParams['page']
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetApCounterpartiesSuspenseInfiniteQueryOptions(
    params,
    options
  );

  const query = useSuspenseInfiniteQuery(
    queryOptions
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create ap counterparty
 */
export const useCreateApCounterpartyHook = () => {
  const createApCounterparty = useCustomClient<ApCounterpartyItem>();

  return (
    createApCounterpartyRequest: BodyType<CreateApCounterpartyRequest>,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>
  ) => {
    return createApCounterparty(
      {
        url: `/api/js/ap_counterparties`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: createApCounterpartyRequest,
      },
      options
    );
  };
};

export const useCreateApCounterpartyMutationOptions = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateApCounterpartyHook>>>,
    TError,
    { data: BodyType<CreateApCounterpartyRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateApCounterpartyHook>>>,
  TError,
  { data: BodyType<CreateApCounterpartyRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const createApCounterparty = useCreateApCounterpartyHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateApCounterpartyHook>>>,
    { data: BodyType<CreateApCounterpartyRequest> }
  > = (props) => {
    const { data } = props ?? {};

    return createApCounterparty(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateApCounterpartyMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateApCounterpartyHook>>>
>;
export type CreateApCounterpartyMutationBody =
  BodyType<CreateApCounterpartyRequest>;
export type CreateApCounterpartyMutationError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Create ap counterparty
 */
export const useCreateApCounterparty = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateApCounterpartyHook>>>,
    TError,
    { data: BodyType<CreateApCounterpartyRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}) => {
  const mutationOptions = useCreateApCounterpartyMutationOptions(options);

  return useMutation(mutationOptions);
};
