import { useInputOrParseUrl } from '@/components/RelationActionMenu/hooks/useInputOrParseUrl';
import { useAgentApplicantResourceHeaders } from '@/context/AgentApplicantOfficeMember';
import {
  useDownloadHook,
  useDownloadS3SignedUrlSuspense,
} from '@/context/services/downloads/index.service';
import { useConvertToModernPagination } from '@/context/services/hooks/usePagination';
import { useOperationStatusToErrorMessage } from '@/context/services/invoice/hooks';
import { useGetLazyMfFile } from '@/context/services/mf_file/MfFile.service';
import { useRefetchQueries } from '@/context/services/refetch';
import { isSuccess } from '@/context/services/type';
import { useTranslation } from '@/i18n';
import { isCommonError } from '@/libs/typeguard/isError';
import { ModernPaginationProps } from '@moneyforward/ap-frontend-components';
import {
  CommonError,
  ReceivedInvoiceDetail,
  SearchReceivedInvoicesResponseReceivedInvoicesItem,
  UpdateReceivedInvoiceErrorResponse,
  getGetReceivedInvoiceQueryKey,
  useArchiveReceivedInvoice,
  useUpdateReceivedInvoice as useBaseUpdateReceivedInvoice,
  useGetReceivedInvoice,
  useGetReceivedInvoiceHook,
  useGetReceivedInvoiceSuspense,
  useGetSignedXmlFileUrlHook,
  useGetSignedXmlFileUrlSuspense,
  useRestoreReceivedInvoice,
  useSearchReceivedInvoices,
  useSearchReceivedInvoicesSuspense,
} from 'ap-openapi';
import { useCallback, useMemo } from 'react';
import type {
  ExistsInvoiceResult,
  FetchReceivedInvoiceHooks,
  FetchReceivedInvoicesArgs,
  FetchReceivedInvoicesModernResult,
  FetchReceivedInvoicesSearchWithReportableAndWithIncludeMfFileResult,
  InvoiceColumn,
  InvoiceColumnWithMfFile,
  ReceivedInvoicesSearchRefetch,
} from './type';

const responseToInvoices = <T extends InvoiceColumn>(
  item:
    | SearchReceivedInvoicesResponseReceivedInvoicesItem
    | ReceivedInvoiceDetail
) => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return {
    id: String(item.searchable_number) ?? '',
    fileName: item.filename,
    payeeCode: item.ap_payee?.code,
    payeeName: item.ap_payee?.name,
    pics:
      item.pics?.map((pic) => ({
        id: pic.id,
        name: pic.display_name || pic.name || '',
      })) ?? [],
    uploadedAt: item.uploaded_at,
    uploadType: item.upload_type,
    operationStatus: item.operation_status,
    memo: item.memo ?? '',
    searchId: item.id ?? '',
    mfFile: {
      id: item.mf_file?.id ?? '',
      name: item.mf_file?.name ?? '',
      contentType: item.mf_file?.content_type,
      contentByteSize: item.mf_file?.byte_size,
    },
  } as unknown as T;
};

type FetchPrivateReceivedInvoicesModernResult = {
  pagination: ModernPaginationProps;
  data: SearchReceivedInvoicesResponseReceivedInvoicesItem[] | undefined;
  refetch: ReceivedInvoicesSearchRefetch;
  error: CommonError | null;
  isLoading: boolean;
};

const usePrivateReceivedInvoicesSearchWithSuspense = (
  args: FetchReceivedInvoicesArgs = {}
): FetchPrivateReceivedInvoicesModernResult => {
  const headers = useAgentApplicantResourceHeaders('received_invoices');
  const { perPage = 25, query } = args;
  const searchArgs = useMemo(
    () => ({
      pagination: {
        per_page: perPage,
        page: args.page,
      },
      query: query,
      request: {
        headers,
      },
    }),
    [args.page, headers, perPage, query]
  );
  const { data, error, refetch, isLoading } =
    useSearchReceivedInvoicesSuspense(searchArgs);

  const pagination: ModernPaginationProps = useConvertToModernPagination(
    data?.data?.pagination
  );

  const result = useMemo(() => {
    return data?.data.received_invoices;
  }, [data]);

  return {
    pagination,
    data: result,
    refetch,
    error,
    isLoading,
  };
};

const usePrivateReceivedInvoicesSearch = (
  args: FetchReceivedInvoicesArgs = {}
): FetchPrivateReceivedInvoicesModernResult => {
  const headers = useAgentApplicantResourceHeaders('received_invoices');
  const { page = 1, perPage = 25, query, onPageChange } = args;

  const { data, error, refetch, isLoading } = useSearchReceivedInvoices(
    {
      pagination: {
        per_page: perPage,
        page: page,
      },
      query: query,
    },
    {
      request: {
        headers,
      },
    }
  );

  const result = useMemo(() => {
    return data?.data.received_invoices;
  }, [data]);

  const pagination: ModernPaginationProps = useConvertToModernPagination(
    data?.data?.pagination,
    onPageChange
  );

  return {
    pagination,
    data: result,
    refetch,
    error,
    isLoading,
  };
};

export function useReceivedInvoicesSearch(
  args: FetchReceivedInvoicesArgs = {}
): FetchReceivedInvoicesModernResult {
  const { pagination, refetch, data } =
    usePrivateReceivedInvoicesSearchWithSuspense(args);
  const values: InvoiceColumn[] =
    data?.map((item) => ({
      id: String(item.searchable_number) ?? '',
      fileName: item.filename,
      payeeCode: item.ap_payee?.code,
      payeeName: item.ap_payee?.name,
      pics:
        item.pics?.map((pic) => ({
          id: pic.id,
          name: pic.name || '',
        })) ?? [],
      uploadedAt: item.uploaded_at,
      uploadType: item.upload_type,
      operationStatus: item.operation_status,
      memo: item.memo ?? '',
      searchId: item.id ?? '',
    })) ?? [];
  return {
    pagination,
    data: values,
    refetch,
  };
}

export function useReceivedInvoicesSearchWithReportableAndWithIncludeMfFile(
  args: FetchReceivedInvoicesArgs = {}
): FetchReceivedInvoicesSearchWithReportableAndWithIncludeMfFileResult {
  if (!args.query) {
    args.query = {};
  }
  args.query.reportable = true;
  args.query.with_mf_file = true;
  const { data, pagination, refetch, isLoading, error } =
    usePrivateReceivedInvoicesSearch(args);
  const values = data?.map(responseToInvoices<InvoiceColumnWithMfFile>) ?? [];
  return {
    data: values,
    pagination,
    refetch,
    isLoading,
    isError: Boolean(error),
  };
}

export const useExistsInvoice = (
  invoiceSearchableId: string
): ExistsInvoiceResult => {
  const parseUrl = useInputOrParseUrl('/received_invoices', 'id');
  const headers = useAgentApplicantResourceHeaders('received_invoices');
  const { t } = useTranslation();
  const searchableId = useMemo(() => {
    const v = parseUrl(invoiceSearchableId);
    if (v) {
      const regexp = /([iI][vV])-/;
      return v.replace(regexp, '');
    }
    return '';
  }, [invoiceSearchableId, parseUrl]);
  const options: Parameters<
    typeof useGetReceivedInvoice<
      Awaited<ReturnType<ReturnType<typeof useGetReceivedInvoiceHook>>>
    >
  >[1] = useMemo(
    () => ({
      query: {
        enabled: Boolean(searchableId),
        queryKey: getGetReceivedInvoiceQueryKey(invoiceSearchableId),
      },
      request: {
        headers,
      },
    }),
    [headers, invoiceSearchableId, searchableId]
  );
  const { data, error, isFetching, isRefetching, isLoading } =
    useGetReceivedInvoice(searchableId, options);
  const operationStatusToErrorMessage = useOperationStatusToErrorMessage(
    data?.data?.operation_status
  );
  const mfFileId = useMemo(() => {
    if (operationStatusToErrorMessage) {
      return '';
    }
    return data?.data?.mf_file?.id || '';
  }, [data?.data?.mf_file?.id, operationStatusToErrorMessage]);
  const {
    data: mfFileData,
    error: mfFileError,
    isFetching: mfFileIsFetching,
  } = useGetLazyMfFile(mfFileId);
  return useMemo(() => {
    if (
      !searchableId ||
      [isLoading, isFetching, mfFileIsFetching, isRefetching].some((v) => v)
    ) {
      return undefined;
    }
    if (error || mfFileError) {
      return {
        data: undefined,
        error: t('system_error_input'),
      };
    } else {
      let error: string | undefined = undefined;
      const status = data?.status;
      let resultValue: InvoiceColumnWithMfFile | undefined = undefined;
      switch (status) {
        case undefined:
          break;
        case 200:
          error = operationStatusToErrorMessage;
          if (error === undefined && data?.data) {
            resultValue = responseToInvoices<InvoiceColumnWithMfFile>(
              data?.data
            );
          }
          if (resultValue?.mfFile) {
            resultValue.mfFile.content = mfFileData;
          }
          break;
        case 400:
        case 404:
          error = t('not_found_resource', {
            resource: t('resource_invoice'),
          });
          break;
        case 403:
          error = t('forbidden_resource', {
            resource: t('resource_invoice'),
          });
          break;
        default:
          if (status >= 500) {
            error = t('system_error_input');
          }
          break;
      }
      return {
        data: resultValue,
        error,
      };
    }
  }, [
    searchableId,
    isLoading,
    isFetching,
    mfFileIsFetching,
    isRefetching,
    error,
    mfFileError,
    t,
    data?.status,
    data?.data,
    operationStatusToErrorMessage,
    mfFileData,
  ]);
};

export const useReceivedInvoiceSearch: FetchReceivedInvoiceHooks = (
  invoiceId: string
) => {
  const { data, error, refetch } = useGetReceivedInvoiceSuspense(invoiceId);
  if (error) throw error;
  const body = data.data;
  if (data.status !== 200) {
    if (isCommonError(body)) {
      throw new Error(body.messages?.join('\n'));
    } else {
      throw new Error(data.statusText);
    }
  }
  return {
    data: data.data,
    refetch,
  };
};

type TArchiveReceivedInvoiceMutateAsync = ReturnType<
  typeof useArchiveReceivedInvoice
>['mutateAsync'];
type ArchiveReceivedInvoiceMutateArgs =
  Parameters<TArchiveReceivedInvoiceMutateAsync>['0'];
type ArchiveReceivedInvoiceMutateOption =
  Parameters<TArchiveReceivedInvoiceMutateAsync>['1'] & TFetchQueries;

export const useReceivedInvoiceArchive = () => {
  const { mutateAsync: _mutateAsync, error } = useArchiveReceivedInvoice();
  const refetchQueries = useRefetchQueries();
  const mutateAsync = useCallback(
    async (
      variable: ArchiveReceivedInvoiceMutateArgs,
      options?: ArchiveReceivedInvoiceMutateOption
    ) => {
      const resp = await _mutateAsync(variable, options);
      if (!isSuccess(resp.status)) throw error;
      return refetchQueries(options?.refetchQueries);
    },
    [_mutateAsync, error, refetchQueries]
  );
  return { mutateAsync };
};

type TRestoreReceivedInvoiceMutateAsync = ReturnType<
  typeof useRestoreReceivedInvoice
>['mutateAsync'];
type RestoreReceivedInvoiceMutateArgs =
  Parameters<TRestoreReceivedInvoiceMutateAsync>['0'];
type RestoreReceivedInvoiceMutateOption =
  Parameters<TRestoreReceivedInvoiceMutateAsync>['1'] & TFetchQueries;

export const useReceivedInvoiceRestoreArchive = () => {
  const { mutateAsync: _mutateAsync, error } = useRestoreReceivedInvoice();
  const refetchQueries = useRefetchQueries();
  const mutateAsync = useCallback(
    async (
      variable: RestoreReceivedInvoiceMutateArgs,
      options?: RestoreReceivedInvoiceMutateOption
    ) => {
      const resp = await _mutateAsync(variable, options);
      if (!isSuccess(resp.status)) throw error;
      return refetchQueries(options?.refetchQueries);
    },
    [_mutateAsync, error, refetchQueries]
  );
  return { mutateAsync };
};

type TUpdateReceivedInvoiceMutateAsync = ReturnType<
  typeof useBaseUpdateReceivedInvoice
>['mutateAsync'];
type UpdateReceivedInvoiceMutateArgs =
  Parameters<TUpdateReceivedInvoiceMutateAsync>['0'];
type UpdateReceivedInvoiceMutateOption =
  Parameters<TUpdateReceivedInvoiceMutateAsync>['1'] & TFetchQueries;

type UpdateReceivedInvoiceSuccess = {
  status: 'success';
};
type UpdateReceivedInvoiceError = {
  status: 'invalid';
  errors: UpdateReceivedInvoiceErrorResponse;
};
type UpdateReceivedInvoiceCommonError = {
  status: 'error';
  errors: CommonError;
};
type UpdateReceivedInvoiceResult =
  | UpdateReceivedInvoiceSuccess
  | UpdateReceivedInvoiceError
  | UpdateReceivedInvoiceCommonError;
export const useUpdateReceivedInvoice = () => {
  const { mutateAsync: _mutateAsync } = useBaseUpdateReceivedInvoice();
  const refetchQueries = useRefetchQueries();
  const mutateAsync = useCallback(
    async (
      args: UpdateReceivedInvoiceMutateArgs,
      options?: UpdateReceivedInvoiceMutateOption
    ): Promise<UpdateReceivedInvoiceResult> => {
      const resp = await _mutateAsync(args, options);
      if (isSuccess(resp.status)) {
        await refetchQueries(options?.refetchQueries);
        return {
          status: 'success',
        };
      } else {
        if (isCommonError(resp.data)) {
          return {
            status: 'error',
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            errors: (resp.data ?? {}) as CommonError,
          };
        } else {
          return {
            status: 'invalid',
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            errors: (resp.data ?? {}) as UpdateReceivedInvoiceErrorResponse,
          };
        }
      }
    },
    [_mutateAsync, refetchQueries]
  );
  return { mutateAsync };
};

const dummyBlob = new Blob([]);
export const useDownloadS3SignedUrl = (signedUrl: string) => {
  const downloadFn = useDownloadHook();
  const { data, error } = useDownloadS3SignedUrlSuspense(signedUrl, {
    query: {
      queryFn: ({ signal }) => downloadFn(signedUrl, signal),
    },
  });

  if (error) throw error;
  return useMemo(() => {
    return data?.data || dummyBlob;
  }, [data?.data]);
};

export const useGetXMLFile = (invoiceId: string = '') => {
  const getSignedXmlFileUrl = useGetSignedXmlFileUrlHook();

  const { data, error } = useGetSignedXmlFileUrlSuspense(invoiceId, {
    query: {
      queryFn: ({ signal }) => getSignedXmlFileUrl(invoiceId, {}, signal),
    },
  });
  if (error) throw error;
  return useMemo(() => data?.data?.signed_url ?? '', [data]);
};

export const useGetXMLFileAndObjectURL = (invoiceId: string = '') => {
  const signedUrl = useGetXMLFile(invoiceId);
  const downloadBlob = useDownloadS3SignedUrl(signedUrl);
  return downloadBlob;
};
