import { FileNameSeparatorWithExtension } from '@/components/FileNameSeparatorWithExtension';
import { useDownloadClick } from '@/features/invoices/components/Details/Components/Left/hooks';
import {
  IconButton,
  IconClose,
  IconDownload,
} from '@moneyforward/ap-frontend-components';
import { Header } from 'antd/es/layout/layout';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';
import styles from './Header.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  onClose: VoidFunction;
  fileName: string | undefined;
  originalBlob: Blob;
};

export const PreviewHeader: FC<Props> = memo(
  ({ onClose, originalBlob, fileName }) => {
    const onDownloadClick = useDownloadClick(
      originalBlob,
      fileName || 'unknown.pdf'
    );

    return (
      <Header className={cx(styles['headerContainer'])}>
        <div className={cx(styles['closeButton'])} onClick={onClose}>
          <IconClose size={20} />
        </div>
        <FileNameSeparatorWithExtension
          containerClassName={cx(styles['title'])}
        >
          {fileName ?? ''}
        </FileNameSeparatorWithExtension>
        <IconButton color='primary' onClick={onDownloadClick}>
          <IconDownload size={20} />
        </IconButton>
      </Header>
    );
  }
);
PreviewHeader.displayName = 'PreviewHeader';
