import type { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import axios, { AxiosRequestConfig } from 'axios';

export const AXIOS_INSTANCE: AxiosInstance = axios.create();

export type CustomHeaders = AxiosRequestConfig['headers'] & {
  OfficeMemberId?: string;
};

export type AxiosCustomOptions = {
  headers?: CustomHeaders;
};

type CustomClient<T> = (
  data: AxiosRequestConfig,
  options?: AxiosCustomOptions
) => Promise<AxiosResponse<T>>;

export const useCustomClient = <T>(): CustomClient<T> => {
  return async (config: AxiosRequestConfig, options?: AxiosCustomOptions) => {
    if (options?.headers) {
      const { OfficeMemberId, ...headers } = options.headers;
      config.headers = {
        ...config.headers,
        ...headers,
        ...(OfficeMemberId
          ? { 'X-Agent-Applicant-Office-Member-Id': OfficeMemberId }
          : {}),
      };
    }
    const source = axios.CancelToken.source();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const promise = AXIOS_INSTANCE<any, AxiosResponse<T>>({
      ...config,
      cancelToken: source.token,
    }).catch((err: AxiosError<T>) => {
      if (err.response) {
        // TODO: 401,403の場合は再ログインを促す
        return err.response;
      }
      throw err;
    });
    // @ts-ignore
    promise.cancel = () => {
      source.cancel('Query was cancelled by React Query');
    };
    return promise;
  };
};

export default useCustomClient;

export type ErrorType<ErrorData> = ErrorData;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type BodyType<BodyData> = BodyData & { headers?: any };
