import { BusinessDocumentDetail } from '@/features/business_documents/Detail';
import { DEFAULT_DRAWER_DETAIL_WIDTH } from '@/features/business_documents/UploadForm/Content/constant';
import { DraggableDrawer } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo, useEffect, useState } from 'react';
import styles from './Drawer.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  selectedId: string;
  open: boolean;
  onClose: () => void;
};

export const Drawer: FC<Props> = memo(({ selectedId, open, onClose }) => {
  const [width, setWidth] = useState(DEFAULT_DRAWER_DETAIL_WIDTH);
  useEffect(() => {
    setWidth(DEFAULT_DRAWER_DETAIL_WIDTH);
  }, [open]);

  return (
    <DraggableDrawer
      width={width}
      setWidth={setWidth}
      visible={open}
      onClose={onClose}
      withOutDefaultTitle
      contentContainerClassName={cx(styles['contentContainer'])}
      minWidth={DEFAULT_DRAWER_DETAIL_WIDTH}
    >
      {selectedId && (
        <BusinessDocumentDetail
          data-testid={selectedId}
          key={selectedId}
          id={selectedId}
          onClose={onClose}
        />
      )}
    </DraggableDrawer>
  );
});
Drawer.displayName = 'Drawer';
