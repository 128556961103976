import { KeywordCont } from '@/components/KeywordCont';
import { PicIdIn } from '@/components/PicIdIn';
import { ErrorBoundary } from '@/components/Rollbar';
import { SuspenseLoading } from '@/components/SuspenseErrorBoundary/fallback';
import { Amount } from '@/features/business_documents/List/Search/Amount';
import { DocumentTypeIn } from '@/features/business_documents/List/Search/DocumentTypeIn';
import { IncludeArchive } from '@/features/business_documents/List/Search/IncludeArchive';
import { TransactionDate } from '@/features/business_documents/List/Search/TransactionDate';
import { TBusinessDocumentCondition } from '@/features/business_documents/UploadForm/Content/type';
import { useTranslation } from '@/i18n';

import {
  ButtonV2,
  Form,
  SearchConditions,
  useNotification,
} from '@moneyforward/ap-frontend-components';
import { Col, Row } from 'antd';
import classnames from 'classnames/bind';
import parser from 'html-react-parser';
import { FC, Suspense, memo, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import styles from './Search.module.scss';

const cx = classnames.bind(styles);

type ErrorFallbackProps = {
  error: Error | null;
};

const ErrorFallback: FC<ErrorFallbackProps> = ({ error }) => {
  const { t } = useTranslation();
  const notify = useNotification();
  useEffect(() => {
    notify.error({
      title: error?.message ?? t('system_error'),
      opts: {
        duration: 3000,
        style: {
          position: 'fixed',
        },
      },
    });
  }, [error, notify, t]);
  return <></>;
};
ErrorFallback.displayName = 'ErrorFallback';

type Props = {
  defaultValues: TBusinessDocumentCondition;
  onSearch: (queryParams: TBusinessDocumentCondition) => void;
};

const InnerSearch: FC<Props> = memo(({ defaultValues, onSearch }) => {
  const { t } = useTranslation();
  const methods = useForm<TBusinessDocumentCondition>({
    defaultValues,
    mode: 'all',
  });

  const picLabelProps = useMemo(
    () => ({
      text: t('business_document_pics'),
    }),
    [t]
  );

  const keywordLabelProps = useMemo(
    () => ({
      text: t('business_document_key_word'),
      tooltip: parser(t('business_document_keyword_tooltip')),
    }),
    [t]
  );

  const keywordInputProps = useMemo(
    () => ({
      placeholder: t('business_document_key_word_placeholder'),
    }),
    [t]
  );

  return (
    <div className={cx(styles['searchCondition'])}>
      <SearchConditions>
        <Form<TBusinessDocumentCondition>
          formMethod={methods}
          onSubmit={onSearch}
          testId='searchForm'
        >
          <Row gutter={8} align='bottom'>
            <Col span={4}>
              <KeywordCont
                control={methods.control}
                name='keyword_cont'
                labelProps={keywordLabelProps}
                inputProps={keywordInputProps}
              />
            </Col>
            <Col span={4}>
              <DocumentTypeIn
                control={methods.control}
                name='document_type_in'
              />
            </Col>
            <Col span={4}>
              <PicIdIn<TBusinessDocumentCondition>
                control={methods.control}
                name='pic_id_in'
                labelProps={picLabelProps}
              />
            </Col>
            <Col span={5}>
              <TransactionDate
                control={methods.control}
                name='transaction_date'
              />
            </Col>
            <Col span={3}>
              <Amount control={methods.control} name='amount' />
            </Col>
            <Col span={4} className={cx(styles['flexCol'])}>
              <IncludeArchive
                control={methods.control}
                name='include_archive'
              />
              <ButtonV2
                isSecondary
                size='xs'
                type='submit'
                testId='search-submit-button'
              >
                {t('search')}
              </ButtonV2>
            </Col>
          </Row>
        </Form>
      </SearchConditions>
    </div>
  );
});

InnerSearch.displayName = 'InnerSearch';

export const BusinessDocumentSearch: FC<Props> = memo((props) => {
  return (
    <Suspense fallback={<SuspenseLoading />}>
      <ErrorBoundary fallback={({ error }) => <ErrorFallback error={error} />}>
        <InnerSearch {...props} />
      </ErrorBoundary>
    </Suspense>
  );
});
BusinessDocumentSearch.displayName = 'BusinessDocumentSearch';
