import { SuspenseErrorBoundary } from '@/components/SuspenseErrorBoundary';
import { useBusinessDocument } from '@/context/services/business_document/BusinessDocument.service';
import { BusinessDocumentContent } from '@/features/business_documents/Detail/components/Content/Content';
import { FC, memo } from 'react';
export type Props = {
  id: string;
  onClose: () => void;
};

const InnerDetails: FC<Props> = memo(({ id, onClose }) => {
  const { data } = useBusinessDocument(id);

  return <BusinessDocumentContent data={data} onClose={onClose} />;
});
InnerDetails.displayName = 'InnerDetails';

export const BusinessDocumentDetail: FC<Props> = memo((props) => {
  return (
    <SuspenseErrorBoundary {...props}>
      <InnerDetails {...props} />
    </SuspenseErrorBoundary>
  );
});

BusinessDocumentDetail.displayName = 'BusinessDocumentDetail';
