import { useTranslation } from '@/i18n';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  fileName: string;
  pics: string[];
  bodyTitle?: string;
};

export const ArchiveConfirmationModalBody: FC<Props> = memo(
  ({ fileName, pics, bodyTitle }) => {
    const { t } = useTranslation();
    return (
      <div className={cx(styles['container'])}>
        <div>
          <p>{bodyTitle ?? t('archive_modal_body_content_1')}</p>
          <p>{t('archive_modal_body_content_2')}</p>
          <ul>
            <li>
              {t('archive_modal_body_file_name')}
              {fileName}
            </li>
            <li>
              {t('archive_modal_body_pic')}
              {pics.join(',')}
            </li>
          </ul>
        </div>
      </div>
    );
  }
);
ArchiveConfirmationModalBody.displayName = 'ArchiveConfirmationModalBody';
