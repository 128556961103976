import { useTranslation } from '@/i18n';
import { Option } from '@moneyforward/ap-frontend-components/dist/es/atoms/Form/Radio/Radio';
import { useMemo } from 'react';

export const useReceiptType = () => {
  const { t } = useTranslation();
  const obj = useMemo(
    () => ({
      PAPER: {
        id: 'radio-paper',
        value: 'paper',
        label: t('receipt_type_paper'),
      },
      E_DOC: {
        id: 'radio-e_doc',
        value: 'e_doc',
        label: t('receipt_type_e_doc'),
      },
    }),
    [t]
  );

  const options: Option[] = useMemo(() => Object.values(obj), [obj]);
  return options;
};

export const useReceiptTypeLabel = () => {
  const { t } = useTranslation();
  const obj = useMemo(
    () => ({
      paper: t('receipt_type_paper'),
      e_doc: t('receipt_type_e_doc'),
    }),
    [t]
  );

  return obj;
};
