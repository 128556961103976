import { TErrorNotification } from '@/components/ErrorInlineNotification/ErrorInlineNotification';
import { useBulkUploadBusinessDocumentForm } from '@/context/services/business_document/BusinessDocument.service';
import { ResponseError } from '@/context/services/business_document/type';
import { TBusinessDocumentUpload } from '@/features/business_documents/UploadForm/Content/type';
import { useTranslation } from '@/i18n';
import { SendErrorTracking } from '@/utils/errors';
import { useNotification } from '@moneyforward/ap-frontend-components';
import { RcFile } from 'antd/es/upload';

import { useCallback, useState } from 'react';
import {
  ErrorOption,
  SubmitHandler,
  UseFieldArrayRemove,
} from 'react-hook-form';

export const useSubmitBulkUpload = (
  setErrorNotification: TErrorNotification['setError'],
  handleResetForm: () => void,
  remove: UseFieldArrayRemove,
  onClearMessage: () => void
) => {
  const { t } = useTranslation();
  const notify = useNotification();
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<ErrorOption[]>([]);
  const { mutateAsync } = useBulkUploadBusinessDocumentForm();

  const onHandleSubmit: SubmitHandler<TBusinessDocumentUpload> = useCallback(
    async (form) => {
      setLoading(true);
      onClearMessage();

      try {
        const resp = await mutateAsync({
          data: {
            ...form,
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            files: form.files as RcFile[],
          },
        });

        if (resp.status === 'success') {
          handleResetForm();
          notify.success({
            title: t('business_document_bulk_upload_successful'),
            opts: {
              duration: 3000,
              style: {
                position: 'fixed',
              },
            },
            bodyProps: {
              disabledSuffix: true,
            },
          });
        } else if (resp.status === 'error') {
          const results = resp.errors?.results ?? [];
          const successIndexes: number[] = [];
          const errorMessages: ErrorOption[] = [];

          results.map((item, idx) => {
            if (item?.status === 'error') {
              const errors = item.errors || [];
              Object.entries(errors).forEach((entry) => {
                const messages = entry[1]
                  .map((value: ResponseError) => value.message)
                  .join('\n');
                errorMessages.push({ message: messages });
              });
            } else {
              successIndexes.push(idx);
            }
          });
          setErrorMessage(errorMessages);
          remove(successIndexes);
          setErrorNotification(
            new Error(t('business_document_bulk_upload_error'))
          );
        } else if (resp.status === 'error_response') {
          setErrorNotification(new Error(resp.errors));
        }
      } catch (error) {
        if (error instanceof Error && error?.name === 'AxiosError') {
          setErrorNotification(new SendErrorTracking(error));
        } else {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          setErrorNotification(new SendErrorTracking(error as Error));
        }
      } finally {
        setLoading(false);
      }
    },
    [
      mutateAsync,
      notify,
      setErrorNotification,
      handleResetForm,
      t,
      remove,
      onClearMessage,
    ]
  );
  return {
    isLoading,
    onHandleSubmit,
    errorMessage,
  };
};
