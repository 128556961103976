import { ReceivedInvoiceDetail } from '@/context/services/invoice/type';
import { Left } from '@/features/invoices/components/Details/Components/Left';
import { Right } from '@/features/invoices/components/Details/Components/Right';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';
import styles from './Content.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  data: ReceivedInvoiceDetail;
  onClose: () => void;
};

export const Ratio: FC<Props> = memo(({ data, onClose }) => {
  return (
    <div className={cx(styles['container'])}>
      <div className={cx(styles['preview'])}>
        <Left data={data} onClose={onClose} />
      </div>
      <div className={cx(styles['content'])}>
        <Right data={data} />
      </div>
    </div>
  );
});
Ratio.displayName = 'Ratio';
