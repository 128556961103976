import { TErrorNotification } from '@/components/ErrorInlineNotification/ErrorInlineNotification';
import { ApReport } from '@/context/services/invoice/type';
import { useCreateInvoiceReports } from '@/context/services/invoice_report/InvoiceReport.service';
import { RestError } from '@/libs/error';
import { isCommonError } from '@/libs/typeguard/isError';
import { SendErrorTracking } from '@/utils/errors';
import { useCallback } from 'react';

export const useCreateInvoiceReport = (
  id: string,
  callback: (data: ApReport) => void,
  setError: TErrorNotification['setError']
) => {
  const { mutateAsync } = useCreateInvoiceReports(callback);
  return useCallback(async () => {
    try {
      await mutateAsync(
        { id: id }
        // { refetchQueries: ['ReceivedInvoice', 'ReceivedInvoices'] }
      );
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const err = error as Error;
      if (isCommonError(err)) {
        if (err?.code) {
          setError(new RestError(err.code, err.messages?.join('</br>') || ''));
        } else {
          setError(new Error(err.messages?.join('\n')));
        }
      } else {
        setError(new SendErrorTracking(err));
      }
    }
  }, [id, mutateAsync, setError]);
};
