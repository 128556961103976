import { useCallback, useState } from 'react';

type TUseVisibilityToggle = () => {
  visible: boolean;
  handleOpen: () => void;
  handleClose: () => void;
};

export const useVisibilityToggle: TUseVisibilityToggle = () => {
  const [visible, setVisible] = useState(false);

  const handleOpen = useCallback(() => {
    setVisible(true);
  }, []);

  const handleClose = useCallback(() => {
    setVisible(false);
  }, []);

  return {
    visible,
    handleOpen,
    handleClose,
  };
};
