import { LocaleProvider as BaseLocaleProvider } from '@moneyforward/ap-frontend-i18n';
import { FC, ReactNode } from 'react';
export { useLocale } from '@moneyforward/ap-frontend-i18n';
export type Locale = 'ja' | 'en';

type Props = {
  value: Locale;
  children: ReactNode;
};

export const LocaleProvider: FC<Props> = ({ value, children }) => {
  return <BaseLocaleProvider value={value}>{children}</BaseLocaleProvider>;
};
