import { CopyURLButton } from '@/features/business_documents/Detail/components/Right/HeaderButton/CopyURLButton';
import { useIsView } from '@/features/invoices/components/Details/Components/Context/ViewEdit';
import { useWrapperClick } from '@/features/invoices/components/Details/Components/Right/HeaderButton/hooks';
import { ButtonGroup } from '@moneyforward/ap-frontend-components';
import { FC } from 'react';
import { ArchiveButton } from './ArchiveButton';
import { RestoreArchiveButton } from './RestoreButton';

type HeaderButton = {
  onArchiveClick: () => void;
  onArchiveRestoreClick: () => Promise<void>;
  copyText: string;
  hasInvoiceReports: boolean;
};

export const HeaderButtons: FC<HeaderButton> = ({
  onArchiveClick,
  onArchiveRestoreClick,
  copyText,
  hasInvoiceReports,
}) => {
  const { isDisabled, onClick } = useWrapperClick({
    onArchiveClick,
    onArchiveRestoreClick,
  });

  const { isView } = useIsView();

  return (
    <ButtonGroup>
      <CopyURLButton copyText={copyText} />
      {!hasInvoiceReports && (
        <ArchiveButton
          isDisabled={isDisabled || !isView}
          onClick={onClick('archive')}
        />
      )}

      <RestoreArchiveButton
        isDisabled={isDisabled}
        onClick={onClick('restore')}
      />
    </ButtonGroup>
  );
};
