import {
  TBusinessDocumentCondition,
  TSetupConditionProps,
} from '@/features/business_documents/UploadForm/Content/type';
import { useTranslation } from '@/i18n';
import { FormCheckbox } from '@moneyforward/ap-frontend-components';
import { FC, memo } from 'react';

export const IncludeArchive: FC<TSetupConditionProps> = memo(
  ({ control, name }) => {
    const { t } = useTranslation();

    return (
      <FormCheckbox<TBusinessDocumentCondition>
        name={name}
        control={control}
        inputId='include_archive'
        label={t('business_document_include_archive')}
        testId='include-archive'
      />
    );
  }
);
IncludeArchive.displayName = 'IncludeArchive';
