import { FunctionHeader } from '@/components/FunctionHeader';
import { ListViewBody, ListViewLabel } from '@/components/ListView';
import { BusinessDocumentDetail } from '@/context/services/business_document/type';
import { useTranslation } from '@/i18n';
import { Bottom, ListView, Top } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo, useMemo } from 'react';
import styles from './PaymentRequestApp.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  data: BusinessDocumentDetail;
};

export const PaymentRequestApp: FC<Props> = memo(({ data }) => {
  const { t } = useTranslation();
  const reportNumbers = useMemo(() => {
    return data?.invoice_reports?.map((report) => report.number).join(', ');
  }, [data?.invoice_reports]);

  return (
    <FunctionHeader
      functionName={t('payment_request_app')}
      testId='payment-request-header'
    >
      <div className={cx(styles['contentContainer'])}>
        <ListView>
          <Top>
            <ListViewLabel key='left' testId='payment-request-number'>
              {t('payment_request_number')}
            </ListViewLabel>
            <ListViewBody key='right'>{reportNumbers}</ListViewBody>
          </Top>
          <Bottom>
            {/* Empty fragment to satisfy the required prop */}
            <> </>
          </Bottom>
        </ListView>
      </div>
    </FunctionHeader>
  );
});
PaymentRequestApp.displayName = 'PaymentRequestApp';
