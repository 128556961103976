import {
  TBusinessDocumentUpload,
  TSetupUploadProps,
} from '@/features/business_documents/UploadForm/Content/type';
import {
  DescriptionCol,
  DescriptionRow,
} from '@/features/business_documents/components/Description';
import { useReceiptType } from '@/features/business_documents/hooks';
import { useTranslation } from '@/i18n';
import { FormRadioButton, Label } from '@moneyforward/ap-frontend-components';
import { FC, memo } from 'react';

export const ReceiptType: FC<TSetupUploadProps> = memo(({ control, name }) => {
  const { t } = useTranslation();
  const options = useReceiptType();
  return (
    <DescriptionRow>
      <DescriptionCol
        label={
          <Label
            text={t('how_to_receive')}
            required
            testId='receiptTypeLabel'
          />
        }
        labelWidth='160px'
      />
      <DescriptionCol
        value={
          <FormRadioButton<TBusinessDocumentUpload>
            name={name}
            control={control}
            inputId='receipt-type'
            options={options}
            // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
            rules={{ required: t('required') }}
            testId='receiptType'
          />
        }
        valueWidth='auto'
      />
    </DescriptionRow>
  );
});
ReceiptType.displayName = 'ReceiptType';
