import { IFeatureFlag } from '../types';

export class Denied implements IFeatureFlag {
  off: boolean;

  constructor() {
    this.off = false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public check(_name: string, _skip = false): Promise<boolean> {
    return Promise.resolve(false);
  }
}
